import { IconProps } from 'assets'
import React from 'react'

const Arrow = ({ className, pathClassName = 'fill-blue800' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="148"
      height="18"
      viewBox="0 0 148 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M147.568 9.0957L132.568 0.435449V17.756L147.568 9.0957ZM134.068 7.5957L0.512695 7.5957V10.5957L134.068 10.5957V7.5957Z"
        className={pathClassName}
      />
    </svg>
  )
}

export default Arrow
