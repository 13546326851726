import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'

const Loading = ({ className }: LoadingProps): JSX.Element => {
  return (
    <div role="status" className={cx(className)}>
      {getIcons(IconsType.loading)}
      <span className="sr-only">Loading...</span>
    </div>
  )
}

interface LoadingProps {
  className?: string
}

export default Loading
