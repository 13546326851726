const getAllUsers = {
  status: 200,
  data: [
    {
      id: 9,
      username: 'abhishek',
      first_name: 'Abhishek',
      last_name: 'Arora',
      email: 'abhishek@integraltechnologies.io',
      organization: 2,
      user_roles: [
        {
          id: 6,
          user_type: 'super_admin',
          user: 9,
          organization: null,
        },
      ],
      is_active: true,
      country: {
        code: 'MV',
        name: 'Maldives',
      },
      profile_photo:
        'https://integral-optimus-bucket-development-7cc3bd6269201519dc7f6c3e99.s3.amazonaws.com/tenant-2/miscellaneous/pic-logo_JA0hqRL.png?AWSAccessKeyId=ASIAXCERLY6MVODAYNUC&Signature=hIENhth9tndtOm%2F9DdrkBj4Y58Y%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEOr%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIDWNRc6QIxXwiwDES9ttNdat97OD%2Br%2BXMBM0UMucpVdbAiEA1A%2FhIBlh8ne2b1nw3crQY7g8X5wrDLXKmVDq5zai2UoqhwQIQhAAGgw0ODU2MzYxNjM0ODEiDIHKJKkWA23Wisg1NSrkA%2BBcdJzVywloOBqiUIfiBcoVoST5%2BDyKl%2FF18SSR0H7kZ3HzJtjyvirMP78%2BW%2BVC%2FqEkXSTQtHXgi8cSbG9woFNNdhxoVQ3MAQTeIh%2FAD%2BLdno5fkGmeRH%2B0cpQSEOmSScyM7R67v96mxWGuv%2F%2F0wXdGgDqu9ipBKnwLlruxk3Bg8ciy4EH8RaH%2FaUAA%2BTxo6HrsC1aYfTwOjbIyjja31UU2JfvxfhLV%2FAiDKKq%2BJXu6rcD6mpoPKvsms5RUBK7LOhmEK2b0ecNHRWR3T4hCuNIAMLBaoYnadKTJCU5ri2HdmHd7dphMQTJkZxWGSuqG%2FQFeJUG4xERGYHMoZahprG01i8fWf2QqpW4XHmm8yxgUELqpnHsGdDAr%2F8fWfSzW%2FgC1CXLXtw%2F7DVCreWALggSTcw0mPVqcbcF6pUiF5ITBXslNcvaJAmAP5CfU6fRDPKt3RFXp2HhsYVFUkZlfS3hLEFOQoiw87hmAOiR%2Bk6eytUo1zFh%2BU%2Bm%2BV4LtbZrqKg0qUTXAb1jVXrxz23nNRQQkNgazlsPe%2Fs1mr7XpYU2ErslRiFyxSomslDYkEs75nwicSVyOisYpkK0JisFyRDzdnwCfEW0a9EQcs0Zl3LtVPoz32094JQo40qKzzOdL77wmSjIwqI3IsQY6pQFN8Ydhb7BtPZBIDe04fp51MAEevtFUImVEi8ZJjlcBYme4yPUpBIr5AZAuAN7V74Jaqg%2BVijGExFxjgK0yPBEN7koVSDI6czvlNQqOAh06TlZ09%2FqWSC3bCZlq0pUz1Q3CIcLk%2FevnM3f5Ae5OFlFvuM2rl73jN45j5PayHSDqsUnzdPtgEfRYDCSr7MrmXBABeBrTv9MU8%2BfnX7niZNdd%2F5THbno%3D&Expires=1714568979',
      department: null,
      designation: null,
      tenant_name: 'Ab-tenant',
      is_sso_enabled: false,
      is_tenant_multi_org: true,
    },
    {
      id: 2,
      username: 'artyom',
      first_name: 'Artyom',
      last_name: '',
      email: 'Artsiom.Hryshyn@itechart-group.com',
      organization: 2,
      user_roles: [
        {
          id: 1,
          user_type: 'super_admin',
          user: 2,
          organization: null,
        },
      ],
      is_active: true,
      country: '',
      profile_photo: null,
      department: null,
      designation: null,
      tenant_name: 'Ab-tenant',
      is_sso_enabled: false,
      is_tenant_multi_org: true,
    },
    {
      id: 58,
      username: 'test-dev2@blondmail.com',
      first_name: '',
      last_name: '',
      email: 'test-dev2@blondmail.com',
      organization: null,
      user_roles: [],
      is_active: true,
      country: '',
      profile_photo: null,
      department: null,
      designation: null,
      tenant_name: 'Ab-tenant',
      is_sso_enabled: false,
      is_tenant_multi_org: true,
    },
    {
      id: 24,
      username: 'vinay.mothkur@vomoto.com',
      first_name: '',
      last_name: '',
      email: 'vinay.mothkur@vomoto.com',
      organization: 2,
      user_roles: [],
      is_active: false,
      country: {
        code: 'IN',
        name: 'India',
      },
      profile_photo: null,
      department: null,
      designation: null,
      tenant_name: 'Ab-tenant',
      is_sso_enabled: false,
      is_tenant_multi_org: true,
    },
    {
      id: 25,
      username: 'naveen.reddy@vomoto.com',
      first_name: 'Naveen',
      last_name: 'Reddy',
      email: 'naveen.reddy@vomoto.com',
      organization: 2,
      user_roles: [],
      is_active: true,
      country: {
        code: 'AL',
        name: 'Albania',
      },
      profile_photo: null,
      department: null,
      designation: null,
      tenant_name: 'Ab-tenant',
      is_sso_enabled: false,
      is_tenant_multi_org: true,
    },
    {
      id: 26,
      username: 'rahul.gandhi@vomoto.com',
      first_name: 'Rahul-1',
      last_name: 'Gandhi',
      email: 'rahul.gandhi@vomoto.com',
      organization: 2,
      user_roles: [],
      is_active: true,
      country: '',
      profile_photo: null,
      department: null,
      designation: null,
      tenant_name: 'Ab-tenant',
      is_sso_enabled: false,
      is_tenant_multi_org: true,
    },
    {
      id: 56,
      username: 'harinath@getmule.com',
      first_name: '',
      last_name: '',
      email: 'harinath@getmule.com',
      organization: null,
      user_roles: [
        {
          id: 23,
          user_type: 'super_admin',
          user: 56,
          organization: null,
        },
      ],
      is_active: true,
      country: '',
      profile_photo: null,
      department: null,
      designation: null,
      tenant_name: 'Ab-tenant',
      is_sso_enabled: false,
      is_tenant_multi_org: true,
    },
    {
      id: 3,
      username: 'Max',
      first_name: '',
      last_name: '',
      email: 'MaksimTsybaWork@gmail.com',
      organization: 2,
      user_roles: [],
      is_active: true,
      country: {
        code: 'PT',
        name: 'Portugal',
      },
      profile_photo: null,
      department: null,
      designation: null,
      tenant_name: 'Ab-tenant',
      is_sso_enabled: false,
      is_tenant_multi_org: true,
    },
    {
      id: 4,
      username: 'sriharsha',
      first_name: 'Harsha',
      last_name: 'Revadi',
      email: 'harsha@integraltechnologies.io',
      organization: 2,
      user_roles: [
        {
          id: 3,
          user_type: 'super_admin',
          user: 4,
          organization: null,
        },
      ],
      is_active: true,
      country: '',
      profile_photo: null,
      department: null,
      designation: null,
      tenant_name: 'Ab-tenant',
      is_sso_enabled: false,
      is_tenant_multi_org: true,
    },
  ],
}

export default getAllUsers
