import { useQuery } from '@tanstack/react-query'
import { SubscriptionDetail } from 'api/subscriptions/types'
import { QUERIES } from 'constants/query'
import { useRouter } from 'next/router'
function useSubscriptions() {
  const router = useRouter()

  const res = useQuery([QUERIES.GET_SUBSCRIPTIONS.key], {
    queryFn: () => QUERIES.GET_SUBSCRIPTIONS.function(),
    enabled: !!Number(router.query?.orgId),
  })
  const { data: subscriptionList, isLoading, isError } = res

  function getActiveSubscription(subscriptions: SubscriptionDetail[]): SubscriptionDetail | undefined {
    if (!subscriptions?.length) return
    const activeSubscriptions = subscriptions.filter(sub => sub.status === 'Active')

    if (activeSubscriptions.length === 0) {
      return
    }

    let subscriptionWithGreatestDate = activeSubscriptions[0]

    for (let i = 1; i < activeSubscriptions.length; i++) {
      const currentSubscription = activeSubscriptions[i]

      const greatestDate = subscriptionWithGreatestDate.end_date || subscriptionWithGreatestDate.start_date
      const currentDate = currentSubscription.end_date || currentSubscription.start_date

      if (greatestDate && currentDate) {
        if (new Date(currentDate) > new Date(greatestDate)) {
          subscriptionWithGreatestDate = currentSubscription
        }
      }
    }

    return subscriptionWithGreatestDate
  }

  const activeSubscription = subscriptionList && getActiveSubscription(subscriptionList)
  return {
    subscriptionList,
    activeSubscription,
    isLoading,
    isError,
  }
}

export default useSubscriptions
