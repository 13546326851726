import { IconProps } from 'assets'
import React from 'react'

const Paragraph = ({ className, pathClassName = 'fill-[#DBEAFE]' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M20.2383 3H5.23828C4.40985 3 3.73828 3.67157 3.73828 4.5V19.5C3.73828 20.3284 4.40985 21 5.23828 21H20.2383C21.0667 21 21.7383 20.3284 21.7383 19.5V4.5C21.7383 3.67157 21.0667 3 20.2383 3Z"
        stroke="#1E3A8A"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M13.7383 12H7.73828"
        stroke="#1E3A8A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M17.7383 7.5H7.73828"
        stroke="#1E3A8A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M16.7383 16.5H7.73828"
        stroke="#1E3A8A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Paragraph
