import { IconProps } from 'assets'

const SortDescending = ({ className }: IconProps) => (
  <svg className={className} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.125 6.25781L12.9375 3.44531L15.7499 6.25732"
      stroke="#1E3A8A"
      strokeWidth="1.6875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9375 10.1953V3.44531"
      stroke="#1E3A8A"
      strokeWidth="1.6875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.375 9.07031H8.43743"
      stroke="#1E3A8A"
      strokeWidth="1.6875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.375 4.57031H7.31243"
      stroke="#1E3A8A"
      strokeWidth="1.6875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.375 13.5703H12.9374"
      stroke="#1E3A8A"
      strokeWidth="1.6875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SortDescending
