import axios from '../index'
import { TemplatesMapResponse } from './types'

export const getTemplatesMap = (variables: { transaction: number }) => {
  return axios
    .get<TemplatesMapResponse>(`/api/v1/org/{organization}/templates/get_template_map/`, {
      params: variables,
    })
    .then(res => res.data)
}
