import { IconProps } from 'assets'

const ProductCategory = ({ className }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5854_4034)">
        <circle
          cx="15.5834"
          cy="6.7915"
          r="2.75"
          stroke="#1E3A8A"
          strokeWidth="1.83333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="6.41663"
          cy="15.9585"
          r="2.75"
          stroke="#1E3A8A"
          strokeWidth="1.83333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.8334 13.2085H18.3334V17.7918C18.3334 18.2981 17.923 18.7085 17.4167 18.7085H13.75C13.2438 18.7085 12.8334 18.2981 12.8334 17.7918V13.2085Z"
          stroke="#1E3A8A"
          strokeWidth="1.83333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.66663 4.0415H9.16663V8.62484C9.16663 9.1311 8.75622 9.5415 8.24996 9.5415H4.58329C4.07703 9.5415 3.66663 9.1311 3.66663 8.62484V4.0415Z"
          stroke="#1E3A8A"
          strokeWidth="1.83333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5854_4034">
          <rect width="22" height="22" fill="white" transform="translate(0 0.375)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ProductCategory
