import { configureStore } from '@reduxjs/toolkit'

import { appSlice } from './reducer/app'

// config the store
const store = configureStore({
  reducer: {
    app: appSlice.reducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware(),
})

// export default the store
export default store

export type RootState = ReturnType<typeof store.getState>
