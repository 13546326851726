import { IconProps } from 'assets'

const AutoAwesomeCharacter = ({ className, pathClassName = 'fill-[#1E293B]' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        style={{ maskType: 'alpha' }}
        id="mask0_8976_26709"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18">
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_8976_26709)">
        <path
          className={pathClassName}
          d="M14.25 6.75L13.3125 4.6875L11.25 3.75L13.3125 2.8125L14.25 0.75L15.1875 2.8125L17.25 3.75L15.1875 4.6875L14.25 6.75ZM14.25 17.25L13.3125 15.1875L11.25 14.25L13.3125 13.3125L14.25 11.25L15.1875 13.3125L17.25 14.25L15.1875 15.1875L14.25 17.25ZM6.75 15L4.875 10.875L0.75 9L4.875 7.125L6.75 3L8.625 7.125L12.75 9L8.625 10.875L6.75 15ZM6.75 11.3625L7.5 9.75L9.1125 9L7.5 8.25L6.75 6.6375L6 8.25L4.3875 9L6 9.75L6.75 11.3625Z"
          fill="#1E293B"
        />
      </g>
    </svg>
  )
}

export default AutoAwesomeCharacter
