import { IconProps } from 'assets'
import * as React from 'react'
const CompetitorAnalysis = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <ellipse
        cx="11.017947"
        cy="11.109388"
        rx="9.2252808"
        ry="9.3300476"
        style={{ fill: 'none', stroke: '#1e3a8a', strokeWidth: 1.5, strokeOpacity: 1 }}
        className={pathClassName}
      />
      <path
        d="m 20.507758,20.503181 c 8.604814,8.681188 -8.604815,-8.681188 0,0 z"
        style={{
          fill: '#000000',
          stroke: '#1e3a8a',
          strokeWidth: 1.74028,
          strokeLinecap: 'round',
          strokeLinejoin: 'bevel',
          strokeOpacity: 1,
        }}
        className={pathClassName}
      />
      <ellipse
        cx="10.960598"
        cy="4.4670277"
        rx="0.98770869"
        ry="0.74750161"
        style={{
          fill: 'none',
          stroke: '#1e3a8a',
          strokeWidth: 1.74701,
          strokeLinecap: 'round',
          strokeLinejoin: 'bevel',
          strokeOpacity: 1,
        }}
        className={pathClassName}
      />
      <rect
        width="4.0156717"
        height="1.6490766"
        x="8.9749584"
        y="7.4420867"
        ry="0.38595408"
        rx="1.1473348"
        style={{
          fill: 'none',
          stroke: '#1e3a8a',
          strokeWidth: 2.05854,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
          paintOrder: 'fill markers stroke',
        }}
        className={pathClassName}
      />
      <ellipse
        cx="14.921479"
        cy="11.140696"
        rx="0.98770869"
        ry="0.74750161"
        style={{
          fill: 'none',
          stroke: '#1e3a8a',
          strokeWidth: 1.74701,
          strokeLinecap: 'round',
          strokeLinejoin: 'bevel',
          strokeOpacity: 1,
        }}
        className={pathClassName}
      />
      <rect
        width="4.0156717"
        height="1.6490766"
        x="12.935841"
        y="14.115756"
        ry="0.38595408"
        rx="1.1473348"
        style={{
          fill: 'none',
          stroke: '#1e3a8a',
          strokeWidth: 2.05854,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
          paintOrder: 'fill markers stroke',
        }}
        className={pathClassName}
      />
      <ellipse
        cx="6.903151"
        cy="11.03972"
        rx="0.98770869"
        ry="0.74750161"
        style={{
          fill: 'none',
          stroke: '#1e3a8a',
          strokeWidth: 1.74701,
          strokeLinecap: 'round',
          strokeLinejoin: 'bevel',
          strokeOpacity: 1,
        }}
        className={pathClassName}
      />
      <rect
        width="4.0156717"
        height="1.6490766"
        x="4.9175134"
        y="14.01478"
        ry="0.38595408"
        rx="1.1473348"
        style={{
          fill: 'none',
          stroke: '#1e3a8a',
          strokeWidth: 2.05854,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
          paintOrder: 'fill markers stroke',
        }}
        className={pathClassName}
      />
    </svg>
  )
}
export default CompetitorAnalysis
