import { AxiosError, AxiosResponse } from 'axios'
import { FieldValues, Path, UseFormSetError } from 'react-hook-form'

import { HandleMutationErrorArgs, MutationErrorHandler, RecordWithStringKeys } from './lib.types'

export const getErrorMessage = <T>(error: AxiosError, id: string): T | string => {
  const data = error.response?.data as RecordWithStringKeys<T>
  return data?.[id] || 'Something went wrong.'
}
export function getDataFromAxiosResponse<T>(response: AxiosResponse<T>): T {
  return response.data
}

export function handleMutationError<T extends FieldValues>({ error, setError, id }: HandleMutationErrorArgs<T>): void {
  setError(id, {
    type: 'manual',
    message: getErrorMessage(error, id) as string,
  })
}

export function createHandleMutationError<T extends FieldValues>(
  setError: UseFormSetError<T>,
  id: Path<T>
): MutationErrorHandler<T> {
  return (error: AxiosError) =>
    handleMutationError({
      error,
      id,
      setError,
    })
}
