import axios from '../../index'
import {
  addComparabilityMetricPayload,
  ComparabilityFactor,
  CreateSelectedComparabilityFactorsPayload,
  CreateSuggetedComparabilityFactorsPayload,
  editComparabilityFactorsPayload,
  SelectedComparabilityFactor,
  UpdateSelectedComparabilityFactorsPayload,
} from './types'

export const getComparabilityMetricsList = () => {
  return axios
    .get<ComparabilityFactor[]>(`/api/v1/org/{organization}/transaction/comparability_factor/`)
    .then(res => res.data)
}

export const addComparabilityMetric = (payload: addComparabilityMetricPayload) => {
  return axios
    .post<ComparabilityFactor>(`/api/v1/org/{organization}/transaction/comparability_metric/`, payload)
    .then(res => res.data)
}

export const editComparabilityFactors = ({ id, ...variables }: editComparabilityFactorsPayload) => {
  return axios
    .patch(
      `/api/v1/org/{organization}/transaction/economic_analysis/${id}/`,
      {
        ...variables,
      },
      {
        headers: {
          'Content-Type': variables.comparability_factors_file instanceof File ? 'multipart/form-data' : undefined,
        },
      }
    )
    .then(res => res.data)
}

export const deleteComparabilityJustification = ({ id }: { id: number }) => {
  return axios
    .delete(`/api/v1/org/{organization}/transaction/selected_comparability_factor/${id}/`)
    .then(res => res.data)
}

export const getSelectedComparabilityFactors = (economic_analysis: number) => {
  return axios
    .get<SelectedComparabilityFactor[]>(`/api/v1/org/{organization}/transaction/selected_comparability_factor/`, {
      params: {
        economic_analysis: economic_analysis,
      },
    })
    .then(res => res.data)
}

export const createSelectedComparabilityFactors = (payload: CreateSelectedComparabilityFactorsPayload) => {
  return axios
    .post<SelectedComparabilityFactor>(
      `/api/v1/org/{organization}/transaction/selected_comparability_factor/`,
      payload,
      {
        headers: {
          'Content-Type': payload.comparability_file instanceof File ? 'multipart/form-data' : undefined,
        },
      }
    )
    .then(res => res.data)
}

export const updateSelectedComparabilityFactors = ({ id, ...payload }: UpdateSelectedComparabilityFactorsPayload) => {
  return axios
    .patch<SelectedComparabilityFactor>(
      `/api/v1/org/{organization}/transaction/selected_comparability_factor/${id}/`,
      payload,
      {
        headers: {
          'Content-Type': payload.comparability_file instanceof File ? 'multipart/form-data' : undefined,
        },
      }
    )
    .then(res => res.data)
}

export const createComparabilityFactors = (payload: CreateSuggetedComparabilityFactorsPayload) => {
  return axios
    .post(`/api/v1/org/{organization}/transaction/comparability_factor/add_comparability_factor/`, payload)
    .then(res => res.data)
}
