import axios from 'api'
import { AxiosRequestConfig } from 'axios'

import { AppendicesResponse, ReorderOtherAppendixFilesPayload, UpdateAppendixFile } from '../types/appendices'

export const getLegalEntityAppendices = (entityId: number) =>
  axios
    .get<AppendicesResponse>(
      `/api/v1/org/{organization}/legal_entity/legal_entity/${entityId}/get_legal_entity_appendices/`
    )
    .then(res => res.data)

export const uploadBulkAppendices = ({ filesData, files }: { filesData: UpdateAppendixFile[]; files: File[] }) => {
  const formData = new FormData()
  formData.append('data', JSON.stringify(filesData))
  files.forEach(file => {
    formData.append('files', file)
  })
  return axios
    .post('/api/v1/org/{organization}/legal_entity/local_file_appendix/bulk_create/', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(res => res.data)
}
export const updateBulkAppendices = (payload: ReorderOtherAppendixFilesPayload[]) => {
  return axios.patch('/api/v1/org/{organization}/legal_entity/local_file_appendix/bulk_update/', payload)
}

export function patchAppendixFile({
  url,
  payload,
}: {
  url: string
  payload: Record<string, File | string | null | undefined>
}) {
  const apiOptions: AxiosRequestConfig = { headers: {} }

  const hasFiles = Object.values(payload).some(value => value instanceof File)
  if (hasFiles) {
    if (apiOptions.headers) {
      apiOptions.headers['Content-Type'] = 'multipart/form-data'
    }
  }
  return axios.patch(url, payload, apiOptions).then(res => res.data)
}
