export const BaseUrl = process.env.NEXT_PUBLIC_API_URL

export const APIS = {
  BENCHMARKS: {
    LIST: '/api/v1/org/{organization}/transaction/benchmarking/',
    ITEM: '/api/v1/org/{organization}/transaction/benchmarking/{id}/', // create, edit, post
  },
  TRANSACTIONS: {
    GET_DEFAULT_CONCLUSION: '/api/v1/org/{organization}/transaction/transactions/get_conclusion/',
    GET_AI_CONCLUSION: '/api/v1/org/{organization}/recommendations/generate_transaction_conclusion',
    GET_TRANSACTION_ATTRIBUTES: '/api/v1/org/{organization}/transaction/transaction_attributes',
  },
  USERS: {
    LIST: '/api/v1/ums/users/',
  },
  OECD_CHAT: `/api/v1/org/{organization}/recommendations/chat/oecd_chat/`,
  TINYMCE_AI_ASSISTANT: `/api/v1/org/{organization}/recommendations/textbox/`,
  DOCUMENT_CHAT: `/api/v1/org/{organization}/recommendations/document_chat/chat/`,
}
