import { IconProps } from 'assets'
import React from 'react'

const InterExchange = ({ className }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="25"
      height="325"
      viewBox="0 0 25 325"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <line x1="12.7402" y1="2.18557e-08" x2="12.7402" y2="134.5" stroke="#94A3B8" />
      <path
        d="M18.2402 164L21.2402 167L18.2402 170"
        stroke="#94A3B8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3.24023 167H21.2402" stroke="#94A3B8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.24023 161L3.24023 158L6.24023 155"
        stroke="#94A3B8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M21.2402 158H3.24023" stroke="#94A3B8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <line x1="12.7402" y1="190.5" x2="12.7402" y2="325" stroke="#94A3B8" />
    </svg>
  )
}

export default InterExchange
