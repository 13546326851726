import axios from 'api'

import { ActivityLogResponseType } from './types'

export const getAuditLogDetail = ({ from, to, page }: { from?: string; to?: string; page: number }) => {
  return axios
    .get<ActivityLogResponseType>(
      `/api/v1/org/{organization}/log/auditlog/?timestamp__gte=${from}&timestamp__lte=${to}&page=${page}`
    )
    .then(res => res.data)
}

export interface ActivityLogResponse {
  count: number
  next: string
  previous: null | string
  results: ActivityLogResult[]
}

export interface User {
  id: number
  username: string
  first_name: string
  last_name: string
}

export interface ActivityLogResult {
  id: number
  user: User
  action: string
  module_name: string
  model_name: string
  changed_data: string
  action_time: string
  organization: number
  legal_entity: null
}
