import { IconProps } from 'assets'

const Transaction = ({ className, pathClassName = 'stroke-[#F96416]' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.1484 9.66406L13.8128 12.3284L16.4772 9.66406"
        className={pathClassName}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.32031 4.33594H13.8135V12.3291"
        className={pathClassName}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.84829 8.33235L4.18391 5.66797L1.51953 8.33235"
        className={pathClassName}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6767 13.6611H4.18359V5.66797"
        className={pathClassName}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Transaction
