import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import cx from 'classnames'

import { collapseContainerConfig } from './sidebarHeader.config'

const SidebarExtender = ({ open, handleSideBarToggle }: SidebarHeaderProps) => {
  return (
    <div
      className={cx(
        'flex flex-col mt-auto w-[192px] border-solid border-[1px] border-b-0 border-l-0 border-r-0 border-blue-200 ',
        {
          'items-center !w-[44px]': !open,
        }
      )}>
      <div
        className={cx(
          `${collapseContainerConfig.base} ${open ? collapseContainerConfig.open : collapseContainerConfig.closed}`
        )}
        onClick={handleSideBarToggle}>
        {!open && (
          <KeyboardDoubleArrowRightIcon
            sx={{
              fill: '#1e3a8a',
              cursor: 'pointer',
            }}
          />
        )}
        {open && (
          <KeyboardDoubleArrowLeftIcon
            sx={{
              fill: '#1e3a8a',
              cursor: 'pointer',
            }}
          />
        )}
      </div>
    </div>
  )
}

interface SidebarHeaderProps {
  open: boolean
  handleSideBarToggle: () => void
}

export default SidebarExtender
