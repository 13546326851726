import { IconProps } from 'assets'

const EconomicAnalysis = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M21.7617 6.5H2.26172C1.84751 6.5 1.51172 6.83579 1.51172 7.25V17.75C1.51172 18.1642 1.84751 18.5 2.26172 18.5H21.7617C22.1759 18.5 22.5117 18.1642 22.5117 17.75V7.25C22.5117 6.83579 22.1759 6.5 21.7617 6.5Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#0F172A"
      />
      <path
        className={pathClassName}
        d="M12.0117 15.5C13.6686 15.5 15.0117 14.1569 15.0117 12.5C15.0117 10.8431 13.6686 9.5 12.0117 9.5C10.3549 9.5 9.01172 10.8431 9.01172 12.5C9.01172 14.1569 10.3549 15.5 12.0117 15.5Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#0F172A"
      />
      <path
        className={pathClassName}
        stroke="#0F172A"
        d="M16.5117 6.5L22.5117 11.75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        stroke="#0F172A"
        d="M16.5117 18.5L22.5117 13.25"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        stroke="#0F172A"
        d="M7.51172 6.5L1.51172 11.75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        stroke="#0F172A"
        d="M7.51172 18.5L1.51172 13.25"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default EconomicAnalysis
