import { IconProps } from 'assets'

const Assets = ({ className, pathClassName = 'fill-[#1E293B]' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M1.91926 6.08854L2.0625 6.01692V11.9831L1.91926 11.9115L1.75156 11.8276L1.66771 11.9953L1.38412 12.5625H0.9375V5.4375H1.38412L1.66771 6.00469L1.75156 6.17239L1.91926 6.08854ZM0.969657 12.9375H1.19662L1.09512 13.1405C1.03929 13.0826 0.996478 13.0133 0.969657 12.9375ZM1.41615 13.337L1.61588 12.9375H2.25H2.4375V12.75V12.5898L8.58075 15.6615L8.41305 15.7453L8.49689 15.913L8.79037 16.5L8.58074 16.9193L1.41615 13.337ZM16.5838 13.337L9.41926 16.9193L9.20963 16.5L9.50311 15.913L9.58695 15.7453L9.41926 15.6615L15.5625 12.5898V12.75V12.9375H15.75H16.3841L16.5838 13.337ZM16.9049 13.1405L16.8034 12.9375H17.0303C17.0035 13.0133 16.9607 13.0826 16.9049 13.1405ZM17.0625 12.5625H16.6159L16.3323 11.9953L16.2485 11.8276L16.0807 11.9115L15.9375 11.9831V6.01692L16.0807 6.08854L16.2485 6.1724L16.3323 6.00468L16.6159 5.4375H17.0625V12.5625ZM16.9049 4.85948C16.9607 4.91739 17.0035 4.98667 17.0303 5.0625H16.8034L16.9049 4.85948ZM16.5838 4.66302L16.3841 5.0625H15.75H15.5625V5.25V5.41015L9.41925 2.33852L9.58696 2.25467L9.50311 2.08697L9.20963 1.5L9.41925 1.08074L16.5838 4.66302ZM9.06946 0.941802L9 1.08073L8.93054 0.941802C8.97666 0.936066 9.02334 0.936066 9.06946 0.941802ZM8.58075 1.08074L8.79037 1.5L8.49689 2.08697L8.41304 2.25467L8.58074 2.33852L2.4375 5.41016V5.25V5.0625H2.25H1.61588L1.41615 4.66301L8.58075 1.08074ZM1.09512 4.85949L1.19662 5.0625H0.969657C0.996478 4.98667 1.03929 4.91739 1.09512 4.85949ZM9.08384 2.08696L9 2.12889L8.91616 2.08696L9 1.91927L9.08384 2.08696ZM1.80338 5.4375H2.0625V5.59766L1.91927 5.66928L1.80338 5.4375ZM16.1966 5.4375L16.0807 5.66928L15.9375 5.59765V5.4375H16.1966ZM15.9375 12.5625V12.4023L16.0807 12.3307L16.1966 12.5625H15.9375ZM1.91927 12.3307L2.0625 12.4023V12.5625H1.80338L1.91927 12.3307ZM9 16.0807L8.91615 15.913L9 15.8711L9.08385 15.913L9 16.0807ZM9 16.9193L9.06946 17.0582C9.02334 17.0639 8.97666 17.0639 8.93054 17.0582L9 16.9193Z"
        stroke="#1E293B"
        strokeWidth="0.375"
      />
      <path
        className={pathClassName}
        d="M1.5 5.25L9 9"
        stroke="#1E293B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id="path-3-inside-1_8976_26696" fill="white">
        <path className={pathClassName} fillRule="evenodd" clipRule="evenodd" d="M9 9V16.5V9Z" />
      </mask>
      <path
        className={pathClassName}
        d="M8.625 9V16.5H9.375V9H8.625ZM9.375 16.5V9H8.625V16.5H9.375Z"
        mask="url(#path-3-inside-1_8976_26696)"
      />
      <path
        className={pathClassName}
        d="M9 16.5V9"
        stroke="#1E293B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M16.5 5.25L9 9"
        stroke="#1E293B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M12.834 3.96215L5.66942 7.54444L5.16632 6.53818L12.3309 2.9559L12.834 3.96215ZM4.84522 6.73452L5.31969 7.68351C5.08976 7.71213 4.85657 7.59578 4.74703 7.37671C4.63752 7.15766 4.68434 6.90129 4.84522 6.73452ZM13.1551 3.76581L12.6806 2.81682C12.9106 2.78819 13.1438 2.90455 13.2533 3.12361C13.3628 3.34267 13.316 3.59904 13.1551 3.76581Z"
        stroke="#1E293B"
        strokeWidth="0.375"
      />
    </svg>
  )
}

export default Assets
