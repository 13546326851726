import { IconProps } from 'assets'
import React from 'react'

const StickyNotes = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_15919_694583)">
        <path
          d="M14.3538 9.625H10.9251C10.47 9.625 10.2425 9.625 10.0687 9.71356C9.91579 9.79148 9.79148 9.91571 9.71364 10.0686C9.62508 10.2424 9.62508 10.4699 9.62508 10.925V14.3537M14.5 8.59061V4.1C14.5 3.18992 14.5 2.73487 14.3229 2.38727C14.1671 2.0815 13.9185 1.83291 13.6128 1.67712C13.2652 1.5 12.8101 1.5 11.9 1.5H4.1C3.18992 1.5 2.73487 1.5 2.38727 1.67712C2.0815 1.83291 1.83291 2.0815 1.67712 2.38727C1.5 2.73487 1.5 3.18992 1.5 4.1V11.9C1.5 12.8101 1.5 13.2652 1.67712 13.6128C1.83291 13.9185 2.0815 14.1671 2.38727 14.3229C2.73487 14.5 3.18992 14.5 4.1 14.5H8.77155C9.18446 14.5 9.39084 14.5 9.58421 14.4518C9.75565 14.4092 9.91896 14.3388 10.0677 14.2436C10.2356 14.1362 10.3774 13.9861 10.661 13.6861L13.7894 10.3767C14.052 10.0989 14.1832 9.96008 14.277 9.80018C14.3601 9.65831 14.4212 9.50467 14.4582 9.34453C14.5 9.16391 14.5 8.97281 14.5 8.59061Z"
          stroke="#1E3A8A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={pathClassName}
        />
      </g>
      <defs>
        <clipPath id="clip0_15919_694583">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default StickyNotes
