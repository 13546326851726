import { IconsType } from 'assets/types'
import Button, { ButtonVariant } from 'components/button'
import Loading from 'components/loading'
import Modal from 'components/modal'
import Typography, { Variant } from 'components/typography'

const ItemRemovalWarning = ({
  isLoading = false,
  showModal,
  setShowModal,
  classes,
  confirmationMessage,
  doConfirmationAction,
  title = 'Warning!',
}: ItemRemovalWarningProps) => {
  const handleModalClose = () => {
    setShowModal(false)
  }

  const handleDeleteItem = () => {
    doConfirmationAction()
  }
  if (isLoading) {
    return (
      <Modal
        icon={IconsType.alertTriangle}
        title={title}
        isOpen={showModal}
        onClose={handleModalClose}
        className={`p-8 h-full ${classes?.modal?.root || ''}`}
        iconClassName={classes?.modal?.icon?.root}
        iconPathClassName={classes?.modal?.icon?.path}
        containerClassName={`w-[31rem] ${classes?.modal?.container || ''}`}>
        <div className="flex flex-col justify-center items-center h-full mt-4 w-full">
          <Loading className="flex flex-col items-center justify-center w-full h-[5rem]" />
        </div>
      </Modal>
    )
  }

  return (
    <Modal
      icon={IconsType.alertTriangle}
      title={title}
      isOpen={showModal}
      onClose={handleModalClose}
      className={`p-8 h-full ${classes?.modal?.root || ''}`}
      iconClassName={classes?.modal?.icon?.root}
      iconPathClassName={classes?.modal?.icon?.path}
      containerClassName={`w-[31rem] ${classes?.modal?.container || ''}`}>
      <div className="flex flex-col justify-between h-full mt-4 w-full">
        <Typography variant={Variant.Body}>{confirmationMessage}</Typography>
        <div className="flex w-full items-center gap-3 mt-10">
          <Button onClick={handleModalClose} isFullWidth variant={ButtonVariant.Secondary}>
            Cancel
          </Button>
          <Button onClick={handleDeleteItem} isFullWidth>
            Yes, I am sure
          </Button>
        </div>
      </div>
    </Modal>
  )
}

interface ItemRemovalWarningProps {
  isLoading?: boolean
  showModal: boolean
  confirmationMessage: string | React.ReactNode
  doConfirmationAction: () => void
  setShowModal: (x: boolean) => void
  classes?: { modal?: { root?: string; icon?: { root?: string; path?: string }; container?: string } }
  title?: string
}

export default ItemRemovalWarning
