export const camelToKebabCase = (str: string) =>
  str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? '-' : '') + $.toLowerCase())

/**
 * Process HTML content to remove empty footnotes and merge adjacent footnotes.
 * @param {string} html - The HTML content to be processed.
 * @returns {string} - The processed HTML content.
 */
export const cleanupHTML = (html: string, keepFontFamily?: boolean): string => {
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = html

  // Remove empty footnotes
  tempDiv.querySelectorAll('.footnote').forEach(footnote => {
    if (!(footnote?.textContent?.trim && footnote.textContent.trim())) {
      footnote.remove()
    }
  })

  // Merge adjacent footnotes
  const footnotes = tempDiv.querySelectorAll('.footnote')
  footnotes.forEach((footnote, index) => {
    if (index < footnotes.length - 1 && footnote.nextSibling === footnotes[index + 1]) {
      const combinedText = `${footnote?.textContent?.trim() || ''} ${footnotes[index + 1]?.textContent?.trim() || ''}`
      footnote.textContent = combinedText
      footnotes[index + 1].remove()
    }
  })
  let processedHTML = tempDiv.innerHTML
  if (!keepFontFamily) {
    processedHTML = processedHTML.replace(/font-family\s*:\s*[^;]+;/gi, '')
  }
  tempDiv.remove()

  return processedHTML
}

export const addStringAsABlob = (
  content: string,
  fileName?: string,
  srcType = 'text/plain',
  targetType = 'text/html'
) => {
  const fName = fileName || 'edited-template.html'
  const blob = new Blob([content], { type: srcType })
  const file = new File([blob], fName, { type: targetType })

  return file
}

export function generateAvatarColor(input: string): string {
  const hRange = [0, 360]
  const sRange = [50, 75]
  const lRange = [25, 60]

  // Simple hash function to generate a consistent number from a string
  function hashString(str: string): number {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i)
      hash = (hash << 5) - hash + char
      hash = hash & hash // Convert to 32bit integer
    }
    return Math.abs(hash)
  }

  // Get a consistent number from the string input
  const hash = hashString(input)

  // Generate HSL values within the specified ranges
  const h = hRange[0] + (hash % (hRange[1] - hRange[0]))
  const s = sRange[0] + (hash % (sRange[1] - sRange[0]))
  const l = lRange[0] + (hash % (lRange[1] - lRange[0]))

  // Return the HSL color string
  return `hsl(${h}, ${s}%, ${l}%)`
}
