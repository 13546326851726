import { IconProps } from 'assets'

const ActivityLog = ({ className, pathClassName = 'fill-[blue100]' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.80469 16.5C8.20251 16.5 8.58404 16.342 8.86535 16.0607C9.14665 15.7794 9.30469 15.3978 9.30469 15C9.30957 14.9501 9.30957 14.8999 9.30469 14.85L12.0947 12.06H12.3247H12.5547L14.1647 13.67C14.1647 13.67 14.1647 13.72 14.1647 13.75C14.1647 14.1478 14.3227 14.5294 14.604 14.8107C14.8853 15.092 15.2669 15.25 15.6647 15.25C16.0625 15.25 16.444 15.092 16.7253 14.8107C17.0067 14.5294 17.1647 14.1478 17.1647 13.75V13.67L20.8047 10C21.1014 10 21.3914 9.91203 21.638 9.7472C21.8847 9.58238 22.077 9.34811 22.1905 9.07403C22.304 8.79994 22.3337 8.49834 22.2759 8.20736C22.218 7.91639 22.0751 7.64912 21.8653 7.43934C21.6556 7.22956 21.3883 7.0867 21.0973 7.02882C20.8064 6.97094 20.5048 7.00065 20.2307 7.11418C19.9566 7.22771 19.7223 7.41997 19.5575 7.66664C19.3927 7.91332 19.3047 8.20333 19.3047 8.5C19.2998 8.54988 19.2998 8.60012 19.3047 8.65L15.6947 12.26H15.5347L13.8047 10.5C13.8047 10.1022 13.6467 9.72064 13.3653 9.43934C13.084 9.15804 12.7025 9 12.3047 9C11.9069 9 11.5253 9.15804 11.244 9.43934C10.9627 9.72064 10.8047 10.1022 10.8047 10.5L7.80469 13.5C7.40686 13.5 7.02533 13.658 6.74403 13.9393C6.46272 14.2206 6.30469 14.6022 6.30469 15C6.30469 15.3978 6.46272 15.7794 6.74403 16.0607C7.02533 16.342 7.40686 16.5 7.80469 16.5ZM21.3047 20.5H4.30469V3.5C4.30469 3.23478 4.19933 2.98043 4.01179 2.79289C3.82426 2.60536 3.5699 2.5 3.30469 2.5C3.03947 2.5 2.78512 2.60536 2.59758 2.79289C2.41004 2.98043 2.30469 3.23478 2.30469 3.5V21.5C2.30469 21.7652 2.41004 22.0196 2.59758 22.2071C2.78512 22.3946 3.03947 22.5 3.30469 22.5H21.3047C21.5699 22.5 21.8243 22.3946 22.0118 22.2071C22.1993 22.0196 22.3047 21.7652 22.3047 21.5C22.3047 21.2348 22.1993 20.9804 22.0118 20.7929C21.8243 20.6054 21.5699 20.5 21.3047 20.5Z"
        className={pathClassName}
      />
    </svg>
  )
}

export default ActivityLog
