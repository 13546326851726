import axios from '../../index'
import { TransferPricingMethod } from './types'

export const getTransactionMethodsList = (region: string, transactionType: string) => {
  return axios.get<TransferPricingMethod[]>(
    `/api/v1/org/{organization}/transaction/tp_method/?compliance_region=${region || ''}&transaction_type=${
      transactionType || ''
    }`
  )
}
