import axios from 'api'
import { AxiosRequestConfig } from 'axios'

import { CoreBusinessInfoPayload, CoreBusinessInfoResponse } from '../types/legalEntity'

export async function createOrUpdateCoreBusinessInfo({ id, ...variables }: CoreBusinessInfoPayload) {
  const postApiUrl = `/api/v1/org/{organization}/legal_entity/core_business_info/${id ? `${id}/` : ''}`
  const options: AxiosRequestConfig = { headers: { 'Content-Type': 'multipart/form-data' } }
  let axiosResponse
  if (id) {
    axiosResponse = await axios.patch<CoreBusinessInfoResponse>(postApiUrl, variables, options)
  } else {
    axiosResponse = await axios.post<CoreBusinessInfoResponse>(postApiUrl, variables, options)
  }

  return axiosResponse
}
export async function deleteCoreBusinessInfoFile({ id, ...variables }: CoreBusinessInfoPayload) {
  const postApiUrl = `/api/v1/org/{organization}/legal_entity/core_business_info/${id ? `${id}/` : ''}`
  return await axios.patch<CoreBusinessInfoResponse>(postApiUrl, variables)
}

export const getCoreBusinessInfo = (id: number) =>
  axios
    .get<CoreBusinessInfoResponse>(`/api/v1/org/{organization}/legal_entity/core_business_info/${id}/`)
    .then(res => res.data)
