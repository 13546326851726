import { IconProps } from 'assets'
import React from 'react'

const Building = ({ className, pathClassName = 'fill-blue900' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="87"
      height="121"
      viewBox="0 0 87 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.2712 0.759766L1.06152 25.1292L43.2712 49.4986L85.4809 25.1292L43.2712 0.759766Z"
        className={pathClassName}
      />
      <path
        d="M0.0400391 26.8965V95.8772L42.2521 120.247V51.2659L0.0400391 26.8965ZM12.4644 92.174L4.21573 87.4102V74.2586L12.4644 79.0225V92.174ZM12.4644 74.1885L4.21573 69.4246V56.2695L12.4644 61.0315V74.1885ZM12.4644 56.2048L4.21573 51.4409V38.2941L12.4644 43.0561V56.2048ZM25.3926 99.6378L17.1283 94.8666V81.715L25.3926 86.4863V99.6378ZM25.3926 81.6522L17.1283 76.881V63.7249L25.3926 68.4981V81.6522ZM25.3926 63.6692L17.1283 58.8961V45.7493L25.3926 50.5205V63.6692ZM38.3422 107.114L30.0721 102.337V89.1859L38.3422 93.9619V107.114ZM38.3422 89.1276L30.0721 84.3535V71.1994L38.3422 75.9734V89.1276ZM38.3422 71.1446L30.0721 66.3686V53.2218L38.3422 57.9978V71.1446Z"
        className={pathClassName}
      />
      <path
        d="M44.29 51.2669V120.248L61.1486 110.516V86.4834L69.4148 81.7122L69.4139 105.75L86.5021 95.8749V26.8965L44.29 51.2669ZM74.0768 79.0289L82.3255 74.2577V87.4092L74.0768 92.1731V79.0289ZM74.0768 61.0312L82.3255 56.2693V69.4253L74.0768 74.1892V61.0312ZM74.0768 43.0577L82.3255 38.2958V51.4425L74.0768 56.2045V43.0577ZM61.1486 68.4964L69.4148 63.7252V76.8793L61.1486 81.6506V68.4964ZM61.1486 50.522L69.4148 45.7507V58.8975L61.1486 63.6688V50.522ZM48.199 93.9621L56.4691 89.1854V102.328L48.199 107.113V93.9621ZM48.199 75.9708L56.4691 71.1968V84.3483L48.199 89.125V75.9708ZM48.199 57.9973L56.4691 53.2214V66.3681L48.199 71.1441V57.9973Z"
        className={pathClassName}
      />
      <path d="M63.1924 102.151L67.3764 104.568V84.9316L63.1924 87.3496V102.151Z" className={pathClassName} />
    </svg>
  )
}

export default Building
