import { IconProps } from 'assets'

const Gear = ({ className, pathClassName = 'stroke-[#1E3A8A]' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M6.85643 16.1896C5.59976 15.8154 4.48117 15.1208 3.59545 14.2004C3.92587 13.8088 4.125 13.3028 4.125 12.7504C4.125 11.5077 3.11764 10.5004 1.875 10.5004C1.79983 10.5004 1.72552 10.504 1.65225 10.5112C1.55242 10.0232 1.5 9.51789 1.5 9.00035C1.5 8.21638 1.62029 7.46049 1.84342 6.75013C1.85393 6.75028 1.86446 6.75035 1.875 6.75035C3.11764 6.75035 4.125 5.74299 4.125 4.50035C4.125 4.14361 4.04197 3.80626 3.89422 3.5066C4.76156 2.70012 5.82019 2.09656 6.99454 1.77148C7.36665 2.50088 8.12501 3.00036 9 3.00036C9.87499 3.00036 10.6334 2.50088 11.0055 1.77148C12.1798 2.09656 13.2384 2.70012 14.1058 3.5066C13.958 3.80626 13.875 4.14361 13.875 4.50035C13.875 5.74299 14.8824 6.75035 16.125 6.75035C16.1355 6.75035 16.1461 6.75028 16.1566 6.75013C16.3797 7.46049 16.5 8.21638 16.5 9.00035C16.5 9.51789 16.4476 10.0232 16.3478 10.5112C16.2745 10.504 16.2002 10.5004 16.125 10.5004C14.8824 10.5004 13.875 11.5077 13.875 12.7504C13.875 13.3028 14.0741 13.8088 14.4045 14.2004C13.5188 15.1208 12.4002 15.8154 11.1436 16.1896C10.8535 15.2823 10.0035 14.6254 9 14.6254C7.9965 14.6254 7.14645 15.2823 6.85643 16.1896Z"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M9 11.625C10.4497 11.625 11.625 10.4497 11.625 9C11.625 7.55025 10.4497 6.375 9 6.375C7.55025 6.375 6.375 7.55025 6.375 9C6.375 10.4497 7.55025 11.625 9 11.625Z"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Gear
