import { ICookie } from './useCookie.type'

const isBrowser = typeof window !== 'undefined'

export function stringifyOptions(options: ICookie) {
  return Object.keys(options).reduce((acc, key) => {
    if (key === 'days') {
      return acc
    } else {
      if (options[key as keyof ICookie] === false) {
        return acc
      } else if (options[key as keyof ICookie] === true) {
        return `${acc}; ${key}`
      } else {
        return `${acc}; ${key}=${options[key as keyof ICookie]}`
      }
    }
  }, '')
}

export const setCookie = (name: string, value: string, options: ICookie) => {
  if (!isBrowser) return

  const optionsWithDefaults: ICookie = {
    days: 7,
    path: '/',
    ...options,
  }

  const expires = new Date(Date.now() + (optionsWithDefaults?.days || 0) * 864e5).toUTCString()

  document.cookie =
    name + '=' + encodeURIComponent(value) + '; expires=' + expires + stringifyOptions(optionsWithDefaults)
}

export const getCookie = (name: string, initialValue = '') => {
  return (
    (isBrowser &&
      document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=')
        return parts[0] === name ? decodeURIComponent(parts[1]) : r
      }, '')) ||
    initialValue
  )
}
