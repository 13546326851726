import axios from '../index'
import { addOrgToTenantPayload, OrganizationListItem } from './types'

export const getOrganizations = () => {
  return axios
    .get<OrganizationListItem[]>(`/api/v1/tenant/get_organizations/`, { params: { ordering: 'name' } })
    .then(res => res.data)
}

export const deleteOrganizationFromTenant = async (id: number) => {
  return await axios.delete(`/api/v1/tenant/delete_organization/${id}/`)
}

export const addOrgToTenant = (variables: addOrgToTenantPayload) => {
  return axios.post<{ id: number }>(`/api/v1/tenant/create_organization/`, variables)
}
