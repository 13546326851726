import { IconProps } from 'assets'

const Users = ({ className, pathClassName = 'stroke-[blue100]' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4080_2509)">
        <path
          d="M23.8047 21.5V19.5C23.804 18.6137 23.509 17.7528 22.9661 17.0523C22.4231 16.3519 21.6628 15.8516 20.8047 15.63"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={pathClassName}
        />
        <path
          d="M17.8047 21.5V19.5C17.8047 18.4391 17.3833 17.4217 16.6331 16.6716C15.883 15.9214 14.8656 15.5 13.8047 15.5H5.80469C4.74382 15.5 3.72641 15.9214 2.97626 16.6716C2.22611 17.4217 1.80469 18.4391 1.80469 19.5V21.5"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={pathClassName}
        />
        <path
          d="M16.8047 3.63C17.6651 3.85031 18.4277 4.35071 18.9723 5.05232C19.5169 5.75392 19.8125 6.61683 19.8125 7.505C19.8125 8.39318 19.5169 9.25608 18.9723 9.95769C18.4277 10.6593 17.6651 11.1597 16.8047 11.38"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={pathClassName}
        />
        <path
          d="M9.80469 11.5C12.0138 11.5 13.8047 9.70914 13.8047 7.5C13.8047 5.29086 12.0138 3.5 9.80469 3.5C7.59555 3.5 5.80469 5.29086 5.80469 7.5C5.80469 9.70914 7.59555 11.5 9.80469 11.5Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={pathClassName}
        />
      </g>
      <defs>
        <clipPath id="clip0_4080_2509">
          <rect width="24" height="24" fill="white" transform="translate(0.804688 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Users
