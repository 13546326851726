import { IconProps } from 'assets'
import React from 'react'

const ApproveRight = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 18L16 12L10 6"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
    </svg>
  )
}

export default ApproveRight
