import { IconProps } from 'assets'
import React from 'react'

const Camera = ({ className, pathClassName = 'fill-blue900' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6389 14.7361C16.6389 15.1045 16.4925 15.4577 16.2321 15.7182C15.9716 15.9787 15.6183 16.125 15.25 16.125H2.74997C2.38162 16.125 2.02835 15.9787 1.76788 15.7182C1.50741 15.4577 1.36108 15.1045 1.36108 14.7361V7.09722C1.36108 6.72887 1.50741 6.3756 1.76788 6.11513C2.02835 5.85466 2.38162 5.70833 2.74997 5.70833H5.52775L6.91664 3.625H11.0833L12.4722 5.70833H15.25C15.6183 5.70833 15.9716 5.85466 16.2321 6.11513C16.4925 6.3756 16.6389 6.72887 16.6389 7.09722V14.7361Z"
        stroke="white"
        strokeWidth="1.38889"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
      <path
        d="M9.00117 13.3475C10.5353 13.3475 11.7789 12.1039 11.7789 10.5698C11.7789 9.03565 10.5353 7.79199 9.00117 7.79199C7.46704 7.79199 6.22339 9.03565 6.22339 10.5698C6.22339 12.1039 7.46704 13.3475 9.00117 13.3475Z"
        stroke="white"
        strokeWidth="1.38889"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
    </svg>
  )
}

export default Camera
