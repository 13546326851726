import axios from 'api'

interface ImportEntitiesPayload {
  legal_entities: number[]
  year: number
}

export const importBulkEntities = (variables: ImportEntitiesPayload) =>
  axios
    .post(`/api/v1/org/{organization}/legal_entity/legal_entity/import_legal_entities/`, variables)
    .then(response => response.data)

export const getImportableEntities = ({ fromYear, toYear }: { fromYear: number; toYear: number }) =>
  axios
    .get(
      `/api/v1/org/{organization}/legal_entity/legal_entity/legal_entities_to_import/?from_year=${fromYear}&to_year=${toYear}`
    )
    .then(response => response.data)
