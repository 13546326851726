import { Set, Variant } from './types/typography.types'

export class VariantType {
  Regular: string
  SemiBold: string
  Bold: string

  constructor(public variant: Variant) {
    this.variant = variant
    this.Regular = ''
    this.SemiBold = ''
    this.Bold = ''
  }

  set({ regular, semibold, bold }: Set): Set {
    this.Regular = regular
    this.SemiBold = semibold
    this.Bold = bold

    return {
      bold,
      regular,
      semibold,
    }
  }
}
