import { IconProps } from 'assets'
import React from 'react'

const CognitiveBrain = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M19.7353 31.4163C19.2861 29.9517 18.6086 28.8598 17.7026 28.1407C16.3436 27.062 14.1843 27.7324 13.3867 26.6198C12.5891 25.5071 13.9455 23.461 14.4215 22.2537C14.8975 21.0465 12.5971 20.6196 12.8656 20.2769C13.0447 20.0484 14.2072 19.389 16.3531 18.2988C16.9628 14.8216 19.215 13.083 23.1096 13.083C28.9515 13.083 31.1771 18.0357 31.1771 21.1858C31.1771 24.3359 28.4821 27.7296 24.6431 28.4613C24.2998 28.9613 24.795 29.9463 26.1285 31.4163"
        stroke="#1E3A8A"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9465 17.8957C19.6471 19.0572 19.7362 19.8729 20.2138 20.3425C20.6914 20.8122 21.5055 21.1194 22.6559 21.264C22.3949 22.7621 22.7132 23.465 23.6107 23.3725C24.5082 23.28 25.0474 22.9071 25.2284 22.2537C26.6311 22.6479 27.3913 22.318 27.509 21.264C27.6857 19.6829 26.8331 18.4217 26.4836 18.4217C26.134 18.4217 25.2284 18.3791 25.2284 17.8957C25.2284 17.4123 24.1706 17.1392 23.2159 17.1392C22.2612 17.1392 22.8358 16.4956 21.5248 16.7499C20.6508 16.9194 20.1247 17.3013 19.9465 17.8957Z"
        stroke="#1E3A8A"
        strokeWidth="1.83333"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M24.9912 22.9375C24.5251 23.2267 23.8855 23.7076 23.6162 24.0833C22.9429 25.0227 22.3968 25.5946 22.2773 26.1954"
        stroke="#1E3A8A"
        strokeWidth="1.83333"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default CognitiveBrain
