import { IconProps } from 'assets'

const AddUsers = ({ className = 'fill-blue900', pathClassName = 'stroke-blue900' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Icon / 18px / add-useres" clipPath="url(#clip0_8627_177338)">
        <path
          id="Vector"
          className={pathClassName}
          d="M17.2499 16.2656V14.9879C17.2494 14.4218 17.0281 13.8717 16.6209 13.4243C16.2137 12.9768 15.6435 12.6572 14.9999 12.5156"
          stroke="#1E3A8A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          className={pathClassName}
          d="M12.7499 16.2656V15.0156C12.7499 14.3526 12.4733 13.7167 11.981 13.2479C11.4888 12.779 10.8211 12.5156 10.1249 12.5156H4.87488C4.17868 12.5156 3.51101 12.779 3.01872 13.2479C2.52644 13.7167 2.24988 14.3526 2.24988 15.0156V16.2656"
          stroke="#1E3A8A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          className={className}
          stroke="0"
          strokeOpacity="0"
          d="M2.99988 2.01562H3.74988C3.94879 2.01562 4.13956 2.09464 4.28021 2.2353C4.42086 2.37595 4.49988 2.56671 4.49988 2.76562C4.49988 2.96454 4.42086 3.1553 4.28021 3.29595C4.13956 3.43661 3.94879 3.51562 3.74988 3.51562H2.99988V4.26562C2.99988 4.46454 2.92086 4.6553 2.78021 4.79596C2.63956 4.93661 2.44879 5.01562 2.24988 5.01562C2.05097 5.01562 1.8602 4.93661 1.71955 4.79596C1.5789 4.6553 1.49988 4.46454 1.49988 4.26562V3.51562H0.749878C0.550966 3.51562 0.3602 3.43661 0.219548 3.29595C0.0788957 3.1553 -0.00012207 2.96454 -0.00012207 2.76562C-0.00012207 2.56671 0.0788957 2.37595 0.219548 2.2353C0.3602 2.09464 0.550966 2.01562 0.749878 2.01562H1.49988V1.26562C1.49988 1.06671 1.5789 0.875947 1.71955 0.735295C1.8602 0.594643 2.05097 0.515625 2.24988 0.515625C2.44879 0.515625 2.63956 0.594643 2.78021 0.735295C2.92086 0.875947 2.99988 1.06671 2.99988 1.26562V2.01562Z"
          fill="#1E3A8A"
        />
        <path
          id="Vector_4"
          className={pathClassName}
          d="M11.9999 4.26562C12.6435 4.41486 13.214 4.75384 13.6214 5.22913C14.0288 5.70441 14.2499 6.28896 14.2499 6.89063C14.2499 7.49229 14.0288 8.07684 13.6214 8.55212C13.214 9.02741 12.6435 9.36639 11.9999 9.51563"
          stroke="#1E3A8A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          className={pathClassName}
          d="M7.87488 9.51562C9.32463 9.51562 10.4999 8.34037 10.4999 6.89062C10.4999 5.44088 9.32463 4.26562 7.87488 4.26562C6.42513 4.26562 5.24988 5.44088 5.24988 6.89062C5.24988 8.34037 6.42513 9.51562 7.87488 9.51562Z"
          stroke="#1E3A8A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8627_177338">
          <rect width="18" height="18" fill="white" transform="translate(-0.00012207 0.515625)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AddUsers
