import { IconProps } from 'assets'

const NewFilter = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        id="Vector"
        d="M11.235 3.35145C11.235 4.01773 11.005 4.58089 10.5449 5.04094C10.0849 5.50099 9.52172 5.73101 8.85545 5.73101C8.18917 5.73101 7.62601 5.50099 7.16596 5.04094C6.70591 4.58089 6.47589 4.01773 6.47589 3.35145C6.47589 2.68518 6.70591 2.12202 7.16596 1.66197C7.62601 1.20192 8.18917 0.971897 8.85545 0.971897C9.52172 0.971897 10.0849 1.20192 10.5449 1.66197C11.005 2.12202 11.235 2.68518 11.235 3.35145ZM9.96591 3.35145C9.96591 3.04476 9.85751 2.783 9.6407 2.5662C9.4239 2.3494 9.16215 2.24099 8.85545 2.24099C8.54875 2.24099 8.287 2.3494 8.07019 2.5662C7.85339 2.783 7.74499 3.04476 7.74499 3.35145C7.74499 3.65815 7.85339 3.9199 8.07019 4.13671C8.287 4.35351 8.54875 4.46191 8.85545 4.46191C9.16215 4.46191 9.4239 4.35351 9.6407 4.13671C9.85751 3.9199 9.96591 3.65815 9.96591 3.35145ZM9.49 12.0765H8.2209V7.00011H9.49V12.0765ZM5.52407 10.6488C5.52407 11.315 5.29404 11.8782 4.834 12.3382C4.37395 12.7983 3.81079 13.0283 3.14451 13.0283C2.47823 13.0283 1.91507 12.7983 1.45503 12.3382C0.994977 11.8782 0.764954 11.315 0.764954 10.6488C0.764954 9.98249 0.994977 9.41933 1.45503 8.95928C1.91507 8.49923 2.47823 8.26921 3.14451 8.26921C3.81079 8.26921 4.37395 8.49923 4.834 8.95928C5.29404 9.41933 5.52407 9.98249 5.52407 10.6488ZM4.25497 10.6488C4.25497 10.3421 4.14657 10.0803 3.92976 9.86351C3.71296 9.64671 3.45121 9.5383 3.14451 9.5383C2.83781 9.5383 2.57606 9.64671 2.35926 9.86351C2.14245 10.0803 2.03405 10.3421 2.03405 10.6488C2.03405 10.9555 2.14245 11.2172 2.35926 11.434C2.57606 11.6508 2.83781 11.7592 3.14451 11.7592C3.45121 11.7592 3.71296 11.6508 3.92976 11.434C4.14657 11.2172 4.25497 10.9555 4.25497 10.6488ZM3.77906 7.00011H2.50996L2.50996 1.92372H3.77906L3.77906 7.00011Z"
        fill="#1E3A8A"
      />
    </svg>
  )
}

export default NewFilter
