import { IconProps } from 'assets'
import React from 'react'

const ApproveTick = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M19.1852 7.22826C19.0926 7.13489 18.9824 7.06078 18.861 7.01021C18.7396 6.95963 18.6094 6.93359 18.4779 6.93359C18.3464 6.93359 18.2162 6.95963 18.0948 7.01021C17.9734 7.06078 17.8632 7.13489 17.7706 7.22826L10.3492 14.6598L7.23115 11.5318C7.13499 11.4389 7.02149 11.3659 6.89711 11.3169C6.77274 11.2678 6.63992 11.2438 6.50626 11.2461C6.37259 11.2484 6.24069 11.277 6.11808 11.3303C5.99547 11.3836 5.88456 11.4605 5.79168 11.5567C5.6988 11.6528 5.62577 11.7664 5.57675 11.8907C5.52774 12.0151 5.5037 12.1479 5.50601 12.2816C5.50832 12.4153 5.53694 12.5472 5.59023 12.6698C5.64352 12.7924 5.72043 12.9033 5.81659 12.9962L9.64187 16.8215C9.73448 16.9149 9.84466 16.989 9.96605 17.0396C10.0874 17.0902 10.2176 17.1162 10.3492 17.1162C10.4807 17.1162 10.6109 17.0902 10.7323 17.0396C10.8536 16.989 10.9638 16.9149 11.0564 16.8215L19.1852 8.69266C19.2863 8.59937 19.367 8.48615 19.4222 8.36014C19.4774 8.23412 19.5059 8.09804 19.5059 7.96046C19.5059 7.82289 19.4774 7.6868 19.4222 7.56078C19.367 7.43477 19.2863 7.32155 19.1852 7.22826Z"
      />
    </svg>
  )
}

export default ApproveTick
