import { Divider } from '@mui/material'
import Typography, { Variant } from 'components/typography'
import { IMenu } from 'organisms/sidebar/config/sidebar.config'

const EntitiesTitle = ({ menu, open }: EntitiesTitleProps) => {
  return (
    <div className="flex flex-col items-center">
      <Divider className="mb-5 mt-4 w-[11.75rem] m-auto bg-[#93C5FD]" />
      <Typography
        variant={Variant.Callout}
        type="semibold"
        className={`${!open && 'hidden'} text-blue100 mb-5  duration-1000 pl-4 mt-4 text-start w-full`}>
        {menu.type}
      </Typography>
    </div>
  )
}

interface EntitiesTitleProps {
  menu: IMenu
  open: boolean
}

export default EntitiesTitle
