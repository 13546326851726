import { IconProps } from 'assets'
import React from 'react'

const Delete = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M4.11523 3.75V16.5H15.3652V3.75H4.11523Z"
        stroke="#DC2626"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M8.24023 7.5V12.375"
        stroke="#DC2626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M11.2402 7.5V12.375"
        stroke="#DC2626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M2.24023 3.75H17.2402"
        stroke="#DC2626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M6.74023 3.75L7.97361 1.5H11.5316L12.7402 3.75H6.74023Z"
        stroke="#DC2626"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Delete
