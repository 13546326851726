import { IconProps } from 'assets'
import React from 'react'

const Graph = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        className={pathClassName}
        d="M7.5 16C7.89782 16 8.27936 15.842 8.56066 15.5607C8.84196 15.2794 9 14.8978 9 14.5C9.00488 14.4501 9.00488 14.3999 9 14.35L11.79 11.56H12.02H12.25L13.86 13.17C13.86 13.17 13.86 13.22 13.86 13.25C13.86 13.6478 14.018 14.0294 14.2993 14.3107C14.5806 14.592 14.9622 14.75 15.36 14.75C15.7578 14.75 16.1394 14.592 16.4207 14.3107C16.702 14.0294 16.86 13.6478 16.86 13.25V13.17L20.5 9.5C20.7967 9.5 21.0867 9.41203 21.3334 9.2472C21.58 9.08238 21.7723 8.84811 21.8858 8.57403C21.9993 8.29994 22.0291 7.99834 21.9712 7.70736C21.9133 7.41639 21.7704 7.14912 21.5607 6.93934C21.3509 6.72956 21.0836 6.5867 20.7926 6.52882C20.5017 6.47094 20.2001 6.50065 19.926 6.61418C19.6519 6.72771 19.4176 6.91997 19.2528 7.16664C19.088 7.41332 19 7.70333 19 8C18.9951 8.04988 18.9951 8.10012 19 8.15L15.39 11.76H15.23L13.5 10C13.5 9.60218 13.342 9.22064 13.0607 8.93934C12.7794 8.65804 12.3978 8.5 12 8.5C11.6022 8.5 11.2206 8.65804 10.9393 8.93934C10.658 9.22064 10.5 9.60218 10.5 10L7.5 13C7.10218 13 6.72064 13.158 6.43934 13.4393C6.15804 13.7206 6 14.1022 6 14.5C6 14.8978 6.15804 15.2794 6.43934 15.5607C6.72064 15.842 7.10218 16 7.5 16ZM21 20H4V3C4 2.73478 3.89464 2.48043 3.70711 2.29289C3.51957 2.10536 3.26522 2 3 2C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V21C2 21.2652 2.10536 21.5196 2.29289 21.7071C2.48043 21.8946 2.73478 22 3 22H21C21.2652 22 21.5196 21.8946 21.7071 21.7071C21.8946 21.5196 22 21.2652 22 21C22 20.7348 21.8946 20.4804 21.7071 20.2929C21.5196 20.1054 21.2652 20 21 20Z"
        fill="#1E3A8A"
      />
    </svg>
  )
}

export default Graph
