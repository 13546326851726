import { IconProps } from 'assets'

const Analysis = ({ className, pathClassName = 'stroke-blue900' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M16.5117 2.375H1.51172V6.875H16.5117V2.375Z" stroke="#1E40AF" strokeWidth="1.5" strokeLinejoin="round" />
      <path
        className={pathClassName}
        d="M1.51172 15.8863L6.07757 11.2735L8.54481 13.6363L11.561 10.625L13.2412 12.263"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path className={pathClassName} d="M16.5117 6.56445V16.3145" strokeWidth="1.5" strokeLinecap="round" />
      <path className={pathClassName} d="M1.51172 6.56445V11.8145" strokeWidth="1.5" strokeLinecap="round" />
      <path className={pathClassName} d="M4.89258 16.625H16.5118" strokeWidth="1.5" strokeLinecap="round" />
      <path className={pathClassName} d="M6.38672 4.625H14.2617" strokeWidth="1.5" strokeLinecap="round" />
      <path className={pathClassName} d="M3.76172 4.62305H4.13672" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  )
}

export default Analysis
