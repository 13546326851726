import { IconProps } from 'assets'

const DotSearchTwo = ({ className }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.3333 12.7502V3.12516C18.3333 2.6189 17.9229 2.2085 17.4166 2.2085H4.58329C4.07703 2.2085 3.66663 2.6189 3.66663 3.12516V19.6252C3.66663 20.1314 4.07703 20.5418 4.58329 20.5418H9.62496"
        stroke="#1E40AF"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.79163 5.875H14.2083"
        stroke="#1E40AF"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.79163 9.5415H14.2083"
        stroke="#1E40AF"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.79163 13.2085H10.5416"
        stroke="#1E40AF"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.875 20.5415L16.2871 18.9536L17.875 20.5415ZM16.2871 18.9536C16.7018 18.5389 16.9583 17.966 16.9583 17.3332C16.9583 16.0675 15.9323 15.0415 14.6667 15.0415C13.401 15.0415 12.375 16.0675 12.375 17.3332C12.375 18.5988 13.401 19.6248 14.6667 19.6248C15.2995 19.6248 15.8724 19.3684 16.2871 18.9536Z"
        stroke="#1E40AF"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DotSearchTwo
