import { ROUTES } from 'constants/routes'

export const ENTITIES = {
  TRANSACTION_MANAGEMENT: 'Transaction Management',
  LEGAL_ENTITY_MANAGEMENT: 'Legal Entity Management',
  TEMPLATE_MANAGEMENT: 'Template Management',
  BENCHMARK_MANAGEMENT: 'Benchmark Management',
  IC_AGREEMENTS_MANAGEMENT: 'Intercompany Agreements',
}

export const BREADCRUMBS_CONFIG = () => {
  return {
    [`${ROUTES.TRANSACTION_MANAGEMENT}/[id]`]: {
      type: 'detail',
      name: 'Transaction',
      nameIdentifier: 'txnName',
      parent: {
        name: ENTITIES.TRANSACTION_MANAGEMENT,
        route: `${ROUTES.TRANSACTION_MANAGEMENT}`,
      },
    },
    [ROUTES.CREATE_TRANSACTION]: {
      type: 'create',
      name: 'Transaction',
      itemIdentifier: 'transactionId',
      nameIdentifier: 'transactionName',
      parent: {
        name: ENTITIES.TRANSACTION_MANAGEMENT,
        route: `${ROUTES.TRANSACTION_MANAGEMENT}`,
      },
    },
    [`${ROUTES.LEGAL_ENTITY_MANAGEMENT}/[id]`]: {
      type: 'detail',
      name: 'Legal Entity',
      nameIdentifier: 'name',
      parent: {
        name: ENTITIES.LEGAL_ENTITY_MANAGEMENT,
        route: ROUTES.LEGAL_ENTITY_MANAGEMENT,
      },
    },
    [ROUTES.CREATE_LEGAL_ENTITY]: {
      type: 'create',
      name: 'Legal Entity',
      itemIdentifier: 'entityId',
      nameIdentifier: 'name',
      parent: {
        name: ENTITIES.LEGAL_ENTITY_MANAGEMENT,
        route: ROUTES.LEGAL_ENTITY_MANAGEMENT,
      },
    },
    [`${ROUTES.BENCHMARK_MANAGEMENT}/[id]`]: {
      type: 'detail',
      name: 'Benchmarks',
      nameIdentifier: 'name',
      parent: {
        name: ENTITIES.BENCHMARK_MANAGEMENT,
        route: ROUTES.BENCHMARK_MANAGEMENT,
      },
    },
    [`${ROUTES.TEMPLATE_MANAGEMENT}/[id]`]: {
      type: 'detail',
      name: 'Template',
      nameIdentifier: 'templateName',
      parent: {
        name: ENTITIES.TEMPLATE_MANAGEMENT,
        route: ROUTES.TEMPLATE_MANAGEMENT,
      },
    },
    [`${ROUTES.IC_AGREEMENTS_MANAGEMENT}/[id]`]: {
      type: 'detail',
      name: 'Intercompany Agreement',
      nameIdentifier: 'name',
      parent: {
        name: ENTITIES.IC_AGREEMENTS_MANAGEMENT,
        route: ROUTES.IC_AGREEMENTS_MANAGEMENT,
      },
    },
  }
}

export const ROUTES_TITLES = {
  [ROUTES.USER_PROFILE]: 'Profile Settings',
  [ROUTES.HOME]: 'Home',
  [ROUTES.USER_ACCESS_MANAGEMENT]: 'User Management',
  [ROUTES.SETTINGS]: 'Settings and Support',
  [ROUTES.DASHBOARD]: 'Dashboard',
  [ROUTES.ORGANIZATION]: 'Organization',
  [ROUTES.LEGAL_ENTITY_MANAGEMENT]: 'Legal Entities',
  [ROUTES.TRANSACTION_MANAGEMENT]: 'Transactions',
  [ROUTES.DOCUMENT_ARCHIVAL]: 'Documents',
  [ROUTES.BENCHMARK_MANAGEMENT]: 'Benchmarks',
  [ROUTES.ACTIVITY_LOG]: 'Activity Logs',
  [ROUTES.HELP_CENTER]: 'Help Center',
  [ROUTES.IC_AGREEMENTS_MANAGEMENT]: 'IC Agreements',
  [ROUTES.CHAT_BOT]: 'Erica',
}

export const ROUTES_WITHOUT_LAYOUT = [ROUTES.RESOURCE_NOT_FOUND, ROUTES.DOCUMENT_CHAT]
