import Hotjar from '@hotjar/browser'
import mixpanel, { Dict } from 'mixpanel-browser'

import { initializeHotjarAnalytics } from './hotjar'
import { initializeMixpanelAnalytics } from './mixpanel'

/**
 * Initialise event analytics
 */
export const initializeAnalytics = () => {
  initializeHotjarAnalytics()
  initializeMixpanelAnalytics()
}

/**
 * Send analytics events
 * @param event_name
 * @param props
 */
export const trackEvent = (eventName: string, props?: Dict) => {
  try {
    mixpanel.track(eventName, props)
    Hotjar.event(eventName)
  } catch (e) {
    console.log('Unable to track the event :(', e)
  }
}

export const resetAnanlyticsForUser = () => {
  mixpanel.reset()
}
