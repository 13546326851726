import cx from 'classnames'
import useSubscriptions from 'hooks/useSubscriptions'
import React from 'react'

function SubscriptionPill({ className }: { className?: string }) {
  const { activeSubscription, isLoading } = useSubscriptions()
  const type = activeSubscription?.type || 'Trial'
  return !isLoading ? (
    <span
      className={cx(
        `px-2 py-1 flex items-center justify-center  rounded-full w-[64px] h-[20px] text-[0.7rem] font-semibold`,
        {
          'premium-bg-gradient ': type === 'Premium',
          'bg-gray-300 text-gray-600': type !== 'Premium',
        },
        className
      )}>
      <span className={` ${type === 'Premium' ? 'premium-text-gradient' : ''} drop-shadow-md`}>{type}</span>
    </span>
  ) : (
    <></>
  )
}

export default SubscriptionPill
