import { IconProps } from 'assets'
import React from 'react'

const Footnote = ({ className, pathClassName = 'fill-neutral800 stroke-neutral800' }: IconProps): JSX.Element => {
  return (
    <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M11.2905 17.9191H9.82628L8.81312 15.0392H4.34411L3.33095 17.9191H1.93612L5.6973 7.58624H7.52932ZM8.3898 13.8595L6.5786 8.78676L4.76046 13.8595ZM19.5762 13.9844Q19.5762 14.9559 19.2987 15.7331Q19.0281 16.5103 18.5632 17.0377Q18.0705 17.5859 17.4806 17.8635Q16.891 18.1341 16.1832 18.1341Q15.524 18.1341 15.0313 17.9745Q14.5386 17.8229 14.0597 17.5592L13.9764 17.9201H12.7547V7.1213H14.0597V11.9796Q14.6079 11.5285 15.2255 11.244Q15.8431 10.9535 16.6134 10.9535Q17.6134 10.9535 18.4045 12.0083Q19.2025 13.0631 19.2025 14.9854ZM18.2562 14.0181Q18.2562 12.6302 17.7982 11.9155Q17.3403 11.1938 16.3202 11.1938Q15.7511 11.1938 15.1682 11.4436Q14.5852 11.6954 14.0855 12.084Q13.5859 12.4667 13.0307 12.9551V17.3964Q13.5859 17.6462 13.9815 17.7434Q14.384 17.8406 14.8906 17.8406Q15.1749 17.8406 15.4551 17.7962Q15.7353 17.7518 16.0709 17.6503Q16.3986 17.5488 16.7541 17.3831Q17.1096 17.2174 17.4996 16.9657Q17.8825 16.7089 18.269 16.3385L18.2562 14.0181Z"
      />
      <path
        className={pathClassName}
        d="M20.1725 8.44376H17.2969V7.90147H18.4029V4.34093H17.2969V3.85573Q17.5216 3.85573 17.7785 3.82006Q18.0354 3.78041 18.1674 3.70895Q18.3315 3.61976 18.4243 3.48419Q18.5206 3.34405 18.565 3.11215H19.118V7.89997H20.2025Z"
      />
    </svg>
  )
}

export default Footnote
