import { IconProps } from 'assets'
import React from 'react'

const DoubleLeft = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Icon / 24px / Double-Left">
        <path
          id="Vector"
          className={pathClassName}
          d="M12 18.5L6 12.5L12 6.5"
          stroke="#1E3A8A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          className={pathClassName}
          d="M18 18.5L12 12.5L18 6.5"
          stroke="#1E3A8A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export default DoubleLeft
