import { AxiosError } from 'axios'
import _map from 'lodash/map'
import { Children } from 'react'
import { toast } from 'react-toastify'
import { SelectOptions } from 'types/common.types'

const BASE_FONT_SIZE = 16

export const sleep = (ms: number) => new Promise(r => setTimeout(r, ms))

export function capitalizeFirstLetter(str: string): string {
  if (!str || typeof str !== 'string') {
    return ''
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function formatCurrency(amount: number, currency = 'USD', locale = 'en-US'): string {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  })

  return formatter?.format(amount)
}

export function formatString(s: string): string {
  // Remove underscores and replace them with spaces
  const formattedString = s.replace(/_/g, ' ')
  return capitalizeFirstLetter(formattedString)
}

export function truncateString(text: string, maxLength: number, maxLines?: number) {
  try {
    let truncatedString = text

    if (text.length > maxLength) {
      truncatedString = text.substring(0, maxLength - 3) + '...'
    }

    if (maxLines && truncatedString.split('\n').length > maxLines) {
      truncatedString = `${truncatedString.split('\n', maxLines).join('\n')} ...`
    }

    if (maxLines && truncatedString.split('</p>').length > maxLines) {
      truncatedString = `${truncatedString.split('</p>', maxLines).join('</p>')} ...`
    }

    return truncatedString
  } catch (err) {
    return text
  }
}

export const truncateHTML = (content: string | JSX.Element, maxLength: number, maxLines: number) => {
  const truncatedContent =
    typeof content === 'string'
      ? truncateString(content, maxLength, maxLines)
      : Children.toArray(content.props.children).slice(0, maxLines)

  return truncatedContent
}

export const getRemValue = () => {
  const rootFontSize =
    typeof window !== 'undefined' && typeof window.getComputedStyle === 'function'
      ? parseFloat(getComputedStyle(document.documentElement).fontSize)
      : BASE_FONT_SIZE
  const remValue = rootFontSize // This will give you the value of 1rem in pixels
  return remValue
}

export const remToPx = (remValueStr: `${number}rem`) => {
  const remValue = parseInt(remValueStr.replace('rem', ''))
  const rootFontSize =
    typeof window !== 'undefined' && typeof window.getComputedStyle === 'function'
      ? parseFloat(getComputedStyle(document.documentElement).fontSize)
      : BASE_FONT_SIZE
  const pxValue = remValue * rootFontSize
  return pxValue
}

export const createQueryParams = (params: { [x: string]: string | number | boolean | undefined | string[] }): string =>
  Object.keys(params)
    .map(k => {
      if (Array.isArray(params[k])) {
        return (params[k] as string[])?.map(v => `${k}=${encodeURIComponent(v)}`).join('&')
      }
      return `${k}=${encodeURIComponent((params[k] as string) || '')}`
    })
    .join('&')

export const getToastErrorMessage = (error: AxiosError, alt?: string) => {
  if (
    error.response?.headers?.['content-type'] === 'application/json' ||
    error.response?.headers?.['Content-Type'] === 'application/json'
  ) {
    Object.values(error.response?.data || {})
      .slice(0, 2)
      .forEach(error => {
        toast.error(JSON.stringify(error), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        })
      })
  } else {
    toast.error(alt || 'Some Error Occured')
  }
}

export const calculateWeightedAverage = (
  values: (number | undefined)[],
  weights?: (number | undefined)[]
): number | undefined => {
  if (values.length === 0) {
    return undefined
  }

  let sumOfValues = 0
  let sumOfWeights = 0

  for (let i = 0; i < values.length; i++) {
    const value = values[i] ?? 0
    const weight = weights ? weights[i] ?? 1 : 1
    sumOfValues += value * weight
    sumOfWeights += weight
  }

  if (sumOfWeights > 0) {
    return parseFloat(parseFloat(String(sumOfValues / sumOfWeights)).toFixed(2))
  } else {
    return undefined
  }
}

export const fetchSelectOptionsFromArray = <T>(
  list: ReadonlyArray<T>,
  { label, value }: { label: keyof T; value: keyof T },
  omitValue?: string | number
) => {
  return _map(list, item => ({ label: item[label], value: item[value] } as SelectOptions)).filter(option => {
    return omitValue !== option.value
  })
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function isDataEqual(backendData?: any, localData?: any) {
  if (!backendData || !localData) {
    return false
  }

  for (const backendObj of backendData) {
    const year = backendObj.year?.toString()

    for (const localObj of localData) {
      if (localObj.hasOwnProperty(year) && backendObj.hasOwnProperty(localObj.key)) {
        if (backendObj[localObj.key] !== localObj[year]) {
          return false
        }
      }
    }
  }

  return true
}

export const handleFilePreview = (
  file: File,
  setPreviewFile: (value: React.SetStateAction<string | undefined>) => void
) => {
  const reader = new FileReader()
  reader.onloadend = () => {
    setPreviewFile(reader.result as string)
  }
  reader.readAsDataURL(file)
}

export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export * from './apiUtils'
export * from './numberUtils'
export * from './stringUtils'
