import { IconProps } from 'assets'
import React from 'react'

const Smile = ({ props }: SmileProps) => {
  const { className = 'stroke-black', pathClassName } = props as IconProps
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        strokeWidth="2"
        d="M12 22C17.5229 22 22 17.5229 22 12C22 6.47715 17.5229 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5229 6.47715 22 12 22Z"
      />
      <path
        d="M15.5 15.5C15.5 15.5 14.5 17.5 12 17.5C9.5 17.5 8.5 15.5 8.5 15.5"
        strokeWidth="2"
        className={pathClassName}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path className={pathClassName} d="M15.5 9V11" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.5 9V11" className={pathClassName} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

interface SmileProps {
  props?: IconProps
}

export default Smile
