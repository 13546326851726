import { IconProps } from 'assets'

const Risks = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M9.90934 1.61936L9.90933 1.61934C9.52083 0.8925 8.47884 0.892519 8.09035 1.61937L1.24851 14.4202L1.24845 14.4203C0.881491 15.1071 1.37892 15.9376 2.15799 15.9376H15.8417C16.621 15.9376 17.1181 15.107 16.7512 14.4203L16.7512 14.4202L9.90934 1.61936ZM16.9165 14.3319L16.9165 14.3319C17.3504 15.1437 16.7622 16.1251 15.8417 16.1251H2.15799C1.23746 16.1251 0.649231 15.1437 1.08314 14.3319L1.08314 14.3319L7.925 1.53098C8.38412 0.671965 9.61558 0.671966 10.0747 1.53098L16.9165 14.3319ZM9.37485 12.3751C9.37485 12.5822 9.20697 12.7501 8.99985 12.7501C8.79274 12.7501 8.62485 12.5822 8.62485 12.3751C8.62485 12.168 8.79274 12.0001 8.99985 12.0001C9.20697 12.0001 9.37485 12.168 9.37485 12.3751ZM8.99985 6.37511C9.05162 6.37511 9.0936 6.41709 9.0936 6.46886V9.28136C9.0936 9.33313 9.05162 9.37511 8.99985 9.37511C8.94808 9.37511 8.9061 9.33313 8.9061 9.28136V6.46886C8.9061 6.41709 8.94808 6.37511 8.99985 6.37511Z"
        fill="#1E293B"
        stroke="#1E293B"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default Risks
