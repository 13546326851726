import { IconProps } from 'assets'
import React from 'react'

const HandShake = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M31.6984 21.7151L29.5742 22.7771L26.8242 17.5107L28.97 16.4378C29.1312 16.3572 29.3177 16.3431 29.4891 16.3986C29.6606 16.4541 29.8035 16.5747 29.8869 16.7345L32.0004 20.7819C32.0426 20.8628 32.0683 20.9514 32.076 21.0424C32.0837 21.1333 32.0731 21.2249 32.045 21.3118C32.0169 21.3987 31.9718 21.4791 31.9123 21.5483C31.8528 21.6175 31.7801 21.6742 31.6984 21.7151V21.7151Z"
        stroke="#1E3A8A"
        strokeWidth="1.55833"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M14.4495 22.6853L12.3254 21.6233C12.2437 21.5824 12.171 21.5257 12.1115 21.4565C12.0519 21.3873 12.0068 21.3069 11.9787 21.22C11.9506 21.1331 11.9401 21.0415 11.9477 20.9506C11.9554 20.8596 11.9811 20.771 12.0234 20.6901L14.1368 16.6427C14.2203 16.4829 14.3631 16.3623 14.5346 16.3068C14.7061 16.2513 14.8925 16.2654 15.0537 16.346L17.1995 17.4189L14.4495 22.6853Z"
        stroke="#1E3A8A"
        strokeWidth="1.55833"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M29.5742 22.7786L28.1992 24.3872L25.037 27.5495C24.953 27.6334 24.8488 27.6942 24.7343 27.7259C24.6199 27.7576 24.4993 27.7591 24.3841 27.7303L19.4034 26.4851C19.31 26.4618 19.2225 26.4191 19.1467 26.3597L14.4492 22.6865"
        stroke="#1E3A8A"
        strokeWidth="1.55833"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M28.1994 24.3867L24.4182 21.6367L23.3182 22.4617C22.8422 22.8187 22.2632 23.0117 21.6682 23.0117C21.0732 23.0117 20.4942 22.8187 20.0182 22.4617L19.5523 22.1123C19.4735 22.0532 19.4084 21.9779 19.3612 21.8914C19.3141 21.8049 19.2861 21.7093 19.2791 21.6111C19.2721 21.5128 19.2863 21.4142 19.3207 21.3219C19.3552 21.2297 19.4091 21.1458 19.4787 21.0762L22.8418 17.7131C22.9057 17.6492 22.9815 17.5986 23.0649 17.5641C23.1483 17.5295 23.2377 17.5117 23.328 17.5117H26.8244"
        stroke="#1E3A8A"
        strokeWidth="1.55833"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M17.25 17.4197L21.6603 16.1339C21.8179 16.088 21.9866 16.0999 22.1361 16.1676L25.1069 17.5118"
        stroke="#1E3A8A"
        strokeWidth="1.55833"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M20.6367 29.5428L18.0466 28.8953C17.9415 28.869 17.8441 28.8182 17.7623 28.7472L15.8242 27.0625"
        stroke="#1E3A8A"
        strokeWidth="1.55833"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default HandShake
