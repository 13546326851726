import mixpanel from 'mixpanel-browser'

const MIXPANEL_API_KEY = process.env.NEXT_PUBLIC_TINYMCE_API_KEY || ''

/**
 * Initialise mix panel event analytics
 */
export const initializeMixpanelAnalytics = () => {
  mixpanel.init(MIXPANEL_API_KEY, {
    debug: false,
    track_pageview: true,
    persistence: 'localStorage',
  })
}
