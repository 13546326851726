import { IconProps } from 'assets'

const DocumentManagement = ({ className, pathClassName = 'fill-[blue100]' }: IconProps): JSX.Element => {
  return (
    <svg
      width="17"
      height="21"
      viewBox="0 0 17 21"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.8047 7.44C16.7943 7.34813 16.7742 7.25763 16.7447 7.17V7.08C16.6966 6.97718 16.6325 6.88267 16.5547 6.8V6.8L10.5547 0.8C10.472 0.722216 10.3775 0.658081 10.2747 0.61C10.2448 0.60576 10.2145 0.60576 10.1847 0.61C10.0831 0.551741 9.97091 0.514344 9.85469 0.5H3.80469C3.00904 0.5 2.24598 0.816071 1.68337 1.37868C1.12076 1.94129 0.804688 2.70435 0.804688 3.5V17.5C0.804688 18.2956 1.12076 19.0587 1.68337 19.6213C2.24598 20.1839 3.00904 20.5 3.80469 20.5H13.8047C14.6003 20.5 15.3634 20.1839 15.926 19.6213C16.4886 19.0587 16.8047 18.2956 16.8047 17.5V7.5C16.8047 7.5 16.8047 7.5 16.8047 7.44ZM10.8047 3.91L13.3947 6.5H11.8047C11.5395 6.5 11.2851 6.39464 11.0976 6.20711C10.91 6.01957 10.8047 5.76522 10.8047 5.5V3.91ZM14.8047 17.5C14.8047 17.7652 14.6993 18.0196 14.5118 18.2071C14.3243 18.3946 14.0699 18.5 13.8047 18.5H3.80469C3.53947 18.5 3.28512 18.3946 3.09758 18.2071C2.91004 18.0196 2.80469 17.7652 2.80469 17.5V3.5C2.80469 3.23478 2.91004 2.98043 3.09758 2.79289C3.28512 2.60536 3.53947 2.5 3.80469 2.5H8.80469V5.5C8.80469 6.29565 9.12076 7.05871 9.68337 7.62132C10.246 8.18393 11.009 8.5 11.8047 8.5H14.8047V17.5ZM10.8047 12.5H9.80469V11.5C9.80469 11.2348 9.69933 10.9804 9.51179 10.7929C9.32426 10.6054 9.0699 10.5 8.80469 10.5C8.53947 10.5 8.28512 10.6054 8.09758 10.7929C7.91004 10.9804 7.80469 11.2348 7.80469 11.5V12.5H6.80469C6.53947 12.5 6.28512 12.6054 6.09758 12.7929C5.91004 12.9804 5.80469 13.2348 5.80469 13.5C5.80469 13.7652 5.91004 14.0196 6.09758 14.2071C6.28512 14.3946 6.53947 14.5 6.80469 14.5H7.80469V15.5C7.80469 15.7652 7.91004 16.0196 8.09758 16.2071C8.28512 16.3946 8.53947 16.5 8.80469 16.5C9.0699 16.5 9.32426 16.3946 9.51179 16.2071C9.69933 16.0196 9.80469 15.7652 9.80469 15.5V14.5H10.8047C11.0699 14.5 11.3243 14.3946 11.5118 14.2071C11.6993 14.0196 11.8047 13.7652 11.8047 13.5C11.8047 13.2348 11.6993 12.9804 11.5118 12.7929C11.3243 12.6054 11.0699 12.5 10.8047 12.5Z"
        className={pathClassName}
      />
    </svg>
  )
}

export default DocumentManagement
