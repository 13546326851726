import { IconProps } from 'assets'
import React from 'react'

const BenchmarkManagement = ({ className, pathClassName = 'stroke-[blue100]' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3047 22.5L10.8047 18.5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
      <path
        d="M22.8047 22.5V6.5H13.3047L13.8047 10.5L14.3047 14.5L14.8047 18.5L11.3047 22.5H22.8047Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
      <path
        className={pathClassName}
        d="M14.3047 14.5H18.3047"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M13.8047 10.5H18.3047"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.80469 2.5H12.8047L13.3047 6.5L13.8047 10.5L14.3047 14.5L14.8047 18.5H10.8047H2.80469V2.5Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
      <path
        className={pathClassName}
        d="M5.80469 6.5H9.30469"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M5.80469 10.5H9.80469"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M5.80469 14.5H10.3047"
        stroke="#DBEAFE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BenchmarkManagement
