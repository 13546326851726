import { Home, Settings } from '@mui/icons-material'
import { ROUTES } from 'constants/routes'

export const navlinks = [
  { title: 'Home', src: Home, path: ROUTES.HOME },
  { title: 'Setting & Support', src: Settings, path: ROUTES.SETTINGS },
]

export const classConfig = {
  base: 'flex cursor-pointer !h-10 w-full  !w-10  items-center justify-center  rounded-3xl',
  active: 'bg-blue-200 text-blue800',
  inactive: 'hover:bg-blue-100 text-white',
}
