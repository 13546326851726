import { IconProps } from 'assets'
import React from 'react'

const CloseIntegralLogo = ({ className }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="48"
      height="37"
      viewBox="0 0 48 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.06274 15.6001C-0.354246 17.0171 -0.354246 19.3143 1.06274 20.7313L15.6009 35.2687C17.0178 36.6856 19.3152 36.6856 20.7322 35.2687L35.2703 20.7313C36.6873 19.3143 36.6873 17.0171 35.2703 15.6001L27.6233 7.95347L25.0757 10.501L31.5684 16.9933C32.2157 17.6406 32.2157 18.6901 31.5684 19.3373L19.5059 31.3992C18.8586 32.0465 17.8091 32.0465 17.1618 31.3992L5.09938 19.3373C4.45208 18.6901 4.45208 17.6406 5.09938 16.9933L17.1618 4.93145C17.8091 4.28418 18.8586 4.28418 19.5059 4.93145L20.2456 5.67111L22.7932 3.12361L20.7322 1.06269C19.3152 -0.35423 17.0178 -0.35423 15.6009 1.06269L1.06274 15.6001Z"
        fill="url(#paint0_linear_16614_563955)"
      />
      <path
        d="M23.8683 9.29365L26.4159 6.74615L24.0006 4.33093L21.453 6.87843L23.8683 9.29365Z"
        fill="url(#paint1_linear_16614_563955)"
      />
      <path
        d="M12.7297 15.6009C11.3127 17.0178 11.3127 19.3151 12.7297 20.732L20.3765 28.3785L22.9237 25.8315L16.4304 19.3385C15.7831 18.6912 15.7831 17.6418 16.4304 16.9945L28.4928 4.9326C29.1401 4.28533 30.1896 4.28532 30.8369 4.93259L42.8993 16.9945C43.5466 17.6418 43.5466 18.6912 42.8993 19.3385L30.8369 31.4004C30.1896 32.0476 29.1401 32.0476 28.4928 31.4004L27.7537 30.6613L25.2066 33.2084L27.2678 35.2695C28.6848 36.6864 30.9822 36.6864 32.3991 35.2695L46.9373 20.732C48.3542 19.3151 48.3542 17.0178 46.9373 15.6009L32.3991 1.06347C30.9822 -0.353447 28.6848 -0.353448 27.2678 1.06347L12.7297 15.6009Z"
        fill="url(#paint2_linear_16614_563955)"
      />
      <path
        d="M23.9992 32.0011L26.5464 29.454L24.131 27.0388L21.5839 29.5858L23.9992 32.0011Z"
        fill="url(#paint3_linear_16614_563955)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_16614_563955"
          x1="29.8335"
          y1="0.00074996"
          x2="29.8335"
          y2="36.3322"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#003DEC" />
          <stop offset="1" stop-color="#0A2677" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_16614_563955"
          x1="29.8335"
          y1="0.00074996"
          x2="29.8335"
          y2="36.3322"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#003DEC" />
          <stop offset="1" stop-color="#0A2677" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_16614_563955"
          x1="29.8335"
          y1="0.00074996"
          x2="29.8335"
          y2="36.3322"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#003DEC" />
          <stop offset="1" stop-color="#0A2677" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_16614_563955"
          x1="29.8335"
          y1="0.00074996"
          x2="29.8335"
          y2="36.3322"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#003DEC" />
          <stop offset="1" stop-color="#0A2677" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default CloseIntegralLogo
