import { IconProps } from 'assets'
import React from 'react'

const ModernRightArrow = ({ className, pathClassName = 'fill-[#16A34A]' }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="17"
      viewBox="0 0 48 17"
      fill="none">
      <rect className={pathClassName} x="0.650879" y="7.125" width="36" height="3" rx="1.5" />
      <path
        className={pathClassName}
        d="M46.1183 7.71899C46.7291 8.16859 46.7291 9.08141 46.1183 9.53101L39.6217 14.3132C38.7272 14.9716 37.5209 14.0731 37.8958 13.0275L39.338 9.00466C39.426 8.7592 39.426 8.4908 39.338 8.24534L37.8958 4.2225C37.5209 3.17691 38.7272 2.27837 39.6217 2.93684L46.1183 7.71899Z"
      />
    </svg>
  )
}

export default ModernRightArrow
