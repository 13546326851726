export const config = {
  base: 'flex cursor-pointer w-full rounded-[0.5rem] p-3 gap-3',
  active: 'sidebar-bg-gradient-1',
  subField: 'bg-blue-200',
  inactive: 'hover:bg-blue-200',
}

export const titleConfig = {
  base: 'whitespace-nowrap w-full',
  hidden: 'hidden',
  active: 'text-blue-50',
  inactive: 'text-grey800',
}
