import { IconProps } from 'assets'

const NewFeedBack = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M6.99998 16.3335H9.85831L16.7416 9.5085C16.8583 9.39183 16.9166 9.246 16.9166 9.071C16.9166 8.896 16.8583 8.75016 16.7416 8.6335L14.7 6.59183C14.5833 6.47516 14.4375 6.41683 14.2625 6.41683C14.0875 6.41683 13.9416 6.47516 13.825 6.59183L6.99998 13.4752V16.3335ZM12.25 16.3335H21V14.0002H14.5833L12.25 16.3335ZM2.33331 25.6668V4.66683C2.33331 4.02516 2.56179 3.47586 3.01873 3.01891C3.47567 2.56197 4.02498 2.3335 4.66665 2.3335H23.3333C23.975 2.3335 24.5243 2.56197 24.9812 3.01891C25.4382 3.47586 25.6666 4.02516 25.6666 4.66683V18.6668C25.6666 19.3085 25.4382 19.8578 24.9812 20.3147C24.5243 20.7717 23.975 21.0002 23.3333 21.0002H6.99998L2.33331 25.6668ZM6.00831 18.6668H23.3333V4.66683H4.66665V19.9793L6.00831 18.6668Z"
        fill="#EFF6FF"
      />
    </svg>
  )
}

export default NewFeedBack
