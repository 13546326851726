import { Variant } from '../types/typography.types'
import { VariantType } from '../typography.helper'

export const mapFontToVariant: Record<Variant, string> = {
  Display1: 'text-display1',
  Display2: 'text-display2',
  Title1: 'text-title1',
  Title2: 'text-title2',
  Heading1: 'text-heading1',
  Heading2: 'text-heading2',
  Body: 'text-body',
  ContainerText: 'text-container-text',
  Callout: 'text-callout',
  Footnote: 'text-footnote',
}

export const Display1 = new VariantType(Variant.Display1).set({
  bold: `${mapFontToVariant[Variant.Display1]} font-bold`,
  regular: `${mapFontToVariant[Variant.Display1]} font-normal`,
  semibold: `${mapFontToVariant[Variant.Display1]} font-semibold`,
})

export const Display2 = new VariantType(Variant.Display2).set({
  bold: `${mapFontToVariant[Variant.Display2]} font-bold`,
  regular: `${mapFontToVariant[Variant.Display2]} font-normal`,
  semibold: `${mapFontToVariant[Variant.Display2]} font-semibold`,
})

export const Title1 = new VariantType(Variant.Title1).set({
  bold: `${mapFontToVariant[Variant.Title1]} font-bold`,
  regular: `${mapFontToVariant[Variant.Title1]} font-normal`,
  semibold: `${mapFontToVariant[Variant.Title1]} font-semibold`,
})

export const Title2 = new VariantType(Variant.Title2).set({
  bold: `${mapFontToVariant[Variant.Title2]} font-bold`,
  regular: `${mapFontToVariant[Variant.Title2]} font-normal`,
  semibold: `${mapFontToVariant[Variant.Title2]} font-semibold`,
})

export const Heading1 = new VariantType(Variant.Heading1).set({
  bold: `${mapFontToVariant[Variant.Heading1]} font-bold`,
  regular: `${mapFontToVariant[Variant.Heading1]} font-normal`,
  semibold: `${mapFontToVariant[Variant.Heading1]} font-semibold`,
})

export const Heading2 = new VariantType(Variant.Heading2).set({
  bold: `${mapFontToVariant[Variant.Heading2]} font-bold`,
  regular: `${mapFontToVariant[Variant.Heading2]} font-normal`,
  semibold: `${mapFontToVariant[Variant.Heading2]} font-semibold`,
})

export const Body = new VariantType(Variant.Body).set({
  bold: `${mapFontToVariant[Variant.Body]} font-bold`,
  regular: `${mapFontToVariant[Variant.Body]} font-normal`,
  semibold: `${mapFontToVariant[Variant.Body]} font-semibold`,
})

export const ContainerText = new VariantType(Variant.ContainerText).set({
  bold: `${mapFontToVariant[Variant.ContainerText]} font-bold`,
  regular: `${mapFontToVariant[Variant.ContainerText]} font-normal`,
  semibold: `${mapFontToVariant[Variant.ContainerText]} font-semibold`,
})

export const Callout = new VariantType(Variant.Callout).set({
  bold: `${mapFontToVariant[Variant.Callout]} font-bold`,
  regular: `${mapFontToVariant[Variant.Callout]} font-normal`,
  semibold: `${mapFontToVariant[Variant.Callout]} font-semibold`,
})

export const Footnote = new VariantType(Variant.Footnote).set({
  bold: `${mapFontToVariant[Variant.Footnote]} font-bold`,
  regular: `${mapFontToVariant[Variant.Footnote]} font-normal`,
  semibold: `${mapFontToVariant[Variant.Footnote]} font-semibold`,
})
