import { IconProps } from 'assets'

const AlertTriangle = ({ className, pathClassName = 'stroke-[#DC2626]' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5019_29410)">
        <path
          d="M7.95586 3.39589L1.60336 14.0009C1.47238 14.2277 1.40308 14.4849 1.40235 14.7468C1.40162 15.0087 1.46948 15.2662 1.59918 15.4938C1.72888 15.7213 1.9159 15.9109 2.14164 16.0438C2.36738 16.1766 2.62396 16.248 2.88586 16.2509H15.5909C15.8528 16.248 16.1093 16.1766 16.3351 16.0438C16.5608 15.9109 16.7478 15.7213 16.8775 15.4938C17.0072 15.2662 17.0751 15.0087 17.0744 14.7468C17.0736 14.4849 17.0043 14.2277 16.8734 14.0009L10.5209 3.39589C10.3872 3.17547 10.1989 2.99323 9.97425 2.86675C9.74961 2.74027 9.49616 2.67383 9.23836 2.67383C8.98056 2.67383 8.72711 2.74027 8.50246 2.86675C8.27782 2.99323 8.08956 3.17547 7.95586 3.39589V3.39589Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={pathClassName}
        />
        <path
          className={pathClassName}
          d="M9.23828 7.25V10.25"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.23828 13.25H9.24578"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={pathClassName}
        />
      </g>
      <defs>
        <clipPath id="clip0_5019_29410">
          <rect width="18" height="18" fill="white" transform="translate(0.238281 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AlertTriangle
