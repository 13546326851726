import { IconProps } from 'assets'
import React from 'react'

const DoubleTick = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="22"
      height="12"
      viewBox="0 0 22 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M6.23009 11.4786C5.93647 11.7671 5.46521 11.765 5.17415 11.474L0.584848 6.88465C0.290121 6.58993 0.292238 6.11143 0.589563 5.81932L0.944516 5.4706C1.23814 5.18212 1.7094 5.18421 2.00046 5.47527L5.7248 9.19961L6.58976 10.0646C6.88449 10.3593 6.88237 10.8378 6.58505 11.1299L6.23009 11.4786ZM11.8801 11.4693C11.5872 11.7622 11.1124 11.7622 10.8195 11.4693L6.22546 6.87527C5.9344 6.5842 5.93232 6.11295 6.22079 5.81932L6.56952 5.46437C6.86162 5.16704 7.34012 5.16493 7.63485 5.45965L10.8195 8.64428C11.1124 8.93717 11.5872 8.93717 11.8801 8.64428L20.0148 0.509653C20.3095 0.214925 20.788 0.217043 21.0801 0.514367L21.4288 0.86932C21.7173 1.16295 21.7152 1.6342 21.4241 1.92527L11.8801 11.4693ZM11.8755 5.82395C11.5844 6.11501 11.1131 6.1171 10.8195 5.82862L10.4646 5.4799C10.1672 5.18779 10.1651 4.70929 10.4598 4.41457L14.3491 0.525268C14.6402 0.234206 15.1115 0.23212 15.4051 0.520595L15.76 0.869321C16.0574 1.16143 16.0595 1.63993 15.7648 1.93465L11.8755 5.82395Z"
        fill="#1E3A8A"
      />
    </svg>
  )
}

export default DoubleTick
