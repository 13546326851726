import axios from 'api'

import { LegalEntityResponse } from '../types/legalEntity'

export const getTopFiveLegalEntity = ({ fieldName, year }: { fieldName: string; year?: number }) =>
  axios
    .get<LegalEntityResponse[]>(`/api/v1/org/{organization}/legal_entity/legal_entity/get_top_five_legal_entities/`, {
      params: { year, field_name: fieldName },
    })
    .then(res => res.data)
