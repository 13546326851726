import { AxiosError, InternalAxiosRequestConfig } from 'axios'

// Mock Error class that extends AxiosError and implements MockError
export class MockError extends Error {
  config: InternalAxiosRequestConfig
  mockData?: { status?: number; data?: unknown; message?: string }

  constructor(config: InternalAxiosRequestConfig, message?: string) {
    const error = new Error(message || 'Unknown Mock Error')
    super(error.message)

    this.config = config
    if (message) {
      this.mockData = { message }
    }
  }
}

export type CustomAxiosError = AxiosError & MockError
