import { IconProps } from '../index'

const OnboardingIllustration = ({ className }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="226"
      height="276"
      viewBox="0 0 226 276"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="162.63"
        y="-27.0342"
        width="268.71"
        height="268.71"
        rx="67"
        transform="rotate(45 162.63 -27.0342)"
        fill="url(#paint0_linear_588_28806)"
        fillOpacity="0.9"
      />
      <defs>
        <linearGradient
          id="paint0_linear_588_28806"
          x1="169.562"
          y1="-7.10616"
          x2="451.287"
          y2="21.1907"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#60A5FA" />
          <stop offset="1" stopColor="#2563EB" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default OnboardingIllustration
