import axios from 'api'
import { AxiosRequestConfig } from 'axios'
import { Promise } from 'bluebird'
import { LegalStructurePayload } from 'views/legalEntity/create/organisms/leInfo/components/managementStructure/config/updateLegalEntity.factory'

import {
  CreateAddress,
  CreateAddressResponse,
  CreateDepartmentResponse,
  CreateDirectorResponse,
  CreateLegalEntityResponse,
  Department,
  Director,
  LegalEntityResponse,
  UpdateLegalEntityResponse,
} from '../types/legalEntity'

interface UpdateLegalEntityProps {
  variables: UpdateLegalEntityResponse
  entityId: number | null
  legalStructureVars?: LegalStructurePayload
}

export async function createLegalEntity([variables, entityId]: [CreateLegalEntityResponse, number | null]) {
  const postApiUrl = `/api/v1/org/{organization}/legal_entity/legal_entity/${entityId ? `${entityId}/` : ''}`
  let axiosResponse
  if (entityId) {
    axiosResponse = await axios.patch<LegalEntityResponse>(postApiUrl, variables)
  } else {
    axiosResponse = await axios.post<LegalEntityResponse>(postApiUrl, variables)
  }

  return axiosResponse
}

export async function updateLegalEntity({ variables, entityId, legalStructureVars }: UpdateLegalEntityProps) {
  const apiOptions: AxiosRequestConfig = { headers: {} }

  const patchApiUrl = `/api/v1/org/{organization}/legal_entity/legal_entity/${entityId}/`

  if (variables?.legal_organization_chart_file instanceof File || variables?.legal_structure_file instanceof File) {
    if (apiOptions.headers) {
      apiOptions.headers['Content-Type'] = 'multipart/form-data'
    }
  }

  if (entityId && variables.legal_entity_parent_structure && variables.legal_entity_subsidiary_structure) {
    await axios.patch<LegalEntityResponse>(
      `/api/v1/org/{organization}/legal_entity/legal_entity/${entityId}/update_legal_structure/`,
      legalStructureVars
    )
  }

  const axiosResponse = await axios.patch<LegalEntityResponse>(patchApiUrl, variables, apiOptions)
  return axiosResponse
}

export async function createAddress(variables: CreateAddress) {
  const axiosResponse = await axios.post<CreateAddressResponse>(
    '/api/v1/org/{organization}/legal_entity/address/',
    variables
  )

  return axiosResponse
}

export function editAddress(variables: CreateAddress) {
  return axios
    .patch<CreateAddressResponse>(`/api/v1/org/{organization}/legal_entity/address/${variables.id}/`, variables)
    .then(res => res.data)
}
export function deleteAddress(id: number) {
  return axios
    .delete<CreateAddressResponse>(`/api/v1/org/{organization}/legal_entity/address/${id}/`)
    .then(res => res.data)
}

export async function createDepartment([variables, depId]: [Department, number | null]) {
  const postApiUrl = `/api/v1/org/{organization}/legal_entity/department/${depId ? `${depId}/` : ''}`
  let axiosResponse
  if (depId) {
    axiosResponse = await axios.patch<CreateDepartmentResponse>(postApiUrl, variables)
  } else {
    axiosResponse = await axios.post<CreateDepartmentResponse>(postApiUrl, variables)
  }

  return axiosResponse
}

export async function deleteDepartment(depId: number) {
  const axiosResponse = await axios.delete(`/api/v1/org/{organization}/legal_entity/department/${depId}/`)

  return axiosResponse
}

export async function createBoardOfDirector([variables, bodId]: [Director, number | null]) {
  const postApiUrl = `/api/v1/org/{organization}/legal_entity/director/${bodId ? `${bodId}/` : ''}`
  let axiosResponse
  if (bodId) {
    axiosResponse = await axios.patch<CreateDirectorResponse>(postApiUrl, variables)
  } else {
    axiosResponse = await axios.post<CreateDirectorResponse>(postApiUrl, variables)
  }

  return axiosResponse
}

export async function addExisitingBoardOfDirector(variables: Director[]) {
  // TODO: This logic needs to be changed and replaced with single API in v2
  const directorsResponse = await Promise.map(
    variables,
    async directorVars => {
      const directorResponse = await createBoardOfDirector([directorVars, null])
      return directorResponse.data
    },
    { concurrency: 5 }
  )

  return { status: 'success', data: directorsResponse }
}

export async function deleteBoardOfDirector(bodId: number) {
  const axiosResponse = await axios.delete(`/api/v1/org/{organization}/legal_entity/director/${bodId}/`)

  return axiosResponse
}
