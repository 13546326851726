import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import React from 'react'

const EmptyLayout = ({ children }: EmptyLayoutProps): JSX.Element => {
  return (
    <div className="flex w-full p-4 bg-neutral100 h-screen">
      <div className="flex-1 rounded-r-2xl w-full flex flex-col justify-center z-10 relative bg-white">
        {children}
        {getIcons(IconsType.onboardingIllustration, { className: 'absolute bottom-0 right-0' })}
      </div>
    </div>
  )
}

interface EmptyLayoutProps {
  children: React.ReactNode
}

export default EmptyLayout
