import { IconProps } from 'assets'
import React from 'react'

const Cross = ({ className, pathClassName = 'fill-blue900' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6551 12.4998L18.667 7.49976C18.8864 7.28028 19.0097 6.98262 19.0097 6.67224C19.0097 6.36186 18.8864 6.06419 18.667 5.84472C18.4475 5.62525 18.1498 5.50195 17.8394 5.50195C17.529 5.50195 17.2314 5.62525 17.0119 5.84472L12.0117 10.8564L7.01155 5.84472C6.79208 5.62525 6.4944 5.50195 6.18402 5.50195C5.87363 5.50195 5.57596 5.62525 5.35648 5.84472C5.13701 6.06419 5.01371 6.36186 5.01371 6.67224C5.01371 6.98262 5.13701 7.28028 5.35648 7.49976L10.3683 12.4998L5.35648 17.4999C5.24724 17.6082 5.16053 17.7371 5.10136 17.8792C5.04218 18.0212 5.01172 18.1735 5.01172 18.3274C5.01172 18.4813 5.04218 18.6336 5.10136 18.7756C5.16053 18.9177 5.24724 19.0466 5.35648 19.1549C5.46484 19.2642 5.59375 19.3509 5.73578 19.41C5.87781 19.4692 6.03015 19.4997 6.18402 19.4997C6.33788 19.4997 6.49023 19.4692 6.63226 19.41C6.77429 19.3509 6.9032 19.2642 7.01155 19.1549L12.0117 14.1432L17.0119 19.1549C17.1202 19.2642 17.2491 19.3509 17.3912 19.41C17.5332 19.4692 17.6856 19.4997 17.8394 19.4997C17.9933 19.4997 18.1456 19.4692 18.2877 19.41C18.4297 19.3509 18.5586 19.2642 18.667 19.1549C18.7762 19.0466 18.8629 18.9177 18.9221 18.7756C18.9813 18.6336 19.0117 18.4813 19.0117 18.3274C19.0117 18.1735 18.9813 18.0212 18.9221 17.8792C18.8629 17.7371 18.7762 17.6082 18.667 17.4999L13.6551 12.4998Z"
        className={pathClassName}
      />
    </svg>
  )
}

export default Cross
