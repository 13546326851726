import { ROUTES } from 'constants/routes'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { appActions } from 'store/reducer/app'

function useYearHandler() {
  const { pathname, query, replace, isReady } = useRouter()
  const { year } = useSelector((state: RootState) => state.app)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isReady) {
      if (query?.year && !isNaN(Number(query?.year)) && query.year !== String(year)) {
        dispatch(appActions.updateYear(Number(query.year)))
      } else if (
        !query.year &&
        year &&
        !(pathname === ROUTES.USER_LOGIN || pathname === ROUTES.SIGN_UP || pathname.includes('forgot-password'))
      ) {
        replace({
          pathname: pathname,
          query: {
            ...query,
            year: year,
          },
        })
      }
    }
  }, [dispatch, isReady, pathname, query, query.year, replace, year])
}

export default useYearHandler
