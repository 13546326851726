import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import React from 'react'

const ModalHeader = ({
  icon,
  title,
  iconComponent,
  iconClassName,
  iconPathClassName,
  handleClose,
}: ModalHeaderProps) => {
  return (
    <div className="flex items-center w-full justify-between">
      <div className="flex items-center gap-3">
        {(icon || iconComponent) && (
          <div className="bg-blue50 w-9 h-9 rounded-full flex flex-col items-center justify-center">
            {iconComponent ??
              (icon
                ? getIcons(icon, {
                    pathClassName: iconPathClassName,
                    className: cx('w-[1.125rem] h-[0.984rem]', iconClassName),
                  })
                : null)}
          </div>
        )}
        {title && (
          <Typography variant={Variant.Heading1} type="semibold">
            {title}
          </Typography>
        )}
      </div>
      <div
        className="hover:bg-gray-200 hover:rounded-full flex flex-col items-center justify-center p-1 cursor-pointer"
        onClick={handleClose}>
        {getIcons(IconsType.cross, { className: 'w-[18px] h-[18px]' })}
      </div>
    </div>
  )
}

interface ModalHeaderProps {
  icon?: IconsType
  title?: string | React.ReactNode
  iconClassName?: string
  iconPathClassName?: string
  handleClose: VoidFunction
  iconComponent?: React.ReactNode
}

export default ModalHeader
