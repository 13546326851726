import { IconProps } from 'assets'
import React from 'react'

const Minus = ({ className, pathClassName = 'stroke-[#1E3A8A]' }: IconProps) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M3.9375 9H14.4375"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Minus
