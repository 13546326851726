import { IconProps } from 'assets'

const FictionalLogo = ({ className }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4848 22.5532C15.3818 19.2293 18.8225 15.8125 22.1697 14.9218L43.0376 9.36819C46.3848 8.4774 48.3711 10.4499 47.4741 13.7738L41.8817 34.4969C40.9847 37.8209 37.544 41.2376 34.1968 42.1284L13.3289 47.682C9.98167 48.5727 7.9954 46.6003 8.89242 43.2763L14.4848 22.5532Z"
        fill="#60A5FA"
      />
      <path
        d="M6.1187 14.2451C7.01571 10.9212 10.4563 7.50444 13.8035 6.61365L34.6715 1.06008C38.0187 0.169299 40.005 2.14177 39.108 5.46573L33.5156 26.1888C32.6186 29.5128 29.1779 32.9295 25.8307 33.8203L4.96275 39.3739C1.61555 40.2646 -0.370718 38.2922 0.526297 34.9682L6.1187 14.2451Z"
        fill="#1D4ED8"
        fillOpacity="0.8"
      />
    </svg>
  )
}

export default FictionalLogo
