import { useQuery } from '@tanstack/react-query'
import { OrganizationDetails } from 'api/organization/types'
import { QUERIES } from 'constants/query'
import { useErrorHandler } from 'hooks/useErrorHandler'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { appActions } from 'store/reducer/app'
export const useOrganizationDetails = (enabled = true, year?: number, id?: number) => {
  const dispatch = useDispatch()
  const orgId = id || (!!Number(sessionStorage.getItem('orgId')) ? Number(sessionStorage.getItem('orgId')) : undefined)

  let organizationDetails

  const {
    data: allOrganizationDetails,
    isLoading,
    isFetching,
    error,
    isError,
    refetch: organizationDetailsRefetch,
  } = useQuery<OrganizationDetails[]>([QUERIES.GET_ORGANIZATION_DETAILS.key, orgId, year], {
    queryFn: () => QUERIES.GET_ORGANIZATION_DETAILS.function(year, orgId),
    enabled: !!orgId && enabled,
    staleTime: 0.5 * 60 * 1000, // TODO: Trying to update organisation state as less as possible
  })

  if (allOrganizationDetails && allOrganizationDetails.length) {
    organizationDetails = allOrganizationDetails[0]
  }

  useErrorHandler({ isError, error, enabled: true })

  useEffect(() => {
    if (!isFetching && allOrganizationDetails && allOrganizationDetails.length) {
      dispatch(appActions.resetOrganisation(allOrganizationDetails[0]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching])

  return { organizationDetails, organizationDetailsRefetch, isLoading, isFetching, allOrganizationDetails }
}
