import { IconProps } from 'assets'

const Sort = ({ className, pathClassName = 'stroke-blue900' }: IconProps) => (
  <svg className={className} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.65137 14.0003L5.65137 9.66699H11.6514L8.65137 14.0003Z"
      fill="#1E3A8A"
      stroke="#1E3A8A"
      strokeWidth="1.33333"
      strokeLinejoin="round"
      className={pathClassName}
    />
    <path
      d="M8.65137 2L5.65137 6.33333H11.6514L8.65137 2Z"
      fill="#1E3A8A"
      stroke="#1E3A8A"
      strokeWidth="1.33333"
      strokeLinejoin="round"
      className={pathClassName}
    />
  </svg>
)

export default Sort
