import React from 'react'

const Card = ({ children, className }: CardProps): JSX.Element => {
  return <div className={`rounded-xl p-10 shadow-cardShadow ${className}`}>{children}</div>
}

interface CardProps {
  children: React.ReactNode
  className?: string
}

export default Card
