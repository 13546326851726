import axios from 'api'

import { TransactionResponse } from '../getTransaction/types'

interface ImportTransactionPayload {
  transactions: number[]
  year: number
}

export const importBulkTransactions = ({ transactions, year }: ImportTransactionPayload) =>
  axios
    .post(`/api/v1/org/{organization}/transaction/transactions/import_transactions/`, {
      transactions,
      year,
    })
    .then(res => res.data)

export const getImportableTransaction = ({ fromYear, toYear }: { fromYear: string; toYear: string }) =>
  axios
    .get(
      `/api/v1/org/{organization}/transaction/transactions/transactions_to_import?from_year=${fromYear}&to_year=${toYear}`
    )
    .then(res => res.data)

export const uploadTransactionImportFile = ({ file }: { file: File }) =>
  axios
    .postForm<
      (TransactionResponse & {
        is_existing_transaction?: boolean
        account_number?: number
      })[]
    >(`/api/v1/org/{organization}/onboarding/transactions_onboarding/upload/`, { file })
    .then(res => res.data)

export const importUploadedTransaction = (payload: {
  selected_transactions: number[]
  rejected_transactions: number[]
}) =>
  axios
    .post(`/api/v1/org/{organization}/onboarding/transactions_onboarding/import_transactions/`, payload)
    .then(res => res.data)

export const downloadTemplateFile = () =>
  axios.get(
    `/api/v1/org/{organization}/onboarding/transactions_onboarding/download_transactions_import_excel_template/`,
    { responseType: 'blob' }
  )
