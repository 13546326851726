import { IconProps } from 'assets'

const BlueArrow = ({ className, pathClassName = 'fill-[#1E40AF]' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="166"
      height="18"
      viewBox="0 0 166 18"
      fill="none">
      <path
        className={pathClassName}
        d="M0 9C0 13.4183 3.58172 17 8 17C12.4183 17 16 13.4183 16 9C16 4.58172 12.4183 1 8 1C3.58172 1 0 4.58172 0 9ZM166 9L151 0.339746V17.6603L166 9ZM8 10.5H152.5V7.5H8V10.5Z"
      />
    </svg>
  )
}

export default BlueArrow
