import { CustomAxiosError, MockError } from 'api/types'
import { InternalAxiosRequestConfig } from 'axios'

import { mapMockData } from './config'

export const getMockResponse = (mockError: CustomAxiosError) => {
  const { mockData, config } = mockError

  // Handle mocked error (any non-2xx status code)
  if (mockData?.status && String(mockData.status)[0] !== '2') {
    const err: { code?: number } & Error = new Error(mockData.message || 'Unhandled error while calling Mock API')
    err.code = mockData.status
    throw err
  }

  // Handle mocked success
  return {
    ...mockData,
    config,
    isMock: true,
  }
}

export const mockAPIAdapter = async (config: InternalAxiosRequestConfig) => {
  if (config.isMock) {
    const mockError = new MockError(config)

    // Use the mockResponseData function to get the mock data
    const mockDatahandler = mapMockData(config.url || '')

    if (mockDatahandler instanceof Function) {
      mockError.mockData = mockDatahandler(config)
    } else if (mockDatahandler) {
      mockError.mockData = mockDatahandler
    }

    throw mockError
  }

  return config
}
