import { getAuditLogDetail } from 'api/activityLog'
import { getAllBenchmarks, getBenchmarkById } from 'api/benchmarks'
import { getCountriesList } from 'api/common'
import { getLocalFileCss } from 'api/css'
import { getAllDocumentsList, getAllowedTemplates } from 'api/documents'
import { getTPDocumentRequirements } from 'api/documents/documentRequirements'
import { getAllLocalFiles, getLocalFileById, getLocalFileCheckListById } from 'api/documents/localFiles'
import { getGlobalSearchResults } from 'api/globalSearch'
import {
  getAllAddress,
  getAllLegalEntities,
  getImportableEntities,
  getLegalEntityById,
  getTopFiveLegalEntity,
} from 'api/legalEntity'
import { getLegalEntityAppendices } from 'api/legalEntity/appendixFiles'
import { getCoreBusinessInfo } from 'api/legalEntity/coreBusinessInfo'
import { getAllBODsInfo } from 'api/legalEntity/getPrerequisites'
import { getUserDetails } from 'api/login'
import { getDepartmentsList, getHomePageData, getOrganizationDetails, getTitlesList } from 'api/organization'
import { getSubscriptionsList } from 'api/subscriptions'
import { getTemplatesMap } from 'api/templates'
import { getOrganizations } from 'api/tenant'
import { getImportableTransaction } from 'api/transactions'
import {
  getAllFunctionalProfileAttribute,
  getHistoricalTransaction,
  getTransactionDistribution,
  getTransactionFunctionalProfile,
  getTransactionsByBenchmark,
} from 'api/transactions'
import { getComparabilityMetricsList, getSelectedComparabilityFactors } from 'api/transactions/comparabilityMetrics'
import {
  getArmsLengthRangeTypes,
  getComparableResultConclusionsList,
  getTermsAndCharacteristics,
} from 'api/transactions/comparableResults'
import {
  economicAnalysisTransaction,
  getComplianceRegion,
  getLegalEntity,
  getTransactionAttributesByFilters,
  getTransactionType,
  getTransferPricingMethods,
} from 'api/transactions/createTransaction'
import { getFunctionalProfile } from 'api/transactions/getFunctionalProfile'
import { getTransactionById } from 'api/transactions/getTransaction'
import { geAllTransactionByLegalEntityId, getTransactions } from 'api/transactions/getTransaction'
import { getTransactionMethodsList } from 'api/transactions/getTransactionMethodsList'
import { getTransactionPliMethod } from 'api/transactions/getTransactionPliMethod/getTransactionPliMethod'
import { getAllUsers, getUser } from 'api/userAccess'
export const QUERIES = {
  GET_USER_DETAILS: { key: 'getUserDetails', function: getUserDetails },
  GET_TPDOCUMENT_REQUIREMENTS: { key: 'getTPDocumentRequirements', function: getTPDocumentRequirements },
  GET_ALL_FUNCTIONAL_PROFILE_ATTRIBUTE: {
    key: 'getAllFunctionalProfileAttribute',
    function: getAllFunctionalProfileAttribute,
  },
  FOREIGN_ATTRIBUTES: { key: 'foreignAttributes', function: getAllFunctionalProfileAttribute },
  GET_ALL_BENCHMARKS: { key: 'getAllBenchmarks', function: getAllBenchmarks },
  GET_BENCHMARK_BY_ID: { key: 'getBenchmarkById', function: getBenchmarkById },
  GET_DEPARTMENTS_LIST: { key: 'getDepartmentsList', function: getDepartmentsList },
  GET_LEGAL_ENTITY_BY_ID: { key: 'getLegalEntityById', function: getLegalEntityById },
  GET_LOCAL_FILE_CHECK_LIST_BY_ID: { key: 'getLocalFileCheckListById', function: getLocalFileCheckListById },
  GET_TRANSACTION_PLI_METHOD: { key: 'getTransactionPliMethod', function: getTransactionPliMethod },
  GET_SUBSCRIPTIONS: { key: 'getSubscriptions', function: getSubscriptionsList },
  GET_TITLES_LIST: { key: 'getTitlesList', function: getTitlesList },
  GET_TRANSACTION_FUNCTIONAL_PROFILE: {
    key: 'getTransactionFunctionalProfile',
    function: getTransactionFunctionalProfile,
  },
  GET_GLOBAL_SEARCH_RESULTS: { key: 'getGlobalSearchResults', function: getGlobalSearchResults },
  GET_HOME_PAGE_DATA: { key: 'getHomePageData', function: getHomePageData },
  GET_TRANSACTION_BY_BENCHMARK: { key: 'getTransactionByBenchmark', function: getTransactionsByBenchmark },
  GET_LEGAL_ENTITY: { key: 'getLegalEntity', function: getLegalEntity },
  GET_TRANSACTION_TYPE: { key: 'getTransactionType', function: getTransactionType },
  GET_FUNCTIONAL_PROFILE: { key: 'getFunctionalProfile', function: getFunctionalProfile },
  GET_TRANSFER_PRICING_METHODS: { key: 'getTransferPricingMethods', function: getTransferPricingMethods },
  GET_ORGN: { key: 'getOrgn', function: getOrganizations },
  GET_CORE_BUSINESS_INFO: { key: 'getCoreBusinessInfo', function: getCoreBusinessInfo },
  GET_LOCAL_FILE_CSS: { key: 'getLocalFileCss', function: getLocalFileCss },
  GET_ALL_ADDRESS: { key: 'getAllAddress', function: getAllAddress },
  GET_ALL_BODS_INFO: { key: 'getAllBODsInfo', function: getAllBODsInfo },
  GET_LOCAL_FILE_BY_ID: { key: 'getLocalFileById', function: getLocalFileById },
  GET_ALLOWED_TEMPLATES: { key: 'getAllowedTemplates', function: getAllowedTemplates },
  GET_LEGAL_ENTITY_APPENDICES: { key: 'getLegalEntityAppendices', function: getLegalEntityAppendices },
  GET_TRANSACTION_DISTRIBUTION_BY_TYPE: { key: 'getTransactionDistributionByType', function: getHistoricalTransaction },
  GET_ATTRIBUTES_BY_TRANSACTION: { key: 'getAttributesByTransaction', function: getTransactionAttributesByFilters },
  GET_ALL_TRANSACTION_BY_LEGAL_ENTITY_ID: {
    key: 'geAllTransactionByLegalEntityId',
    function: geAllTransactionByLegalEntityId,
  },
  ECONOMIC_ANALYSIS_TRANSACTION: { key: 'economicAnalysisTransaction', function: economicAnalysisTransaction },
  GET_COMPLIANCE_REGION: { key: 'getComplianceRegion', function: getComplianceRegion },
  GET_TRANSACTION_METHODS_LIST: { key: 'getTransactionMethodsList', function: getTransactionMethodsList },
  GET_ALL_USERS: { key: 'getAllUsers', function: getAllUsers },
  GET_USER_BY_ID: { key: 'getUser', function: getUser },
  GET_TRANSACTION_DISTRIBUTION: { key: 'getTransactionDistribution', function: getTransactionDistribution },
  GET_TRANSACTION_DISTRIBUTION_BY_NATURE: {
    key: 'getTransactionDistributionByNature',
    function: getTransactionDistribution,
  },
  GET_ALL_DOCUMENTS_LIST: { key: 'getAllDocumentsList', function: getAllDocumentsList },
  GET_ALL_LEGAL_ENTITIES: { key: 'getAllLegalEntities', function: getAllLegalEntities },
  USE_ALL_TRANSACTION_LIST: { key: 'useAllTransactionList', function: getTransactions },
  GET_COUNTRIES_LIST: { key: 'getCountriesList', function: getCountriesList },
  GET_ORGANIZATION_DETAILS: { key: 'getOrganizationDetails', function: getOrganizationDetails },
  GET_TRANSACTION_BY_ID: { key: 'getTransactionById', function: getTransactionById },
  GET_IMPORTABLE_ENTITIES: { key: 'getImportableEntities', function: getImportableEntities },
  GET_IMPORTABLE_TRANSACTION: { key: 'getImportableTransaction', function: getImportableTransaction },
  GET_TOP_FIVE_LEGAL_ENTITY: { key: 'getTopFiveLegalEntity', function: getTopFiveLegalEntity },
  GET_AUDIT_LOG_DETAIL: { key: 'getAuditLogDetail', function: getAuditLogDetail },
  GET_ALL_LOCAL_FILES: { key: 'getAllLocalFiles', function: getAllLocalFiles },
  GET_TEMPLATES_MAP: {
    key: 'getTemplatesMap',
    function: getTemplatesMap,
  },
  GET_COMPARABILITY_METRICS_LIST: {
    key: 'getComparabilityMetricsList',
    function: getComparabilityMetricsList,
  },
  GET_ARMS_LENGTH_RANGE_TYPES: {
    key: 'getArmsLengthRangeTypes',
    function: getArmsLengthRangeTypes,
  },
  GET_COMPARABLE_RESULTS_CONCLUSIONS_LIST: {
    key: 'getComparableResultConclusionsList',
    function: getComparableResultConclusionsList,
  },
  GET_TERMS_AND_CHARACTERISTICS: {
    key: 'getTermsAndCharacteristics',
    function: getTermsAndCharacteristics,
  },
  GET_SELECTED_COMPARABILITY_FACTORS: {
    key: 'getSelectedComparabilityFactors',
    function: getSelectedComparabilityFactors,
  },
}
