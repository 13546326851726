import { captureException } from '@sentry/nextjs'
import React, { Component, ErrorInfo, ReactNode } from 'react'

import ErrorBoundaryFallback from './ErrorBoundaryFallback'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
    this.resetErrorBoundary = this.resetErrorBoundary.bind(this)
  }

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
    captureException(error, {
      captureContext: {
        contexts: { react: { componentStack: errorInfo.componentStack } },
      },
      mechanism: { handled: false },
    })
  }

  resetErrorBoundary() {
    this.setState({ hasError: false })
  }

  public render() {
    if (this.state.hasError) {
      return (
        <>
          <ErrorBoundaryFallback onReset={this.resetErrorBoundary} />
        </>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
