import { BrowserClient, Feedback, getClient } from '@sentry/react'
import cx from 'classnames'
import Button, { ButtonVariant } from 'components/button'
import Typography, { Variant } from 'components/typography'
import { useRouter } from 'next/router'
import React, { useCallback } from 'react'

function ErrorBoundaryFallback({ onReset }: { onReset: VoidFunction }) {
  const router = useRouter()

  const redirectToHomePage = () => {
    router.push('/')
    onReset()
  }

  const client = getClient<BrowserClient>()
  const feedback = client?.getIntegration(Feedback)

  const handleRefreshPage = useCallback(() => {
    window.location.reload()
  }, [])

  return (
    <>
      <div className={cx('flex flex-col justify-center items-center h-screen gap-4')}>
        <Typography variant={Variant.Display1} type="semibold">
          Oops! Something went wrong
        </Typography>
        <Typography variant={Variant.Heading2} type="regular" className="text-center">
          Please refresh the page or go to home page to continue
        </Typography>
        {!!feedback && (
          <Typography variant={Variant.Callout} type="regular" className="text-center">
            If issue continues, please{' '}
            <span onClick={() => feedback?.openDialog()} className="underline cursor-pointer text-blue800">
              get in touch.
            </span>
          </Typography>
        )}
        <div className="flex justify-center gap-16">
          <Button variant={ButtonVariant.Secondary} onClick={handleRefreshPage}>
            Refresh
          </Button>
          <Button onClick={redirectToHomePage}>Go Back To Home</Button>
        </div>
      </div>
    </>
  )
}

export default ErrorBoundaryFallback
