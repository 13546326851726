import { AxiosError } from 'axios'
import { ROUTES } from 'constants/routes'
import { useRouter } from 'next/router'

export const useErrorHandler = ({ isError, error, enabled = false }: useErrorHandlerProps) => {
  const { push } = useRouter()

  if (enabled && isError && error instanceof AxiosError) {
    if (
      error.response?.status === 403 &&
      error.response?.data?.detail === 'You do not have permission to perform this action.'
    ) {
      push({ pathname: ROUTES.ACCESS_FORBIDDEN })
    }
    if (error.response?.status === 404 && error.response?.data?.detail === 'Not found.') {
      push({
        pathname: ROUTES.RESOURCE_NOT_FOUND,
        // query: { message: 'You do not have access to the request resource' },
      })
    }
  }
}

interface useErrorHandlerProps {
  isError: boolean
  error: unknown
  enabled?: boolean
}
