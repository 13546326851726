import cx from 'classnames'
import React from 'react'

import { variantMappingToType } from './config/typography.config'
import { TypographyProps } from './types/typography.types'

const Typography = ({
  type = 'regular',
  variant,
  as,
  children,
  className,
  dangerouslySetInnerHTML,
  onClick,
  style,
}: TypographyProps): JSX.Element => {
  const variantClass = variantMappingToType[variant][type]

  const Component = as || 'div'

  return (
    <Component
      style={style}
      onClick={onClick}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      className={cx(variantClass, className)}>
      {children}
    </Component>
  )
}

export default Typography
