import { IconProps } from 'assets'
import React from 'react'

const Settings = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.94659 22.0855C8.27104 21.5867 6.77959 20.6605 5.59862 19.4334C6.03919 18.9112 6.30469 18.2366 6.30469 17.4999C6.30469 15.8431 4.96154 14.4999 3.30469 14.4999C3.20446 14.4999 3.10538 14.5048 3.00768 14.5144C2.87458 13.8637 2.80469 13.19 2.80469 12.4999C2.80469 11.4546 2.96507 10.4468 3.26258 9.49964C3.27659 9.49984 3.29063 9.49994 3.30469 9.49994C4.96154 9.49994 6.30469 8.15679 6.30469 6.49994C6.30469 6.02429 6.19399 5.57449 5.99699 5.17494C7.15344 4.09963 8.56494 3.29489 10.1307 2.86145C10.6269 3.83398 11.638 4.49994 12.8047 4.49994C13.9713 4.49994 14.9825 3.83398 15.4786 2.86145C17.0444 3.29489 18.4559 4.09963 19.6124 5.17494C19.4154 5.57449 19.3047 6.02429 19.3047 6.49994C19.3047 8.15679 20.6478 9.49994 22.3047 9.49994C22.3187 9.49994 22.3328 9.49984 22.3468 9.49964C22.6443 10.4468 22.8047 11.4546 22.8047 12.4999C22.8047 13.19 22.7348 13.8637 22.6017 14.5144C22.504 14.5048 22.4049 14.4999 22.3047 14.4999C20.6478 14.4999 19.3047 15.8431 19.3047 17.4999C19.3047 18.2366 19.5702 18.9112 20.0107 19.4334C18.8298 20.6605 17.3383 21.5867 15.6628 22.0855C15.2761 20.8758 14.1427 19.9999 12.8047 19.9999C11.4667 19.9999 10.3333 20.8758 9.94659 22.0855Z"
        stroke="#DBEAFE"
        strokeWidth="2"
        strokeLinejoin="round"
        className={pathClassName}
      />
      <path
        d="M12.8047 16C14.7377 16 16.3047 14.433 16.3047 12.5C16.3047 10.567 14.7377 9 12.8047 9C10.8717 9 9.30469 10.567 9.30469 12.5C9.30469 14.433 10.8717 16 12.8047 16Z"
        stroke="#DBEAFE"
        strokeWidth="2"
        strokeLinejoin="round"
        className={pathClassName}
      />
    </svg>
  )
}

export default Settings
