import { IconProps } from 'assets'

const Benchmarking = ({ className, pathClassName = 'stroke-[#1E40AF]' }: IconProps): JSX.Element => {
  return (
    <svg className={className} width="19" height="19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M2.586 1.907a.867.867 0 0 0-.62.5c-.064.142-.065.169-.065 7.077L1.9 16.418l-.467.468-.466.467h.126c.069-.001.71-.046 1.425-.101l1.298-.101 6.32-.018 6.319-.017.187-.096a.856.856 0 0 0-.033-1.555c-.14-.066-.205-.074-.561-.075h-.403l-.009-5.66-.008-5.661-.073-.158c-.143-.309-.393-.489-.716-.515a.847.847 0 0 0-.834.43l-.087.148-.016 5.7-.016 5.7-.626.009-.625.008v-2.607c0-2.88.005-2.799-.2-3.069a.904.904 0 0 0-.287-.229.872.872 0 0 0-1.17.378c-.069.127-.069.127-.077 2.826l-.009 2.7H9.627v-4.137c0-2.982-.01-4.17-.036-4.256a.974.974 0 0 0-.439-.515c-.16-.083-.196-.09-.428-.078a1.032 1.032 0 0 0-.356.067 1.028 1.028 0 0 0-.4.43l-.067.145-.009 4.173-.008 4.172-.625-.008-.625-.009-.016-1.187c-.017-1.32-.014-1.3-.241-1.534-.182-.188-.341-.253-.614-.254-.191-.001-.267.014-.391.077a.914.914 0 0 0-.413.442c-.063.137-.067.203-.077 1.308l-.01 1.164H3.643l-.009-6.42-.008-6.421-.067-.142a.883.883 0 0 0-.973-.5"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default Benchmarking
