import { IconProps } from 'assets'
import React from 'react'

const ThreeUsers = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M17.4284 26.8333C18.694 26.8333 19.7201 25.8073 19.7201 24.5417C19.7201 23.276 18.694 22.25 17.4284 22.25C16.1627 22.25 15.1367 23.276 15.1367 24.5417C15.1367 25.8073 16.1627 26.8333 17.4284 26.8333Z"
        stroke="#1E3A8A"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M26.5964 26.8333C27.862 26.8333 28.888 25.8073 28.888 24.5417C28.888 23.276 27.862 22.25 26.5964 22.25C25.3307 22.25 24.3047 23.276 24.3047 24.5417C24.3047 25.8073 25.3307 26.8333 26.5964 26.8333Z"
        stroke="#1E3A8A"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M22.0104 17.6663C23.2761 17.6663 24.3021 16.6403 24.3021 15.3747C24.3021 14.109 23.2761 13.083 22.0104 13.083C20.7448 13.083 19.7188 14.109 19.7188 15.3747C19.7188 16.6403 20.7448 17.6663 22.0104 17.6663Z"
        stroke="#1E3A8A"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M22.0104 31.4163C22.0104 28.8851 19.9584 26.833 17.4271 26.833C14.8958 26.833 12.8438 28.8851 12.8438 31.4163"
        stroke="#1E3A8A"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M31.1784 31.4163C31.1784 28.8851 29.1263 26.833 26.5951 26.833C24.0638 26.833 22.0117 28.8851 22.0117 31.4163"
        stroke="#1E3A8A"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M26.5964 22.2503C26.5964 19.719 24.5443 17.667 22.013 17.667C19.4817 17.667 17.4297 19.719 17.4297 22.2503"
        stroke="#1E3A8A"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ThreeUsers
