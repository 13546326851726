import { IconProps } from 'assets'

const Tick = ({ className, pathClassName = '#1E3A8A' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M13.9011 5.50327L13.9024 5.50195C13.9275 5.47667 13.9573 5.45661 13.9902 5.44292C14.023 5.42923 14.0583 5.42218 14.0939 5.42218C14.1295 5.42218 14.1647 5.42923 14.1976 5.44292C14.2304 5.45661 14.2602 5.47667 14.2853 5.50195L14.2937 5.51045L14.3025 5.51856C14.3299 5.54382 14.3518 5.57447 14.3667 5.60859C14.3816 5.64271 14.3894 5.67955 14.3894 5.7168C14.3894 5.75405 14.3816 5.79089 14.3667 5.82501C14.3518 5.85913 14.3299 5.88978 14.3025 5.91504L14.2946 5.9224L14.2869 5.93008L7.68511 12.5321L7.6851 12.5321L7.68355 12.5337C7.65848 12.559 7.62865 12.579 7.59579 12.5927C7.56293 12.6064 7.52769 12.6134 7.49209 12.6134C7.45649 12.6134 7.42124 12.6064 7.38838 12.5927C7.35552 12.579 7.3257 12.559 7.30063 12.5337L7.30063 12.5337L7.29907 12.5321L4.19235 9.42527L4.19241 9.42522L4.1857 9.41873C4.15855 9.39251 4.13683 9.36119 4.12179 9.32657C4.10674 9.29195 4.09866 9.25471 4.09801 9.21696C4.09735 9.17922 4.10414 9.14172 4.11798 9.1066C4.13182 9.07148 4.15244 9.03943 4.17867 9.01228C4.20489 8.98513 4.2362 8.96342 4.27082 8.94837C4.30543 8.93333 4.34266 8.92525 4.3804 8.9246C4.41813 8.92394 4.45563 8.93073 4.49074 8.94457C4.52468 8.95794 4.55575 8.97765 4.58231 9.00264L7.11008 11.5386L7.49173 11.9215L7.87374 11.5389L13.9011 5.50327Z"
        stroke="white"
        strokeWidth="1.07874"
      />
    </svg>
  )
}

export default Tick
