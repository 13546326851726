import { IconProps } from 'assets'
import React from 'react'

const NetworkTree = ({ className }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 13.625H1.5V16.625H4.5V13.625Z"
        stroke="#1E40AF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 3.125H3V7.625H15V3.125Z"
        stroke="#1E40AF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 13.625V7.625" stroke="#1E40AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M3 13.625V10.625H15V13.625"
        stroke="#1E40AF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 13.625H13.5V16.625H16.5V13.625Z"
        stroke="#1E40AF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 13.625H7.5V16.625H10.5V13.625Z"
        stroke="#1E40AF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.25 5.375H6" stroke="#1E40AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default NetworkTree
