import { ExpandMore, Search } from '@mui/icons-material'
import { Autocomplete, InputAdornment, TextField } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { GlobalSearchResponseType } from 'api/globalSearch'
import cx from 'classnames'
import { QUERIES } from 'constants/query'
import { ROUTES } from 'constants/routes'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { appActions } from 'store/reducer/app'
import ModelNameCell from 'views/activityLog/components/modelNameCell'

import { useStyles } from '../../rightHeaderComponents/searchTransactions/styles'
const styles: React.CSSProperties = {
  backgroundColor: '#eff6ff',
  minWidth: '16rem',
  width: '36rem',
  border: 'none',
  borderRadius: '0.75rem',
}

function GlobalSearch({ className }: { className?: string }) {
  const classes = useStyles()

  const { year, refetchGlobalSearch } = useSelector((state: RootState) => state.app)
  const dispatch = useDispatch()
  const router = useRouter()

  const { data: searchResults, refetch } = useQuery([QUERIES.GET_GLOBAL_SEARCH_RESULTS.key, year], {
    queryFn: () => QUERIES.GET_GLOBAL_SEARCH_RESULTS.function({ year: year }),
    enabled: !!year && !!Number(router.query?.orgId),
  })

  useEffect(() => {
    if (refetchGlobalSearch) {
      refetch()
      dispatch(appActions.setGlobalSearchRefetch(false))
    }
  }, [dispatch, refetch, refetchGlobalSearch])

  const handleSearchResultSelect = (
    event: React.SyntheticEvent<Element, Event>,
    value: GlobalSearchResponseType | null
  ) => {
    if (value && value?.type == 'transaction') {
      router.push(
        `${ROUTES.TRANSACTION_MANAGEMENT}/${value?.id}?txnName=${encodeURIComponent(value?.name)}&year=${year}`
      )
    } else if (value && value?.type == 'legal_entity') {
      router.push(`${ROUTES.LEGAL_ENTITY_MANAGEMENT}/${value?.id}?name=${encodeURIComponent(value?.name)}&year=${year}`)
    }
  }

  return (
    <div className={cx('flex  w-[340px] items-center', className)}>
      <Autocomplete
        key={router.asPath}
        popupIcon={<ExpandMore />}
        classes={{
          paper: classes.root,
          input: classes.input,
        }}
        fullWidth
        style={styles}
        sx={{
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid ',
          },
          '& fieldset': { borderRadius: '16px !important', border: '#D9D9D9 !important' },
        }}
        getOptionLabel={(option: GlobalSearchResponseType) => option.name}
        options={searchResults || []}
        value={null}
        onChange={handleSearchResultSelect}
        multiple={false}
        autoHighlight
        className="max-w-sm xl:max-w-lg"
        renderOption={(props, option) => {
          return (
            <li
              {...props}
              className=" hover:bg-slate-100 items-center px-4 py-2 flex gap-4	cursor-pointer"
              key={option.id + option.type}>
              <div className="font-semibold text-[0.75rem] max-w-[9rem] truncate">{option.name}</div>
              <ModelNameCell
                moduleName={option.type == 'transaction' ? 'Transaction' : 'Legal Entity'}
                className="ml-auto drop-shadow-md	"
                size="small"
              />
            </li>
          )
        }}
        renderInput={param => {
          return (
            <TextField
              sx={{
                backgroundColor: 'white',
                width: '100%',
                borderRadius: 12,
              }}
              {...param}
              fullWidth
              size="small"
              placeholder="Global Search"
              InputProps={{
                ...param.InputProps,
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: 12,
                },
              }}
            />
          )
        }}
      />
    </div>
  )
}

export default GlobalSearch
