import { IconProps } from 'assets'

const LightBulb = ({ pathClassName, className }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M7.98589 13.1523H11.4906M9.73824 2.65234V3.40234M14.5112 4.62937L13.9809 5.1597M16.4883 9.4023H15.7383M3.73828 9.4023H2.98828M5.4956 5.1597L4.96527 4.62937M7.08659 12.054C5.62212 10.5895 5.62212 8.21518 7.08659 6.75071C8.55106 5.28624 10.9254 5.28624 12.3899 6.75071C13.8544 8.21518 13.8544 10.5895 12.3899 12.054L11.9796 12.4643C11.5049 12.939 11.2382 13.5828 11.2382 14.2541V14.6523C11.2382 15.4808 10.5667 16.1523 9.73824 16.1523C8.90981 16.1523 8.23824 15.4808 8.23824 14.6523V14.2541C8.23824 13.5828 7.97158 12.939 7.49691 12.4643L7.08659 12.054Z"
        stroke="#1E293B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LightBulb
