import { IconProps } from 'assets'
import React from 'react'

const CheckWithCircle = ({ className, pathClassName = 'fill-blue900' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M11.6914 6.5925L8.47387 9.8175L7.23637 8.58C7.16914 8.50149 7.0864 8.43772 6.99335 8.39271C6.9003 8.34769 6.79895 8.32239 6.69566 8.3184C6.59237 8.31441 6.48937 8.33182 6.39313 8.36952C6.29688 8.40723 6.20947 8.46442 6.13638 8.53751C6.06329 8.6106 6.0061 8.69801 5.96839 8.79426C5.93069 8.8905 5.91328 8.9935 5.91727 9.09679C5.92126 9.20008 5.94656 9.30143 5.99158 9.39448C6.03659 9.48753 6.10036 9.57026 6.17887 9.6375L7.94137 11.4075C8.01145 11.477 8.09456 11.532 8.18594 11.5693C8.27732 11.6067 8.37517 11.6256 8.47387 11.625C8.67063 11.6242 8.85918 11.5461 8.99887 11.4075L12.7489 7.6575C12.8192 7.58778 12.875 7.50483 12.913 7.41343C12.9511 7.32204 12.9707 7.22401 12.9707 7.125C12.9707 7.02599 12.9511 6.92796 12.913 6.83657C12.875 6.74517 12.8192 6.66222 12.7489 6.5925C12.6083 6.45281 12.4183 6.37441 12.2201 6.37441C12.022 6.37441 11.8319 6.45281 11.6914 6.5925ZM9.65137 1.5C8.16801 1.5 6.71796 1.93987 5.48459 2.76398C4.25122 3.58809 3.28993 4.75943 2.72227 6.12987C2.15462 7.50032 2.00609 9.00832 2.29548 10.4632C2.58487 11.918 3.29918 13.2544 4.34807 14.3033C5.39696 15.3522 6.73334 16.0665 8.18819 16.3559C9.64305 16.6453 11.1511 16.4968 12.5215 15.9291C13.8919 15.3614 15.0633 14.4001 15.8874 13.1668C16.7115 11.9334 17.1514 10.4834 17.1514 9C17.1514 8.01509 16.9574 7.03982 16.5805 6.12987C16.2036 5.21993 15.6511 4.39314 14.9547 3.6967C14.2582 3.00026 13.4314 2.44781 12.5215 2.0709C11.6116 1.69399 10.6363 1.5 9.65137 1.5ZM9.65137 15C8.46468 15 7.30464 14.6481 6.31795 13.9888C5.33125 13.3295 4.56222 12.3925 4.10809 11.2961C3.65397 10.1997 3.53515 8.99334 3.76666 7.82946C3.99817 6.66557 4.56961 5.59647 5.40873 4.75736C6.24784 3.91824 7.31694 3.3468 8.48083 3.11529C9.64471 2.88378 10.8511 3.0026 11.9475 3.45672C13.0438 3.91085 13.9809 4.67988 14.6402 5.66658C15.2995 6.65327 15.6514 7.81331 15.6514 9C15.6514 10.5913 15.0192 12.1174 13.894 13.2426C12.7688 14.3679 11.2427 15 9.65137 15Z"
        fill="#1E3A8A"
      />
    </svg>
  )
}

export default CheckWithCircle
