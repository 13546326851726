import { IconsType } from 'assets/types'

export const getColorOnCell = (moduleName: string) => {
  switch (moduleName) {
    case 'Legal Entity':
      return {
        color: '#0D9488',
        bg: '#F0FDFA',
        icon: IconsType.legalEntityManagement,
      }
    case 'Document Management':
      return {
        color: '#955CF6',
        bg: '#F7F3FF',
        icon: IconsType.documentManagement,
      }
    case 'Transaction':
      return {
        color: '#F96416',
        bg: '#FFF3ED',
        icon: IconsType.transactionManagement,
      }
    case 'Organization':
      return {
        color: '#1e40af',
        bg: '#dbeafe',
        icon: IconsType.organization,
      }
    case 'User Management':
      return {
        color: '#F4C430',
        bg: '#ffffe0',
        icon: IconsType.file,
      }
    default:
      return {
        color: '#1e40af',
        bg: '#dbeafe',
        icon: IconsType.documentManagement,
      }
  }
}
