import { COOKIE } from 'constants/cookie'
import { setCookie } from 'hooks/useCookie/useCookie.helper'

export const fetchAccessTokenFromPageProps = ({ accessToken }: { accessToken?: string }) => {
  if (accessToken) {
    setCookie(COOKIE.authToken, accessToken, {
      days: 10,
      SameSite: 'Strict',
      path: '/',
    })
  }
}
