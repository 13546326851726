import { IconProps } from 'assets'
import React from 'react'

const Save = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.1839 6.9675L11.6839 2.4675C11.6148 2.40004 11.5333 2.34656 11.4439 2.31C11.3509 2.27221 11.2517 2.25187 11.1514 2.25H5.15137C4.55463 2.25 3.98233 2.48705 3.56038 2.90901C3.13842 3.33097 2.90137 3.90326 2.90137 4.5V13.5C2.90137 14.0967 3.13842 14.669 3.56038 15.091C3.98233 15.5129 4.55463 15.75 5.15137 15.75H14.1514C14.7481 15.75 15.3204 15.5129 15.7424 15.091C16.1643 14.669 16.4014 14.0967 16.4014 13.5V7.5C16.4019 7.4013 16.383 7.30345 16.3457 7.21207C16.3084 7.12069 16.2534 7.03758 16.1839 6.9675ZM7.40137 3.75H10.4014V5.25H7.40137V3.75ZM11.9014 14.25H7.40137V12C7.40137 11.8011 7.48038 11.6103 7.62104 11.4697C7.76169 11.329 7.95246 11.25 8.15137 11.25H11.1514C11.3503 11.25 11.541 11.329 11.6817 11.4697C11.8223 11.6103 11.9014 11.8011 11.9014 12V14.25ZM14.9014 13.5C14.9014 13.6989 14.8224 13.8897 14.6817 14.0303C14.541 14.171 14.3503 14.25 14.1514 14.25H13.4014V12C13.4014 11.4033 13.1643 10.831 12.7424 10.409C12.3204 9.98705 11.7481 9.75 11.1514 9.75H8.15137C7.55463 9.75 6.98233 9.98705 6.56038 10.409C6.13842 10.831 5.90137 11.4033 5.90137 12V14.25H5.15137C4.95245 14.25 4.76169 14.171 4.62104 14.0303C4.48038 13.8897 4.40137 13.6989 4.40137 13.5V4.5C4.40137 4.30109 4.48038 4.11032 4.62104 3.96967C4.76169 3.82902 4.95245 3.75 5.15137 3.75H5.90137V6C5.90137 6.19891 5.98039 6.38968 6.12104 6.53033C6.26169 6.67098 6.45245 6.75 6.65137 6.75H11.1514C11.3503 6.75 11.541 6.67098 11.6817 6.53033C11.8223 6.38968 11.9014 6.19891 11.9014 6V4.8075L14.9014 7.8075V13.5Z"
        fill="#ffffff"
        className={pathClassName}
      />
    </svg>
  )
}

export default Save
