import { CampaignOutlined } from '@mui/icons-material'
import { ListItemIcon } from '@mui/material'
import { BrowserClient, Feedback, getClient } from '@sentry/react'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import React from 'react'

type Props = {
  open: boolean
}

function SentryFeedback({ open }: Props) {
  const client = getClient<BrowserClient>()
  const feedback = client?.getIntegration(Feedback)

  // Don't render custom feedback button if Feedback integration isn't installed
  if (!feedback) {
    return null
  }
  return (
    <div
      className={cx(
        `flex cursor-pointer mb-4  ${
          open ? 'w-[11.75rem]' : ''
        }  items-center  rounded-lg  hover:bg-blue-200  p-3 gap-3 mt-4 `
      )}
      onClick={() => feedback?.openDialog()}>
      <ListItemIcon
        sx={{
          minWidth: 0,
          color: '#1e293b',
        }}>
        {React.cloneElement(<CampaignOutlined />, { style: { fontSize: '20px' } })}
      </ListItemIcon>

      <Typography
        variant={Variant.Body}
        type="semibold"
        className={cx(
          'whitespace-nowrap w-full',
          {
            hidden: !open,
          },
          'text-sm text-grey800'
        )}>
        Feedback
      </Typography>
    </div>
  )
}

export default SentryFeedback
