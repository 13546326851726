import { IconProps } from 'assets'

const UserBusinessCeo = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.75 16.875C15.75 13.1471 12.7279 10.125 9 10.125M9 10.125C5.27209 10.125 2.25 13.1471 2.25 16.875M9 10.125L10.5 15L9 16.875L7.5 15L9 10.125ZM12 4.875C12 6.53185 10.6569 7.875 9 7.875C7.34315 7.875 6 6.53185 6 4.875C6 3.21815 7.34315 1.875 9 1.875C10.6569 1.875 12 3.21815 12 4.875Z"
        stroke="#1E40AF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
    </svg>
  )
}

export default UserBusinessCeo
