import { useCallback, useEffect, useState } from 'react'

import { getCookie, setCookie } from './useCookie.helper'
import { ICookie } from './useCookie.type'

function useCookie(key: string, initialValue?: string) {
  const [item, setItem] = useState(() => getCookie(key, initialValue))

  const updateItem = useCallback(
    (value: string, options: ICookie) => {
      setItem(value)
      setCookie(key, value, options)
    },
    [key]
  )

  useEffect(() => {
    const timer = setInterval(
      () => {
        const cookie = getCookie(key, initialValue)
        if (cookie != item) {
          setItem(cookie)
        }
      },
      item ? 1000 : 100
    )
    return () => {
      clearInterval(timer)
    }
  }, [initialValue, item, key])

  const removeItem = useCallback(() => {
    setItem('')
    setCookie(key, '', { maxAge: -1 })
  }, [key])

  return {
    value: item,
    updateValue: updateItem,
    removeItem,
  }
}

export default useCookie
