import { LOGIN_USER } from 'api/login/login.constants'
import { APIS } from 'constants/apis'
import getAiConclusions from 'mock/data/transactions/conclusion/aiConclusions'
import getDefaultConclusion from 'mock/data/transactions/conclusion/defaultConclusion'
import getTransactionAttributes from 'mock/data/transactions/functionalAnalysis'
import getAllUsers from 'mock/data/users/list'

import benchmarksList from '../data/benchmarks/list'
import currentUserData from '../data/loginUser/currentUser'

export const mockDataMapper = {
  [LOGIN_USER.CURRENT_USER]: currentUserData,
  [APIS.BENCHMARKS.LIST]: benchmarksList,
  [APIS.TRANSACTIONS.GET_DEFAULT_CONCLUSION]: getDefaultConclusion,
  [APIS.TRANSACTIONS.GET_AI_CONCLUSION]: getAiConclusions,
  [APIS.TRANSACTIONS.GET_TRANSACTION_ATTRIBUTES]: getTransactionAttributes,
  [APIS.USERS.LIST]: getAllUsers,
}

export const mapMockData = (url: string) => {
  const matchingKey = Object.keys(mockDataMapper).find((key: string) => {
    const regex = new RegExp(`^${key.replace(/\{[^}]+\}/g, '[^/]+')}$`)
    return regex.test(url)
  })

  return matchingKey ? mockDataMapper[matchingKey] : null
}
