import { ButtonConfig, ButtonSize, ButtonVariant } from './button.types'

const disabledClass =
  'disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:cursor-not-allowed disabled:hover:bg-gray-200'
const disabledIconClass = 'fill-gray-500'

export const baseClass = [
  'text-callout font-semibold rounded-lg', // basic
]

const primaryButtonConfig: ButtonConfig = {
  background: 'bg-blue900',
  border: 'border-none',
  hover: 'hover:bg-blue800 hover:cursor-pointer hover:shadow-buttonShadow',
  text: 'text-white',
  disabled: disabledClass,
  focus: 'focus:bg-blue800',
  icon: 'stroke-white',
  disabledIcon: disabledIconClass,
}

const secondaryButtonConfig: ButtonConfig = {
  background: 'bg-blue-50',
  border: 'border border-blue-100 border-solid',
  hover: 'hover:bg-white hover:shadow-buttonShadow hover:cursor-pointer',
  disabled: disabledClass,
  text: 'text-blue900 leading-[0]',
  focus: 'focus:bg-blue100 focus:shadow-none',
  icon: 'stroke-blue900',
  disabledIcon: disabledIconClass,
}

export const tertiaryButtonConfig: ButtonConfig = {
  background: 'bg-transparent',
  border: 'border-none',
  disabled: 'disabled:text-grey500 disabled:hover:cursor-not-allowed',
  focus: '',
  hover: 'hover:cursor-pointer',
  text: 'text-blue900',
  icon: 'stroke-blue900',
  disabledIcon: disabledIconClass,
}

export const prominentButtonConfig: ButtonConfig = {
  background: 'bg-orange-600',
  border: 'border-none',
  disabled: disabledClass,
  focus: 'focus:bg-orange-700',
  hover: 'hover:bg-orange-700 hover:cursor-pointer hover:shadow-buttonShadow',
  text: 'text-white',
  icon: 'stroke-white',
  disabledIcon: disabledIconClass,
}

const gradientButtonConfig: ButtonConfig = {
  background:
    'bg-[radial-gradient(circle_farthest-side_at_50%_150%,_#572ca7_0%,_#6a3fda_55%,_rgba(30,58,138,1)_100%)] shadow-md shadow-indigo-500/50 bg-[length:100%_150%] transition-shadow transition-transform',
  border: 'border border-solid border-[#8a3fda]',
  focus:
    'focus:shadow-lg focus:shadow-indigo-500/50 focus:bg-[radial-gradient(circle_farthest-side_at_50%_150%,_#572ca7_10%,_#6a3fda_65%,_rgba(30,58,138,1)_100%)]',
  hover:
    'hover:cursor-pointer hover:shadow-lg hover:shadow-indigo-500/50 hover:bg-[radial-gradient(circle_farthest-side_at_50%_150%,_#572ca7_10%,_#6a3fda_65%,_rgba(30,58,138,1)_100%)]',
  text: 'text-white',
  disabled: disabledClass,
  icon: 'stroke-white',
  disabledIcon: disabledIconClass,
}

export const variants = {
  [ButtonVariant.Primary]: primaryButtonConfig,
  [ButtonVariant.Secondary]: secondaryButtonConfig,
  [ButtonVariant.Tertiary]: tertiaryButtonConfig,
  [ButtonVariant.Prominent]: prominentButtonConfig,
  [ButtonVariant.AIGradient]: gradientButtonConfig,
}

export const buttonSize = {
  [ButtonSize.Large]: 'px-[0.75rem] py-[0.938rem] h-12',
  [ButtonSize.Medium]: 'px-[0.75rem] py-[0.75rem] h-[2.625rem]',
  [ButtonSize.Small]: 'px-[0.75rem] py-[0.438rem] h-8',
  [ButtonSize.ExtraSmall]: 'px-[0.438rem] py-[0.438rem] h-7',
}
