import { IconProps } from '../index'

const Verified = ({ className }: IconProps): JSX.Element => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="24" height="24" rx="12" fill="#10B981" />
      <rect
        x="8.17252"
        y="10.5106"
        width="6.30785"
        height="2.36544"
        rx="1.18272"
        transform="rotate(45 8.17252 10.5106)"
        fill="#ECFDF5"
        className={className}
      />
      <rect
        x="9.42264"
        y="14.7612"
        width="10.4718"
        height="2.36544"
        rx="1.18272"
        transform="rotate(-45 9.42264 14.7612)"
        fill="#ECFDF5"
      />
    </svg>
  )
}

export default Verified
