import { AxiosRequestConfig } from 'axios'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const benchmarkListData = (config: AxiosRequestConfig) => ({
  status: 200,
  data: [
    {
      id: 1,
      name: 'Benchmark_apac_2022_1',
      region: 'APAC',
      year: 2022,
      benchmarking_file: 'https://path-to-file-1.csv',
      benchmarking_file_name: 'Action Hero',
      quarterly_analysis: [
        {
          id: 22,
          highest_observed_value: 36,
          upper_quartile: 22,
          median: 20,
          lower_quartile: 18,
          lowest_observed_value: 17,
          number_of_observations: 16,
          year: 0,
        },
        {
          id: 23,
          highest_observed_value: 36,
          upper_quartile: 22,
          median: 20,
          lower_quartile: 18,
          lowest_observed_value: 17,
          number_of_observations: 16,
          year: 1,
        },
        {
          id: 24,
          highest_observed_value: 36,
          upper_quartile: 22,
          median: 20,
          lower_quartile: 18,
          lowest_observed_value: 17,
          number_of_observations: 16,
          year: 2,
        },
      ],
    },
    {
      id: 2,
      name: 'Benchmark_apac_2022_2',
      region: 'APAC',
      year: 2022,
      benchmarking_file: 'https://path-to-file-2.csv',
      benchmarking_file_name: 'Camera Rolling',
      quarterly_analysis: [
        {
          id: 25,
          highest_observed_value: 36,
          upper_quartile: 22,
          median: 20,
          lower_quartile: 18,
          lowest_observed_value: 17,
          number_of_observations: 16,
          year: 0,
        },
        {
          id: 26,
          highest_observed_value: 36,
          upper_quartile: 22,
          median: 20,
          lower_quartile: 18,
          lowest_observed_value: 17,
          number_of_observations: 16,
          year: 1,
        },
        {
          id: 27,
          highest_observed_value: 36,
          upper_quartile: 22,
          median: 20,
          lower_quartile: 18,
          lowest_observed_value: 17,
          number_of_observations: 16,
          year: 2,
        },
      ],
    },
    {
      id: 3,
      name: 'Benchmark_apac_2022_5',
      region: 'APAC',
      year: 2022,
      benchmarking_file: null,
      benchmarking_file_name: null,
      quarterly_analysis: [
        {
          id: 32,
          highest_observed_value: 36,
          upper_quartile: 22,
          median: 20,
          lower_quartile: 18,
          lowest_observed_value: 17,
          number_of_observations: 16,
          year: 0,
        },
        {
          id: 33,
          highest_observed_value: 36,
          upper_quartile: 22,
          median: 20,
          lower_quartile: 18,
          lowest_observed_value: 17,
          number_of_observations: 16,
          year: 1,
        },
        {
          id: 34,
          highest_observed_value: 36,
          upper_quartile: 22,
          median: 20,
          lower_quartile: 18,
          lowest_observed_value: 17,
          number_of_observations: 16,
          year: 2,
        },
      ],
    },
    {
      id: 4,
      name: 'Benchmark_apac_2023_1',
      region: 'APAC',
      year: 2023,
      benchmarking_file: 'https://path-to-file-3.csv',
      benchmarking_file_name: 'Mission Impossible',
      quarterly_analysis: [
        {
          id: 41,
          highest_observed_value: 36,
          upper_quartile: 22,
          median: 20,
          lower_quartile: 18,
          lowest_observed_value: 17,
          number_of_observations: 16,
          year: 0,
        },
        {
          id: 42,
          highest_observed_value: 36,
          upper_quartile: 22,
          median: 20,
          lower_quartile: 18,
          lowest_observed_value: 17,
          number_of_observations: 16,
          year: 1,
        },
        {
          id: 43,
          highest_observed_value: 36,
          upper_quartile: 22,
          median: 20,
          lower_quartile: 18,
          lowest_observed_value: 17,
          number_of_observations: 16,
          year: 2,
        },
      ],
    },
    {
      id: 5,
      name: 'Benchmark_apac_2023_2',
      region: 'APAC',
      year: 2023,
      benchmarking_file: null,
      benchmarking_file_name: null,
      quarterly_analysis: [
        {
          id: 53,
          highest_observed_value: 36,
          upper_quartile: 22,
          median: 20,
          lower_quartile: 18,
          lowest_observed_value: 17,
          number_of_observations: 16,
          year: 0,
        },
        {
          id: 54,
          highest_observed_value: 36,
          upper_quartile: 22,
          median: 20,
          lower_quartile: 18,
          lowest_observed_value: 17,
          number_of_observations: 16,
          year: 1,
        },
        {
          id: 55,
          highest_observed_value: 36,
          upper_quartile: 22,
          median: 20,
          lower_quartile: 18,
          lowest_observed_value: 17,
          number_of_observations: 16,
          year: 2,
        },
      ],
    },
    {
      id: 6,
      name: 'Benchmark_apac_2023_3',
      region: 'APAC',
      year: 2023,
      benchmarking_file: null,
      benchmarking_file_name: null,
      quarterly_analysis: [
        {
          id: 61,
          highest_observed_value: 36,
          upper_quartile: 22,
          median: 20,
          lower_quartile: 18,
          lowest_observed_value: 17,
          number_of_observations: 16,
          year: 0,
        },
        {
          id: 62,
          highest_observed_value: 36,
          upper_quartile: 22,
          median: 20,
          lower_quartile: 18,
          lowest_observed_value: 17,
          number_of_observations: 16,
          year: 1,
        },
        {
          id: 63,
          highest_observed_value: 36,
          upper_quartile: 22,
          median: 20,
          lower_quartile: 18,
          lowest_observed_value: 17,
          number_of_observations: 16,
          year: 2,
        },
      ],
    },
  ],
})

export default benchmarkListData
