import { IconProps } from 'assets'

const Activity = ({ className }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.7617 7.5H12.7617L10.5117 14.25L6.01172 0.75L3.76172 7.5H0.761719"
        stroke="#1E40AF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Activity
