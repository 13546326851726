import { ROUTES } from 'constants/routes'
import { useAuth } from 'hooks/useAuth'
import { useRouter } from 'next/router'

import AppLayout from './components/appLayout'
import RedirectingState from './components/redirectingState/RedirectingState'
import { ROUTES_WITHOUT_LAYOUT } from './constants/layout.constants'

const Layout = ({ children }: AppLayoutProps): JSX.Element => {
  const router = useRouter()
  const { isLoggedIn, isFetching } = useAuth()

  if (ROUTES_WITHOUT_LAYOUT.includes(router.pathname)) {
    return <>{children}</>
  }

  if (
    (isFetching || !isLoggedIn) &&
    router.pathname !== ROUTES.USER_LOGIN &&
    router.pathname !== ROUTES.FORGOT_PASSWORD &&
    router.pathname !== ROUTES.FORGOT_PASSWORD_VERIFICATION
  ) {
    return <RedirectingState />
  }

  return (
    <>
      <AppLayout>{children}</AppLayout>
    </>
  )
}

interface AppLayoutProps {
  children: React.ReactNode
}

export default Layout
