import { Set, Variant } from '../types/typography.types'
import {
  Body,
  Callout,
  ContainerText,
  Display1,
  Display2,
  Footnote,
  Heading1,
  Heading2,
  Title1,
  Title2,
} from './fontVariants'

export const variantMappingToType: Record<Variant, Set> = {
  Display1,
  Display2,
  Title1,
  Title2,
  Heading1,
  Heading2,
  Body,
  ContainerText,
  Callout,
  Footnote,
}
