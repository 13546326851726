import { IconProps } from 'assets'

const File = ({ className, pathClassName = 'fill-[#64748B]' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M12.0001 5.205C11.9922 5.1361 11.9772 5.06822 11.9551 5.0025V4.935C11.919 4.85788 11.8709 4.787 11.8126 4.725V4.725L7.31256 0.225C7.25056 0.166662 7.17968 0.118561 7.10256 0.0824999H7.03506C6.95887 0.0388059 6.87473 0.0107582 6.78756 0H2.25006C1.65332 0 1.08103 0.237053 0.659071 0.65901C0.237114 1.08097 6.10352e-05 1.65326 6.10352e-05 2.25V12.75C6.10352e-05 13.3467 0.237114 13.919 0.659071 14.341C1.08103 14.7629 1.65332 15 2.25006 15H9.75006C10.3468 15 10.9191 14.7629 11.3411 14.341C11.763 13.919 12.0001 13.3467 12.0001 12.75V5.25C12.0001 5.25 12.0001 5.25 12.0001 5.205ZM7.50006 2.5575L9.44256 4.5H8.25006C8.05115 4.5 7.86038 4.42098 7.71973 4.28033C7.57908 4.13968 7.50006 3.94891 7.50006 3.75V2.5575ZM10.5001 12.75C10.5001 12.9489 10.421 13.1397 10.2804 13.2803C10.1397 13.421 9.94897 13.5 9.75006 13.5H2.25006C2.05115 13.5 1.86038 13.421 1.71973 13.2803C1.57908 13.1397 1.50006 12.9489 1.50006 12.75V2.25C1.50006 2.05109 1.57908 1.86032 1.71973 1.71967C1.86038 1.57902 2.05115 1.5 2.25006 1.5H6.00006V3.75C6.00006 4.34674 6.23711 4.91903 6.65907 5.34099C7.08103 5.76295 7.65332 6 8.25006 6H10.5001V12.75Z"
      />
    </svg>
  )
}

export default File
