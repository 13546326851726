import { IconProps } from 'assets'
import React from 'react'

const GreenTick = ({ className }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect y="0.875" width="18" height="18" rx="9" fill="#14B8A6" />
      <rect
        x="5.75439"
        y="8.7583"
        width="4.73088"
        height="1.77408"
        rx="0.887041"
        transform="rotate(45 5.75439 8.7583)"
        fill="white"
      />
      <rect
        x="6.69202"
        y="11.9463"
        width="7.85388"
        height="1.77408"
        rx="0.887041"
        transform="rotate(-45 6.69202 11.9463)"
        fill="white"
      />
    </svg>
  )
}

export default GreenTick
