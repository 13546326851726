import axios from 'api'

import { LegalEntityResponse } from '../types/legalEntity'

export const getAllLegalEntities = (year?: number) => {
  const params: { [key: string]: number | string | [] } = {}
  if (year) {
    params.year = year
  }
  return axios
    .get<LegalEntityResponse[]>('/api/v1/org/{organization}/legal_entity/legal_entity/', {
      params,
    })
    .then(res => res.data)
}
