import { IconProps } from 'assets'
import React from 'react'

const Edit = ({ className, pathClassName = 'stroke-blue900' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M3.36523 15.75H16.8652"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.86523 10.02V12.75H7.60918L15.3652 4.99054L12.6259 2.25L4.86523 10.02Z"
        className={pathClassName}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Edit
