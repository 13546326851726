import { IconProps } from 'assets'
import React from 'react'

const AddStickyNotes = ({ className, primaryColor, secondaryColor }: IconProps): JSX.Element => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1024 9.88824H11.2891C10.9157 9.88824 10.729 9.88824 10.5864 9.96696C10.4609 10.0362 10.3589 10.1466 10.2951 10.2826C10.2224 10.4371 10.2224 10.6393 10.2224 11.0438V14.0916M14.2223 8.96878V4.97713C14.2223 4.16817 14.2223 3.76368 14.077 3.4547C13.9492 3.1829 13.7452 2.96193 13.4943 2.82345C13.2091 2.66602 12.8357 2.66602 12.089 2.66602H5.689C4.94226 2.66602 4.56889 2.66602 4.28368 2.82345C4.03279 2.96193 3.82882 3.1829 3.70099 3.4547C3.55566 3.76368 3.55566 4.16817 3.55566 4.97713V11.9105C3.55566 12.7194 3.55566 13.1239 3.70099 13.4329C3.82882 13.7047 4.03279 13.9257 4.28368 14.0641C4.56889 14.2216 4.94226 14.2216 5.689 14.2216H9.52206C9.86086 14.2216 10.0302 14.2216 10.1889 14.1787C10.3295 14.1408 10.4635 14.0783 10.5856 13.9936C10.7233 13.8982 10.8397 13.7648 11.0724 13.4981L13.6393 10.5564C13.8547 10.3095 13.9624 10.1861 14.0393 10.0439C14.1075 9.91785 14.1577 9.78128 14.1881 9.63893C14.2223 9.47838 14.2223 9.30851 14.2223 8.96878Z"
        stroke={primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="2.81368" y="2.81368" width="3.85001" height="3.85001" rx="1.925" fill="white" />
      <rect
        x="2.81368"
        y="2.81368"
        width="3.85001"
        height="3.85001"
        rx="1.925"
        stroke={primaryColor}
        strokeWidth="3.85001"
      />
      <path
        d="M4.73907 2.88281V6.59532M6.59532 4.73907L2.88281 4.73907"
        stroke={secondaryColor}
        strokeWidth="0.825001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default AddStickyNotes
