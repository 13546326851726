import { IconProps } from '../index'

const Functions = ({ className, pathClassName = 'fill-[#1E40AF]' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        style={{ maskType: 'alpha' }}
        id="mask0_8976_26691"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18">
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_8976_26691)">
        <path
          className={pathClassName}
          d="M8.99951 17.3335L1.5 13.1672V4.83436L8.99951 0.667969L16.499 4.83436V13.1672L8.99951 17.3335ZM6.583 6.70924C6.90243 6.37593 7.27046 6.119 7.6871 5.93846C8.10374 5.75792 8.54121 5.66764 8.99951 5.66764C9.45782 5.66764 9.89529 5.75792 10.3119 5.93846C10.7286 6.119 11.0966 6.37593 11.416 6.70924L13.9159 5.3135L8.99951 2.58451L4.08317 5.3135L6.583 6.70924ZM8.16623 14.9587V12.2297C7.41628 12.0353 6.81215 11.6395 6.35385 11.0423C5.89555 10.4451 5.6664 9.7646 5.6664 9.00076C5.6664 8.84799 5.67334 8.70564 5.68723 8.5737C5.70112 8.44177 5.72889 8.30636 5.77056 8.16748L3.16656 6.70924V12.1881L8.16623 14.9587ZM8.99951 10.6673C9.45782 10.6673 9.85015 10.5041 10.1765 10.1778C10.5029 9.8514 10.6661 9.45906 10.6661 9.00076C10.6661 8.54246 10.5029 8.15012 10.1765 7.82375C9.85015 7.49739 9.45782 7.3342 8.99951 7.3342C8.54121 7.3342 8.14887 7.49739 7.8225 7.82375C7.49614 8.15012 7.33295 8.54246 7.33295 9.00076C7.33295 9.45906 7.49614 9.8514 7.8225 10.1778C8.14887 10.5041 8.54121 10.6673 8.99951 10.6673ZM9.83279 14.9587L14.8325 12.1881V6.70924L12.2285 8.16748C12.2701 8.30636 12.2979 8.44177 12.3118 8.5737C12.3257 8.70564 12.3326 8.84799 12.3326 9.00076C12.3326 9.7646 12.1035 10.4451 11.6452 11.0423C11.1869 11.6395 10.5827 12.0353 9.83279 12.2297V14.9587Z"
        />
      </g>
    </svg>
  )
}

export default Functions
