import { IconProps } from 'assets'

const Intangibles = ({ className, pathClassName = 'fill-[#1E3A8A]' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_8319_373613"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="19">
        <rect y="0.930664" width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_8319_373613)">
        <path
          className={pathClassName}
          d="M6 14.3932C6.125 14.4057 6.24688 14.415 6.36563 14.4213C6.48438 14.4275 6.6125 14.4307 6.75 14.4307C6.8875 14.4307 7.01563 14.4275 7.13438 14.4213C7.25313 14.415 7.375 14.4057 7.5 14.3932V15.9307H15V8.43066H13.4625C13.475 8.30566 13.4844 8.18379 13.4906 8.06504C13.4969 7.94629 13.5 7.81816 13.5 7.68066C13.5 7.54316 13.4969 7.41504 13.4906 7.29629C13.4844 7.17754 13.475 7.05566 13.4625 6.93066H15C15.4125 6.93066 15.7656 7.07754 16.0594 7.37129C16.3531 7.66504 16.5 8.01816 16.5 8.43066V15.9307C16.5 16.3432 16.3531 16.6963 16.0594 16.99C15.7656 17.2838 15.4125 17.4307 15 17.4307H7.5C7.0875 17.4307 6.73438 17.2838 6.44063 16.99C6.14688 16.6963 6 16.3432 6 15.9307V14.3932ZM6.75 12.9307C5.2875 12.9307 4.04688 12.4213 3.02813 11.4025C2.00938 10.3838 1.5 9.14316 1.5 7.68066C1.5 6.21816 2.00938 4.97754 3.02813 3.95879C4.04688 2.94004 5.2875 2.43066 6.75 2.43066C8.2125 2.43066 9.45313 2.94004 10.4719 3.95879C11.4906 4.97754 12 6.21816 12 7.68066C12 9.14316 11.4906 10.3838 10.4719 11.4025C9.45313 12.4213 8.2125 12.9307 6.75 12.9307ZM6.75 11.4307C7.7875 11.4307 8.67188 11.065 9.40313 10.3338C10.1344 9.60254 10.5 8.71816 10.5 7.68066C10.5 6.64316 10.1344 5.75879 9.40313 5.02754C8.67188 4.29629 7.7875 3.93066 6.75 3.93066C5.7125 3.93066 4.82813 4.29629 4.09688 5.02754C3.36563 5.75879 3 6.64316 3 7.68066C3 8.71816 3.36563 9.60254 4.09688 10.3338C4.82813 11.065 5.7125 11.4307 6.75 11.4307Z"
        />
      </g>
    </svg>
  )
}

export default Intangibles
