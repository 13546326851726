const currentUserData = {
  status: 200,
  data: {
    id: 9,
    username: 'abhishek',
    first_name: 'Abhishek',
    last_name: 'Arora',
    email: 'abhishek@integraltechnologies.io',
    organization: 2,
    user_roles: [
      {
        id: 6,
        user_type: 'super_admin',
        module: null,
        access_type: 'all',
        user: 9,
        legal_entity: null,
      },
    ],
    is_active: true,
    country: 'Maldives',
    profile_photo:
      'https://integral-development.s3.amazonaws.com/media/private/pic-logo.png?AWSAccessKeyId=AKIA6PR7UR6SEANQCKGM&Signature=ujTtNxKI7tr9WFP2DSsbiVrNGVg%3D&Expires=1690566821',
  },
}

export default currentUserData
