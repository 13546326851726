import { IconProps } from 'assets'
import React from 'react'

const CollapsibleSidebar = ({ className }: IconProps): JSX.Element => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="8" fill="white" />
      <path d="M16 22L10 16L16 10" stroke="#1E3A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22 22L16 16L22 10" stroke="#1E3A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default CollapsibleSidebar
