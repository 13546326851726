import { IconProps } from 'assets'
import React from 'react'

const PieChart = ({ className, pathClassName = 'stroke-blue900' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M11.9991 3.00053V3.00056L12.0051 3.0005C14.1096 2.97887 16.1617 3.65666 17.8393 4.9275C19.5169 6.19833 20.725 7.99025 21.2739 10.022C21.8229 12.0537 21.6817 14.2102 20.8724 16.153C20.0799 18.0555 18.6907 19.6476 16.9158 20.6905H16.7976L16.6826 20.756C15.4178 21.4762 14.0043 21.8962 12.5514 21.9834C11.0986 22.0707 9.64504 21.8229 8.3031 21.2593C6.96115 20.6957 5.76666 19.8312 4.81182 18.7327C3.85698 17.6341 3.16733 16.3309 2.7961 14.9235C2.42486 13.5162 2.38198 12.0423 2.67075 10.6157C2.95952 9.18915 3.57223 7.848 4.46158 6.6958C5.35092 5.5436 6.49313 4.61116 7.80003 3.97045C9.10694 3.32974 10.5436 2.99789 11.9991 3.00053ZM13.0629 4.0745L12.5 4.00312V4.57053V11.5005V12.0005H13H19.93H20.4974L20.426 11.4376C20.1889 9.5676 19.3369 7.82946 18.004 6.49656C16.6711 5.16367 14.9329 4.31164 13.0629 4.0745ZM11.5 4.57053V3.99964L10.9341 4.07489C8.80073 4.35859 6.85441 5.44098 5.4879 7.10361C4.12139 8.76625 3.43644 10.8853 3.57128 13.0333C3.70611 15.1812 4.65069 17.198 6.21439 18.6767C7.7781 20.1553 9.8445 20.9858 11.9966 21.0005L12.0002 21.0005C13.1231 21.0001 14.2344 20.7721 15.2667 20.3302L15.7866 20.1077L15.5021 19.6189L11.57 12.8656V12.8505V12.7591L11.5376 12.6735C11.5155 12.6151 11.5028 12.5535 11.5 12.491V4.57053ZM16.3972 19.1109L16.6808 19.6013L17.1322 19.2589C18.0321 18.5761 18.7869 17.7208 19.3524 16.7429C19.918 15.7651 20.2829 14.6844 20.426 13.5639L20.4979 13.0005H19.93H13.73H12.8632L13.2972 13.7509L16.3972 19.1109Z"
      />
    </svg>
  )
}

export default PieChart
