import { IconProps } from 'assets'
import React from 'react'

const BasicInfo = ({ className, pathClassName = 'stroke-neutral800' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4695_18674)">
        <path
          className={pathClassName}
          d="M12 3L19.7943 7.5V15.3453C19.7943 16.0598 19.4131 16.7201 18.7943 17.0774L13 20.4226C12.3812 20.7799 11.6188 20.7799 11 20.4226L5.20581 17.0774C4.58701 16.7201 4.20581 16.0598 4.20581 15.3453V7.5L12 3Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={pathClassName}
          d="M12.0001 7V12L7.66993 14.5"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={pathClassName}
          d="M12 12L16.3301 14.5"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4695_18674">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default BasicInfo
