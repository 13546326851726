import { Person2 } from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Avatar, IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import { EMPTY_ARRAY } from 'constants/app.constants'
import { ROUTES } from 'constants/routes'
import { useAuth } from 'hooks/useAuth'
import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'
import { capitalizeFirstLetter } from 'utils/utils'

import { ICON_STYLE, PAPER_STYLE } from '../../constants/sidebar.constants'

const UserInfo = ({ isOpen }: UserInfoProps): JSX.Element => {
  const router = useRouter()
  const { user, onLogout } = useAuth()

  const isOnUserProfile = router.pathname === ROUTES.USER_PROFILE

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()

    onLogout()
    handleClose()
  }

  const handleUserNavigation = useCallback(() => {
    router.push(ROUTES.USER_PROFILE)
    handleClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, EMPTY_ARRAY)
  const name = `${(user?.first_name ? `${user?.first_name} ` : '') + (user?.last_name || '')}` || user?.username
  if (!isOpen) {
    return (
      <>
        <Tooltip
          title={`${capitalizeFirstLetter(user?.first_name || '')}${user?.first_name && user?.email ? ' - ' : ''}${
            user?.email || ''
          }`}>
          <Avatar
            className="cursor-pointer bg-orange-100 text-orange-500"
            onClick={handleClick}
            src={user?.profile_photo || ''}>
            {name && name[0].toUpperCase()}
          </Avatar>
        </Tooltip>
        <Menu
          id="user-info-menu"
          aria-labelledby="user-info-menu-button"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          PaperProps={{
            style: PAPER_STYLE,
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          <MenuItem className="font-semibold text-xs" onClick={handleUserNavigation}>
            Profile Settings
          </MenuItem>
          <MenuItem className="font-semibold text-xs" onClick={handleLogout}>
            Log Out
          </MenuItem>
        </Menu>
      </>
    )
  }

  return (
    <div
      className={cx(
        'min-h-[76px] mb-4 rounded-xl p-4 flex items-center gap-3 border border-solid border-blue100 max-w-[11.75rem] mt-4',
        // 'cursor-pointer',
        {
          'bg-white': isOnUserProfile,
          'bg-transparent ': !isOnUserProfile,
        }
      )}
      onClick={handleUserNavigation}>
      <Avatar
        src={user?.profile_photo || ''}
        className="bg-blue-200"
        sx={{
          height: '2rem',
          width: '2rem',
        }}>
        <Person2 className="text-blue-800" />
      </Avatar>
      <div className="flex relative">
        <div>
          <Typography
            variant={Variant.Body}
            type="bold"
            className={cx(' whitespace-nowrap', {
              'text-blue-100': !isOnUserProfile,
              'text-neutral800': isOnUserProfile,
            })}>
            {capitalizeFirstLetter(user?.first_name || '')}
          </Typography>
          <Tooltip title={user?.email}>
            <div>
              <Typography
                variant={Variant.Callout}
                className={cx('mt-1 whitespace-nowrap overflow-hidden text-ellipsis max-w-[6rem] z-50', {
                  'text-blue100': !isOnUserProfile,
                  'text-neutral600': isOnUserProfile,
                })}>
                {user?.email}
              </Typography>
            </div>
          </Tooltip>
        </div>
        <IconButton onClick={handleClick} aria-label="more" id="long-button" aria-haspopup="true" sx={ICON_STYLE}>
          <MoreVertIcon
            fontSize="small"
            sx={{
              color: isOnUserProfile ? '#1E293B' : '#DBEAFE',
            }}
          />
        </IconButton>
      </div>

      <Menu
        id="user-info-menu"
        aria-labelledby="user-info-menu-button"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: PAPER_STYLE,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <MenuItem onClick={handleLogout}>Log Out</MenuItem>
      </Menu>
    </div>
  )
}

interface UserInfoProps {
  setActiveLink?: React.Dispatch<React.SetStateAction<string | undefined>>
  isOpen: boolean
}

export default UserInfo
