import axios from 'axios'

export const formatUrl = (url: string, params: Record<string, string | number | undefined | null>) => {
  for (const key in params) {
    let value = params[key] || ''
    if (typeof value === 'number') {
      value = String(value)
    }
    url = url.replace(`{${key}}`, value)
    url = url.replace(/\/\//g, '/')
  }
  return url
}

export const fetchResourceFromUrl = async <T = string>(url: string, errorCallBack?: VoidFunction) => {
  try {
    return (await axios.get(url)).data as T
  } catch (error) {
    console.log(error)
    if (typeof errorCallBack == 'function') {
      errorCallBack()
    }
    return ''
  }
}

export const getOrgIdFromUrl = () => {
  const pathSegments = window.location.pathname.split('/')
  const orgIdIndex = pathSegments.indexOf('org') + 1

  return orgIdIndex > 0 && orgIdIndex < pathSegments.length && !!Number(pathSegments[orgIdIndex])
    ? pathSegments[orgIdIndex]
    : null
}
