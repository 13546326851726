import { HomepageDataItem } from 'api/organization/types'

export const getYearOptions = (data: HomepageDataItem[]) => {
  return data.map(item => {
    return {
      value: item.year,
      label: `Year ${item.year}`,
    }
  })
}
