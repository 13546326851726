import axios from 'api'
import { User } from 'api/login/login.types'
import { APIS } from 'constants/apis'

import { CreateUser, UpdateUser } from './types/users'

export const getAllUsers = (params?: { organization?: number }) =>
  axios.get<User[]>(APIS.USERS.LIST, { params: params }).then(response => response.data)

export const createUser = (variables: CreateUser) =>
  axios.post<User[]>('/api/v1/ums/users/add_user/', variables).then(response => response.data)

export const editUser = ([variables, userId]: [UpdateUser, number]) =>
  axios
    .patch<User[]>(`/api/v1/ums/users/${userId}/edit_user/`, variables, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(response => response.data)

export const getUser = (userId: number) =>
  axios.get<User>(`/api/v1/ums/users/${userId}/`).then(response => response.data)
export const deleteUser = (userId: number) =>
  axios.delete<User[]>(`/api/v1/ums/users/${userId}/`).then(response => response.data)
