import { IconProps } from '../index'

const FunctionalAnalysis = ({ className, pathClassName = 'stroke-neutral800' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.2611 8.33854C19.4117 8.33854 20.3444 7.4058 20.3444 6.25521C20.3444 5.10461 19.4117 4.17188 18.2611 4.17188C17.1105 4.17188 16.1777 5.10461 16.1777 6.25521C16.1777 7.4058 17.1105 8.33854 18.2611 8.33854Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
      <path
        d="M5.76107 20.8385C6.91166 20.8385 7.8444 19.9058 7.8444 18.7552C7.8444 17.6046 6.91166 16.6719 5.76107 16.6719C4.61047 16.6719 3.67773 17.6046 3.67773 18.7552C3.67773 19.9058 4.61047 20.8385 5.76107 20.8385Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
      <path
        d="M7.8444 4.17188H3.67773V8.33854H7.8444V4.17188Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
      <path
        d="M20.3444 16.6719H16.1777V20.8385H20.3444V16.6719Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
      <path
        d="M16.179 6.25H7.8457"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
      <path
        d="M16.179 18.75H7.8457"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
      <path
        d="M5.76172 16.6615V8.32812"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
      <path
        d="M18.2617 16.6615V8.32812"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
    </svg>
  )
}

export default FunctionalAnalysis
