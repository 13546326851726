import axios from 'api'

export const getGlobalSearchResults = ({ search_term = '', year }: { search_term?: string; year: string | number }) =>
  axios
    .get<GlobalSearchResponseType[]>(
      `/api/v1/org/{organization}/search/global_search/?search_term=${search_term}&year=${year}`
    )
    .then(res => res.data)

export type GlobalSearchResponseType = {
  name: string
  id: number
  type: 'legal_entity' | 'transaction'
}
