import Hotjar from '@hotjar/browser'

const HOJAR_SITE_ID = parseInt(process.env.NEXT_PUBLIC_HOJAR_SITE_ID || '')
const HOTJAR_VERSION = 6

/**
 * Initialise hotjar event analytics
 */
export const initializeHotjarAnalytics = () => {
  Hotjar.init(HOJAR_SITE_ID, HOTJAR_VERSION, {
    debug: false,
    nonce: 'rAnDoM',
  })
}
