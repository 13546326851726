import { ROUTES } from 'constants/routes'

export const addOrgIdToRoute = (route: string, orgId?: string) => {
  let orgIdFromStorage
  if (!orgId && typeof sessionStorage != 'undefined') {
    orgIdFromStorage = sessionStorage.getItem('orgId') || localStorage.getItem('orgId')
  }
  if (orgId) {
    return route.replace('[orgId]', orgId)
  } else if (orgIdFromStorage) {
    return route.replace('[orgId]', orgIdFromStorage)
  } else {
    return ROUTES.HOME
  }
}
