import { IconProps } from 'assets'

const TemplateManagement = ({ className, pathClassName = 'fill-[blue100]' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.8047 7.44C18.7943 7.34813 18.7742 7.25763 18.7447 7.17V7.08C18.6966 6.97718 18.6325 6.88267 18.5547 6.8L12.5547 0.8C12.472 0.722216 12.3775 0.658081 12.2747 0.61C12.2448 0.60576 12.2145 0.60576 12.1847 0.61C12.0831 0.551741 11.9709 0.514344 11.8547 0.5H7.80469C7.00904 0.5 6.24598 0.816071 5.68337 1.37868C5.12076 1.94129 4.80469 2.70435 4.80469 3.5V4.5H3.80469C3.00904 4.5 2.24598 4.81607 1.68337 5.37868C1.12076 5.94129 0.804688 6.70435 0.804688 7.5V17.5C0.804688 18.2956 1.12076 19.0587 1.68337 19.6213C2.24598 20.1839 3.00904 20.5 3.80469 20.5H11.8047C12.6003 20.5 13.3634 20.1839 13.926 19.6213C14.4886 19.0587 14.8047 18.2956 14.8047 17.5V16.5H15.8047C16.6003 16.5 17.3634 16.1839 17.926 15.6213C18.4886 15.0587 18.8047 14.2956 18.8047 13.5V7.5C18.8047 7.5 18.8047 7.5 18.8047 7.44ZM12.8047 3.91L15.3947 6.5H13.8047C13.5395 6.5 13.2851 6.39464 13.0976 6.20711C12.91 6.01957 12.8047 5.76522 12.8047 5.5V3.91ZM12.8047 17.5C12.8047 17.7652 12.6993 18.0196 12.5118 18.2071C12.3243 18.3946 12.0699 18.5 11.8047 18.5H3.80469C3.53947 18.5 3.28512 18.3946 3.09758 18.2071C2.91004 18.0196 2.80469 17.7652 2.80469 17.5V7.5C2.80469 7.23478 2.91004 6.98043 3.09758 6.79289C3.28512 6.60536 3.53947 6.5 3.80469 6.5H4.80469V13.5C4.80469 14.2956 5.12076 15.0587 5.68337 15.6213C6.24598 16.1839 7.00904 16.5 7.80469 16.5H12.8047V17.5ZM16.8047 13.5C16.8047 13.7652 16.6993 14.0196 16.5118 14.2071C16.3243 14.3946 16.0699 14.5 15.8047 14.5H7.80469C7.53947 14.5 7.28512 14.3946 7.09758 14.2071C6.91004 14.0196 6.80469 13.7652 6.80469 13.5V3.5C6.80469 3.23478 6.91004 2.98043 7.09758 2.79289C7.28512 2.60536 7.53947 2.5 7.80469 2.5H10.8047V5.5C10.8047 6.29565 11.1208 7.05871 11.6834 7.62132C12.246 8.18393 13.009 8.5 13.8047 8.5H16.8047V13.5Z"
        className={pathClassName}
      />
    </svg>
  )
}

export default TemplateManagement
