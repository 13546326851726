import axios from 'api'
import { Benchmark, CreateBenchmark, UpdateBenchmark } from 'api/benchmarks/types'
import { AxiosRequestConfig } from 'axios'
import { APIS } from 'constants/apis'
import { formatUrl } from 'utils/utils'

export const getAllBenchmarks = (year?: number) =>
  axios.get<Benchmark[]>(APIS.BENCHMARKS.LIST, { params: { year } }).then(response => response.data)

export const getBenchmarkById = (id: number | null) =>
  axios.get<Benchmark>(formatUrl(APIS.BENCHMARKS.ITEM, { id: id })).then(response => response.data)

export const updateBenchmark = (variables: CreateBenchmark | UpdateBenchmark) => {
  let method: 'post' | 'patch' = 'post'
  const url = formatUrl(APIS.BENCHMARKS.ITEM, { id: variables.id })
  if (variables.id) {
    method = 'patch'
  }
  const apiOptions: AxiosRequestConfig = { headers: {} }
  if (
    variables.accepted_rejection_matrix_file instanceof File ||
    variables.benchmark_studies_file instanceof File ||
    variables.combined_benchmarking_file instanceof File ||
    variables.company_info_file instanceof File
  ) {
    if (apiOptions.headers) {
      apiOptions.headers['Content-Type'] = 'multipart/form-data'
    }
  }
  return axios[method]<Benchmark>(url, variables, apiOptions).then(response => response.data)
}

export const deleteBenchmark = (id: number) =>
  axios.delete<Benchmark>(formatUrl(APIS.BENCHMARKS.ITEM, { id: id })).then(response => response.data)

export const updateBenchmarkTransactions = (variables: UpdateBenchmark) =>
  axios
    .put<Benchmark>(
      `/api/v1/org/{organization}/transaction/benchmarking/${variables.id}/update_transactions/`,
      variables
    )
    .then(response => response.data)
