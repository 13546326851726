export enum Variant {
  Display1 = 'Display1',
  Display2 = 'Display2',
  Title1 = 'Title1',
  Title2 = 'Title2',
  Heading1 = 'Heading1',
  Heading2 = 'Heading2',
  Body = 'Body',
  ContainerText = 'ContainerText',
  Callout = 'Callout',
  Footnote = 'Footnote',
}

export interface Set {
  regular: string
  semibold: string
  bold: string
}

export interface TypographyProps {
  variant: Variant
  type?: keyof Set
  children?: React.ReactNode
  className?: string
  as?: keyof JSX.IntrinsicElements
  onClick?: VoidFunction
  dangerouslySetInnerHTML?: React.DOMAttributes<HTMLElement>['dangerouslySetInnerHTML']
  style?: React.CSSProperties
}
