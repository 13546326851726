import { IconProps } from 'assets'
import React from 'react'

const Services = ({ className, pathClassName = 'fill-[#1E3A8A]' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M8 15.4307C7.0875 15.3182 6.18125 15.0713 5.28125 14.69C4.38125 14.3088 3.57813 13.7619 2.87188 13.0494C2.16563 12.3369 1.59375 11.4369 1.15625 10.3494C0.71875 9.26191 0.5 7.95566 0.5 6.43066V5.68066H1.25C1.8875 5.68066 2.54375 5.76191 3.21875 5.92441C3.89375 6.08691 4.525 6.33066 5.1125 6.65566C5.2625 5.58066 5.60313 4.47754 6.13438 3.34629C6.66563 2.21504 7.2875 1.24316 8 0.430664C8.7125 1.24316 9.33438 2.21504 9.86563 3.34629C10.3969 4.47754 10.7375 5.58066 10.8875 6.65566C11.475 6.33066 12.1062 6.08691 12.7812 5.92441C13.4563 5.76191 14.1125 5.68066 14.75 5.68066H15.5V6.43066C15.5 7.95566 15.2813 9.26191 14.8438 10.3494C14.4062 11.4369 13.8344 12.3369 13.1281 13.0494C12.4219 13.7619 11.6219 14.3088 10.7281 14.69C9.83437 15.0713 8.925 15.3182 8 15.4307ZM7.9625 13.8932C7.825 11.8182 7.20938 10.2494 6.11563 9.18691C5.02188 8.12441 3.6625 7.46816 2.0375 7.21816C2.175 9.35566 2.80938 10.9494 3.94063 11.9994C5.07188 13.0494 6.4125 13.6807 7.9625 13.8932ZM8 9.13066C8.1875 8.85566 8.41563 8.57129 8.68438 8.27754C8.95313 7.98379 9.2125 7.73066 9.4625 7.51816C9.4375 6.80566 9.29687 6.06191 9.04062 5.28691C8.78437 4.51191 8.4375 3.75566 8 3.01816C7.5625 3.75566 7.21563 4.51191 6.95938 5.28691C6.70313 6.06191 6.5625 6.80566 6.5375 7.51816C6.7875 7.73066 7.05 7.98379 7.325 8.27754C7.6 8.57129 7.825 8.85566 8 9.13066ZM9.4625 13.5557C9.925 13.4057 10.4062 13.1869 10.9062 12.8994C11.4063 12.6119 11.8719 12.2213 12.3031 11.7275C12.7344 11.2338 13.1031 10.6182 13.4094 9.88066C13.7156 9.14316 13.9 8.25566 13.9625 7.21816C12.7875 7.39316 11.7562 7.78379 10.8687 8.39004C9.98125 8.99629 9.3 9.76816 8.825 10.7057C8.975 11.1057 9.10313 11.5432 9.20938 12.0182C9.31563 12.4932 9.4 13.0057 9.4625 13.5557Z"
      />
    </svg>
  )
}

export default Services
