import { IconProps } from 'assets'
import React from 'react'

const BarGraph = ({ className, pathClassName = 'fill-blue900' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M5 12.5H1C0.734784 12.5 0.48043 12.6054 0.292893 12.7929C0.105357 12.9804 0 13.2348 0 13.5V21.5C0 21.7652 0.105357 22.0196 0.292893 22.2071C0.48043 22.3946 0.734784 22.5 1 22.5H5C5.26522 22.5 5.51957 22.3946 5.70711 22.2071C5.89464 22.0196 6 21.7652 6 21.5V13.5C6 13.2348 5.89464 12.9804 5.70711 12.7929C5.51957 12.6054 5.26522 12.5 5 12.5ZM4 20.5H2V14.5H4V20.5ZM21 8.5H17C16.7348 8.5 16.4804 8.60536 16.2929 8.79289C16.1054 8.98043 16 9.23478 16 9.5V21.5C16 21.7652 16.1054 22.0196 16.2929 22.2071C16.4804 22.3946 16.7348 22.5 17 22.5H21C21.2652 22.5 21.5196 22.3946 21.7071 22.2071C21.8946 22.0196 22 21.7652 22 21.5V9.5C22 9.23478 21.8946 8.98043 21.7071 8.79289C21.5196 8.60536 21.2652 8.5 21 8.5ZM20 20.5H18V10.5H20V20.5ZM13 0.5H9C8.73478 0.5 8.48043 0.605357 8.29289 0.792893C8.10536 0.98043 8 1.23478 8 1.5V21.5C8 21.7652 8.10536 22.0196 8.29289 22.2071C8.48043 22.3946 8.73478 22.5 9 22.5H13C13.2652 22.5 13.5196 22.3946 13.7071 22.2071C13.8946 22.0196 14 21.7652 14 21.5V1.5C14 1.23478 13.8946 0.98043 13.7071 0.792893C13.5196 0.605357 13.2652 0.5 13 0.5ZM12 20.5H10V2.5H12V20.5Z"
      />
    </svg>
  )
}

export default BarGraph
