import { IconProps } from 'assets'
import React from 'react'

const SparkleStars = ({ className, pathClassName = 'stroke-[#F96416]' }: IconProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="256"
      height="256"
      className={className}
      viewBox="0 0 256 256"
      xmlSpace="preserve">
      <defs></defs>
      <g
        style={{
          stroke: 'none',
          strokeWidth: 1,
          strokeDasharray: 'none',
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeMiterlimit: 10,
          fill: 'none',
          fillRule: 'nonzero',
          opacity: 1,
        }}
        transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
        <path
          d="M 88.02 90 l -16.812 -9.144 c -0.67 -0.299 -1.445 -0.584 -2.23 -0.812 c -0.786 -0.228 -1.593 -0.402 -2.401 -0.517 c -0.806 -0.115 -1.631 -0.173 -2.45 -0.173 c -0.817 0 -1.642 0.058 -2.45 0.173 c -0.804 0.114 -1.613 0.287 -2.402 0.517 c -0.784 0.227 -1.56 0.513 -2.304 0.849 L 40.232 90 l 9.144 -16.812 c 0.299 -0.668 0.585 -1.443 0.813 -2.23 c 0.228 -0.789 0.402 -1.597 0.516 -2.402 c 0.115 -0.808 0.173 -1.633 0.173 -2.45 c 0 -0.82 -0.058 -1.644 -0.173 -2.45 c -0.115 -0.809 -0.289 -1.617 -0.517 -2.402 c -0.227 -0.785 -0.513 -1.561 -0.849 -2.304 l -9.107 -16.738 l 16.812 9.144 c 0.673 0.3 1.449 0.586 2.231 0.812 c 0.783 0.228 1.592 0.401 2.402 0.516 c 0.805 0.115 1.63 0.174 2.45 0.174 c 0.817 0 1.642 -0.058 2.45 -0.173 c 0.804 -0.114 1.613 -0.287 2.402 -0.517 c 0.787 -0.228 1.561 -0.514 2.302 -0.849 l 16.739 -9.107 l -9.144 16.811 c -0.299 0.67 -0.584 1.445 -0.812 2.231 c -0.228 0.789 -0.402 1.597 -0.516 2.402 c -0.115 0.808 -0.173 1.633 -0.173 2.45 c 0 0.82 0.058 1.644 0.173 2.449 c 0.116 0.811 0.289 1.619 0.517 2.402 c 0.223 0.77 0.503 1.533 0.833 2.269 L 88.02 90 z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'round',
            fill: 'rgb(255,255,255)',
            fillRule: 'nonzero',
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
          className={pathClassName}
        />
        <path
          d="M 77.914 32.163 l -11.315 -6.154 c -0.451 -0.201 -0.973 -0.393 -1.501 -0.546 c -0.529 -0.154 -1.072 -0.271 -1.616 -0.348 c -0.543 -0.077 -1.098 -0.116 -1.649 -0.116 c -0.55 0 -1.105 0.039 -1.649 0.117 c -0.541 0.077 -1.086 0.193 -1.617 0.348 c -0.528 0.153 -1.05 0.345 -1.551 0.571 l -11.265 6.13 l 6.154 -11.315 c 0.201 -0.45 0.394 -0.971 0.547 -1.501 c 0.154 -0.531 0.27 -1.075 0.347 -1.616 c 0.077 -0.544 0.116 -1.099 0.117 -1.649 c 0 -0.552 -0.039 -1.107 -0.117 -1.649 c -0.077 -0.544 -0.194 -1.088 -0.348 -1.616 c -0.153 -0.529 -0.345 -1.05 -0.571 -1.551 L 45.751 0 l 11.315 6.154 c 0.453 0.202 0.975 0.394 1.501 0.546 c 0.527 0.153 1.071 0.27 1.617 0.347 c 0.542 0.078 1.097 0.117 1.649 0.117 c 0.55 0 1.105 -0.039 1.649 -0.117 c 0.541 -0.077 1.086 -0.193 1.617 -0.348 c 0.53 -0.153 1.051 -0.346 1.55 -0.571 L 77.914 0 L 71.76 11.315 c -0.201 0.451 -0.393 0.973 -0.547 1.502 c -0.154 0.531 -0.27 1.075 -0.347 1.616 c -0.077 0.544 -0.116 1.099 -0.117 1.649 c 0 0.552 0.039 1.107 0.116 1.648 c 0.078 0.546 0.194 1.089 0.348 1.616 c 0.15 0.518 0.338 1.032 0.56 1.527 L 77.914 32.163 z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'round',
            fill: 'rgb(255,255,255)',
            fillRule: 'nonzero',
            opacity: 0.9,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
          className={pathClassName}
        />
        <path
          d="M 29.846 60.228 l -9.803 -5.332 c -0.391 -0.175 -0.843 -0.341 -1.3 -0.473 c -0.458 -0.133 -0.929 -0.235 -1.4 -0.301 c -0.47 -0.067 -0.951 -0.101 -1.429 -0.101 c -0.477 0 -0.957 0.034 -1.429 0.101 c -0.469 0.067 -0.941 0.168 -1.401 0.301 c -0.457 0.132 -0.91 0.299 -1.343 0.495 l -9.76 5.311 l 5.332 -9.804 c 0.174 -0.39 0.341 -0.841 0.474 -1.3 c 0.133 -0.46 0.234 -0.931 0.301 -1.4 c 0.067 -0.471 0.101 -0.952 0.101 -1.429 c 0 -0.478 -0.034 -0.959 -0.101 -1.429 c -0.067 -0.472 -0.169 -0.943 -0.301 -1.4 c -0.132 -0.458 -0.299 -0.91 -0.495 -1.343 L 1.98 32.361 l 9.803 5.332 c 0.393 0.175 0.845 0.342 1.301 0.473 c 0.457 0.133 0.928 0.234 1.401 0.301 c 0.47 0.067 0.95 0.101 1.428 0.101 c 0.477 0 0.957 -0.034 1.429 -0.101 c 0.469 -0.067 0.941 -0.168 1.401 -0.301 c 0.459 -0.133 0.91 -0.3 1.343 -0.495 l 9.761 -5.311 l -5.332 9.803 c -0.174 0.39 -0.341 0.843 -0.474 1.301 c -0.133 0.46 -0.234 0.931 -0.301 1.4 c -0.067 0.471 -0.101 0.952 -0.101 1.429 c 0 0.478 0.034 0.959 0.101 1.428 c 0.067 0.473 0.168 0.944 0.301 1.4 c 0.13 0.449 0.293 0.894 0.486 1.323 L 29.846 60.228 z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'round',
            fill: 'rgb(255,255,255)',
            fillRule: 'nonzero',
            opacity: 0.8,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
          className={pathClassName}
        />
      </g>
    </svg>
  )
}

export default SparkleStars
