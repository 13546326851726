import { IconProps } from 'assets'

const DollarInCircle = ({ className, pathClassName = 'stroke-[#0D9488]' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M9.38251 6.24153C9.65398 6.55439 10.1277 6.58794 10.4405 6.31648C10.7534 6.04501 10.7869 5.57132 10.5155 5.25847L9.38251 6.24153ZM6.6175 9.75847C6.34604 9.44561 5.87235 9.41206 5.55949 9.68352C5.24664 9.95499 5.21308 10.4287 5.48455 10.7415L6.6175 9.75847ZM8.75 4.25C8.75 3.83579 8.41421 3.5 8 3.5C7.58579 3.5 7.25 3.83579 7.25 4.25H8.75ZM7.25 11.75C7.24999 12.1642 7.58577 12.5 7.99998 12.5C8.4142 12.5 8.74999 12.1642 8.75 11.75L7.25 11.75ZM14 8C14 11.3137 11.3137 14 8 14V15.5C12.1421 15.5 15.5 12.1421 15.5 8H14ZM8 14C4.68629 14 2 11.3137 2 8H0.5C0.5 12.1421 3.85786 15.5 8 15.5V14ZM2 8C2 4.68629 4.68629 2 8 2V0.5C3.85786 0.5 0.5 3.85786 0.5 8H2ZM8 2C11.3137 2 14 4.68629 14 8H15.5C15.5 3.85786 12.1421 0.5 8 0.5V2ZM8 7.25C7.5061 7.25 7.09287 7.11518 6.82504 6.93662C6.55195 6.75457 6.5 6.58543 6.5 6.5H5C5 7.243 5.45173 7.82386 5.99298 8.1847C6.53948 8.54903 7.25126 8.75 8 8.75V7.25ZM6.5 6.5C6.5 6.41457 6.55195 6.24543 6.82504 6.06338C7.09287 5.88482 7.5061 5.75 8 5.75V4.25C7.25126 4.25 6.53948 4.45097 5.99298 4.8153C5.45173 5.17614 5 5.757 5 6.5H6.5ZM8 5.75C8.67783 5.75 9.17227 5.99923 9.38251 6.24153L10.5155 5.25847C9.94764 4.60406 8.9878 4.25 8 4.25V5.75ZM8 8.75C8.4939 8.75 8.90713 8.88482 9.17498 9.06338C9.44806 9.24544 9.50002 9.41458 9.50002 9.5H11C11 8.75699 10.5483 8.17614 10.007 7.8153C9.46052 7.45097 8.74874 7.25 8 7.25V8.75ZM7.25 4.25V5H8.75V4.25H7.25ZM7.25002 11L7.25 11.75L8.75 11.75L8.75002 11L7.25002 11ZM8.00002 10.25C7.32219 10.25 6.82774 10.0008 6.6175 9.75847L5.48455 10.7415C6.05238 11.3959 7.01222 11.75 8.00001 11.75L8.00002 10.25ZM9.50002 9.5C9.50002 9.58543 9.44806 9.75457 9.17498 9.93662C8.90714 10.1152 8.49392 10.25 8.00002 10.25V11.75C8.74875 11.75 9.46053 11.549 10.007 11.1847C10.5483 10.8239 11 10.243 11 9.5H9.50002ZM7.25 5L7.25002 11L8.75002 11L8.75 5L7.25 5Z"
        fill="#1E293B"
      />
    </svg>
  )
}

export default DollarInCircle
