import { IconProps } from 'assets'
import React from 'react'

const LocationPointer = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="36"
      height="37"
      viewBox="0 0 36 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M18 26C18 26 23.625 21.5 23.625 16.625C23.625 13.5184 21.1066 11 18 11C14.8934 11 12.375 13.5184 12.375 16.625C12.375 21.5 18 26 18 26Z"
        fill="white"
        stroke="#1E3A8A"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M18 18.875C19.2426 18.875 20.25 17.8676 20.25 16.625C20.25 15.3824 19.2426 14.375 18 14.375C16.7574 14.375 15.75 15.3824 15.75 16.625C15.75 17.8676 16.7574 18.875 18 18.875Z"
        fill="white"
        stroke="#1E3A8A"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LocationPointer
