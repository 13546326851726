import { IconProps } from 'assets'

const SideBarEclips = ({ className, pathClassName = 'fill-[blue100]' }: IconProps): JSX.Element => {
  return (
    <svg className={className} width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8.98523V9H9V0H8.98523C8.72364 4.84292 4.84292 8.72364 0 8.98523Z"
        fill="#F1F5F9"
      />
    </svg>
  )
}

export default SideBarEclips
