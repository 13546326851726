import { IconProps } from 'assets'
import React from 'react'

const HalfArrow = ({ className, pathClassName = 'fill-[#3B82F6]' }: IconProps): JSX.Element => {
  return (
    <svg className={className} width="20" height="8" viewBox="0 0 20 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.641 1.87066C12.3481 1.57777 12.3481 1.1029 12.641 0.810002C12.9339 0.517109 13.4087 0.517109 13.7016 0.810002L19.5308 6.6392C19.6899 6.79823 19.7625 7.01092 19.7489 7.21899C19.7493 7.22941 19.7495 7.23989 19.7495 7.25041C19.7495 7.66463 19.4137 8.00041 18.9995 8.00041H0.999512C0.585297 8.00041 0.249512 7.66463 0.249512 7.25041C0.249512 6.8362 0.585297 6.50041 0.999512 6.50041H17.2707L12.641 1.87066Z"
        className={pathClassName}
      />
    </svg>
  )
}

export default HalfArrow
