/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'api'
import { deleteICAgreement } from 'api/intercompanyAgreement'
import { AxiosRequestConfig } from 'axios'

import { Variable } from './addTransaction.type'
import { TransactionByIdResponse } from './getTransactionByIdResponse'
import { TransactionResponse } from './types'

export const getTransactions = (
  year?: number,
  filter?: {
    legal_entity?: number
    intercompany_agreement?: number
  }
) => {
  let params: Record<string, string | number | []> = {}
  if (filter) {
    params = { ...params, ...filter }
  }
  if (year) {
    params.year = year
  }
  return axios
    .get<TransactionResponse[]>(`/api/v1/org/{organization}/transaction/transactions/`, {
      params,
    })
    .then(res => res.data)
}

export const getTransactionById = (id: number) =>
  axios.get<TransactionByIdResponse>(`/api/v1/org/{organization}/transaction/transactions/${id}/`).then(res => res.data)

export const deleteIntercompanyFilesById = ({
  id,
  variables,
  deleteType,
}: {
  deleteType: 'intercompany_agreement' | 'economic_analysis' | 'benchmarking'
  id?: number
  variables: { [key: string]: any }
}) => {
  if (id && deleteType == 'intercompany_agreement') {
    return deleteICAgreement({ id })
  }
  return axios({
    method: 'PATCH',
    url: `/api/v1/org/{organization}/transaction/${deleteType}/${id}/`,
    data: {
      ...variables,
    },
  }).then(res => res.data)
}

export const addFileToTransaction = ({
  id,
  variables,
  deleteType,
}: {
  deleteType: 'economic_analysis' | 'benchmarking'
  id?: number
  variables: Variable
}) => {
  let payload = {}

  const { benchmarkingPayload, financial_data_file, name } = variables

  if (deleteType == 'benchmarking' && benchmarkingPayload) {
    payload = benchmarkingPayload
  }
  if (deleteType == 'economic_analysis') {
    payload = {
      financial_data_file,
      financial_data_file_name: name || financial_data_file?.name,
    }
  }
  const apiOptions: AxiosRequestConfig = { headers: {} }

  const hasFiles = Object.values(payload).some(value => value instanceof File)
  if (hasFiles) {
    if (apiOptions.headers) {
      apiOptions.headers['Content-Type'] = 'multipart/form-data'
    }
  }

  return axios
    .patch(`/api/v1/org/{organization}/transaction/${deleteType}/${id}/`, payload, apiOptions)
    .then(res => res.data)
}

export const geAllTransactionByLegalEntityId = (id: number) =>
  axios
    .get<TransactionResponse[]>(`/api/v1/org/{organization}/transaction/transactions/?legal_entity=${id}`)
    .then(res => res.data)

export const getTransactionsByBenchmark = (id: number, year?: number) =>
  axios
    .get<TransactionResponse[]>(`/api/v1/org/{organization}/transaction/transactions/`, {
      params: { benchmarking: id, year },
    })
    .then(res => res.data)
